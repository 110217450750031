import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DeviceService } from 'src/app/device.service';
import { UtilityService } from 'src/app/utility.service';

@Component({
  selector: 'app-list-assets',
  templateUrl: './list-assets.component.html',
  styleUrls: ['./list-assets.component.css']
})
export class ListAssetsComponent implements OnInit {

  Fields:any = [];
  Asset = [];
  headerdata: any = {};
  url: any;
  pageSize=10;
  currentpage=1;
  projection:any;
  rawUrl: any;
  flattenedData:any=[];
   fliterSubscription: Subscription;
  dataSource:any;
  query:any={};
  constructor(
    private deviceService: DeviceService,
    private utilityService: UtilityService
  ) { }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
    this.projection = ["AssetType", "AssetId", "Fields.AssetName", "Fields.PrimaryCategory", "AssetStatus"];
    this.query["AssetType"]="Equipment";
    this.getAssetList()
    this.fliterSubscription=this.utilityService.getFilterClickListener().subscribe((filter:any)=>{
      console.log(filter);
      this.query["AssetType"]=filter[0];
      if(filter.length > 0){
      this.query["Fields.PrimaryCategory"]=filter[1];
      }
      this.getAssetList()
    })

    this.utilityService.getStatusClickListener().subscribe((filter:any)=>{
        this.query['status']=filter;
        console.log(this.query);


      this.utilityService.changeBulkAssetStatus("assets.changeBulkStatus",this.query,res=>{
        console.log(res);

      })
    })

  }

  getAssetList(){
    let data={
      pagesize:this.pageSize,
      page:this.currentpage,
      projection:this.projection,
      query:this.query
    }
    this.deviceService.post(data,
      "assets.list",
      res => {
        this.sendDataToReportTable(res);
      },
      err => {
      }
    );
  }

  onChangedPage(pageData) {
    this.currentpage = pageData.currentPage;
    this.pageSize = pageData.items;
    this.getAssetList();
  }


  /**
   * (sendDataToReportTable)
   * Description:
   * this function sends geofence list data to report table.
   */
  sendDataToReportTable(data) {
    this.Fields=[];
    this.flattenedData=[];
    let field = Object.keys(data.data[0]);

    field.forEach(element => {
      if (typeof data.data[0][element] != "object") {
        if (element != "_id" && element!= "AssetStatus" ) {
          this.Fields.push(element);

        }
      }else{
        const nested_key=Object.keys(data.data[0][element]);
        console.log(nested_key);
        this.Fields=this.Fields.concat(nested_key)
        this.Fields.push("Activate/Deactivate")
      }

    });
    data.data.forEach(element => {
        let flattenedObject={};
        flattenedObject["AssetType"]=element.AssetType;
        flattenedObject["AssetId"]=element.AssetId;

        flattenedObject["AssetStatus"]=element.AssetStatus=='active'?true:false;
        flattenedObject["_id"]=element._id;
        flattenedObject["AssetName"]=element.Fields.AssetName;
        flattenedObject["PrimaryCategory"]=element.Fields.PrimaryCategory;
        this.flattenedData.push(flattenedObject);
    });





    this.utilityService.setDataForTable({
      reportData: this.flattenedData,
      total: data.count,
      items:this.pageSize,
      currentPage:this.currentpage,
      showPagination: 1,
      fields: this.Fields,
      tableName: "Asset List"
    });

    this.utilityService.setLoaderflagStatus(false);
  }

    ngOnDestroy() {
    if (this.fliterSubscription) {
      this.fliterSubscription.unsubscribe();
    }
    
  }



}
