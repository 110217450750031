import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilityService } from '../utility.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthGuardService } from './auth-guard.service';

/**
 * DID-NEXUS-012
 * (Auth Interceptor)
 * This intercept every outgoing request and appends necessary
 * properties to it that are processed by the backend
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private utilityService: UtilityService,
              private router: Router,
              public authGuardService: AuthGuardService) {
  }

  /**
   * (Intercept)
   * The intercept function required to be implmented by
   * HttpInterceptor
   */
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.utilityService.getAuthToken();
    // console.log("authToken",authToken);
    // to manipulate a request, first clone it. otherwise runs into error
    let headers: HttpHeaders;
    if (authToken) {
    headers = new HttpHeaders({
      'Authorization': `Bearer ${authToken}`,
      'pageRoute':this.router.url,
      'sessionId': localStorage.getItem('sessionId'),
      'userName': localStorage.getItem('userName')
    });
  }
    const authRequest = req.clone({headers});
    return next.handle(authRequest).pipe(tap((e) => {
      if (e instanceof HttpResponse && e.headers.get("TokenChanged")) {
        let tokenHeaders = e.headers.get("TokenChanged").split('_');
        if (tokenHeaders[1] === localStorage.getItem("AccessToken")) {
          localStorage.setItem("AccessToken", tokenHeaders[0]);
          e.clone({
            headers: e.headers.delete('TokenChanged')
          })
        } else {
          e.clone({
            headers: e.headers.delete('TokenChanged')
          })
        }
        // console.log("Access Token Changed",e.headers);
      }
    }, (err: any) => {
      console.log(err);
      if (err instanceof HttpErrorResponse) {
        if (err.status !== 401) {
         return;
        }
        // console.log("401 error arivied");
        this.utilityService.logout().subscribe(res=>{
          localStorage.removeItem("AccessToken");
          localStorage.removeItem("userName");
          localStorage.removeItem("sessionId");
          this.authGuardService.clearPermission();
          this.utilityService.mapFlag = false;
          this.router.navigate(["/login"], { replaceUrl: true });
        });
      }
    }));
  }
}
