import { Component, OnInit, AfterViewChecked, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'; // Router Module imported but not used
import { UtilityService } from './utility.service';
import { DeviceService } from './device.service';
import { Subscription } from 'rxjs';
import { StorageConfig } from './storage/storage-config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {

  title = 'icumed-web';
  // tslint:disable-next-line: variable-name
  public _router: string;
  public token;
  public browser_support = false;
  public notfound = false;
  private fourZeroFour: Subscription;
  localFileCount = 0;

  constructor(public deviceService: DeviceService,private router: Router, private route: ActivatedRoute, public utilityService: UtilityService) {
    this._router = router.url;
    this.token = localStorage.getItem('AccessToken');
  }

  async ngOnInit() {
    this.fourZeroFour = this.utilityService.getFourZeroFour().subscribe(data => {
      this.notfound = data;
    });
    this.browser_error();
    this.setPatientDataAccess();
    let storageProvider = StorageConfig.getInstance().getStorageProvider();
    this.localFileCount = await storageProvider.getFiles();
    setInterval(async () => {
      let bucketFileCount = await storageProvider.getFileCount();
      if(bucketFileCount !== -1 && bucketFileCount !== this.localFileCount) this.localFileCount = await storageProvider.getFiles();
    }, 30000);
  }

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this.token = localStorage.getItem('AccessToken');
    });
  }


  browser_error() {
    var browser: any = {};
    if (/(Edge\/[0-9]{2})/i.test(navigator.userAgent)) {
      browser.agent = navigator.userAgent.match(/(Edge\/[0-9]{2})/i)[0].split("/")[0];
    } else if (/(MSIE\ [0-9]{1})/i.test(navigator.userAgent)) {
      browser.agent = navigator.userAgent.match(/(MSIE\ [0-9]{1})/i)[0].split(" ")[0];
    } else {
      browser.agent = false;
      browser.version = false;
    }

    console.log(browser.agent);

    if (browser.agent === "MSIE" || browser.agent === 'Edge') {
      this.browser_support = true;
      window.location.href = '#/not_support'; // it can be redirected using navigation object

    }
  }

  setPatientDataAccess() {
    this.utilityService.get('/api/user/patientDataAccess', res => {
      this.utilityService.setPatientDataAccess(res.patientDataAccess);
    }, err => {
      console.log(err);
    });
  }

  /**
   * (ngOnDestroy)
   * this function is called when angular destroys this component
   */
  ngOnDestroy() {
    if (this.fourZeroFour) { this.fourZeroFour.unsubscribe(); }
  }
}
