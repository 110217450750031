import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UtilityService } from 'src/app/utility.service';
import { DeviceService } from 'src/app/device.service';
import { environment } from "../../../environments/environment";
const BACKEND_URL = environment.locationUrl;

/**
 * This is the component for adding home location of an asset
 */
@Component({
  selector: 'app-home-location',
  templateUrl: './home-location.component.html',
  styleUrls: ['./home-location.component.css']
})
export class HomeLocationComponent implements OnInit {
  @Output() shareEvent: EventEmitter<any> = new EventEmitter<string>();
  @Output() childEvent = new EventEmitter();
  @Input() assetDetails: any;
  selected:any = {};
  public location = [];
  public keys = [];
  public selectedData = {};
  public dropDownValue:any = {};
  constructor(public utilityService: UtilityService,private deviceService: DeviceService) { }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.getLocationHierarchyFilter();
    console.log(this.assetDetails);
  }



  /**
   * (getLocationHierarchyFilter)
   * Description:
   * this function is called for filteration of location levels
   */
  getLocationHierarchyFilter() {
    let url = '/location/api/location.getLocationOptionsByLevel';
    this.utilityService.doPost({ Level_1: null }, url, res => {
        //console.log(res);
        this.dropDownValue[res.level] = res;
        this.location = Object.values(this.dropDownValue);
      },
      err => {
        console.log(err);
      }
    );
  }


  /**
   * (saveHomeLocation)
   * Description:
   * this function saves asset home location
   */
  saveHomeLocation() {
    console.log(this.selectedData);
    let url = `asset.saveHomeLocation`;
    this.deviceService.post({
      AssetType: this.assetDetails.AssetType,
      AssetId: this.assetDetails.AssetId,
      level: this.selectedData
    }, 
    url, (res) => {
      console.log(res);
      this.childEvent.emit(1);
    }, (err) => {
      console.log(err);
    });
  }


  /**
   * (saveData)
   * Description:
   * this function saves home location of device in db.
   */
  async saveData(loc, location) {
    console.log(loc,location);
    let temp = {};
    // if location is empty show all sites
    if (location != "") {
      console.log(this.selectedData[loc]);
      let ObjKeys = Object.keys(this.dropDownValue);
      console.log(ObjKeys);
      let i = ObjKeys.indexOf(loc);
      for (let index = i+1; index < ObjKeys.length; index++) {
        const element = ObjKeys[index];
        delete this.selectedData[element];
      }
      temp[loc] = location;

      this.selectedData[loc] = location;
      let data = await this.getOptionsByLevel(temp);
      if (Object.values(data)[0] != "") {
        this.dropDownValue[data['level']] = data;
        this.location = Object.values(this.dropDownValue);
      }
    } else { // if any level of location is selected, then shows there child
      let ObjKeys = Object.keys(this.dropDownValue);
      let i = ObjKeys.indexOf(loc);
      for (let index = i+1; index < ObjKeys.length; index++) {
        const element = ObjKeys[index];
        delete temp[loc];
        delete this.dropDownValue[element];
        delete this.selectedData[element];
      }
      delete this.selectedData[loc];
      // if loaction level is not Level_1
      if (loc != 'Level_1') {
        delete this.dropDownValue[loc];
        this.location = Object.values(this.dropDownValue);
      } else if (loc == 'Level_1') { // if loaction level is  Level_1
        console.log(this.dropDownValue);
        this.location = Object.values(this.dropDownValue);
      }
    }
    // send home loaction to parent component
    this.shareEvent.emit(this.selectedData);
  }


  /**
   * (getOptionsByLevel)
   * Description:
   * this function returns list of loaction on save level, by passing level
   */
  getOptionsByLevel(query) {
   return new Promise((resolve, reject) => {
      let url = BACKEND_URL+'location.getLocationOptionsByLevel';
      this.utilityService.postLocationService(url, query, (data) => {
          resolve(data);
        });
      });
  }

}
