import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report-service.service';
import { UtilityService } from "../../utility.service";

/**
 * DID-NEXUS-058
 * Movement vs Time Report Page
 */
@Component({
  selector: 'app-movement-vs-time',
  templateUrl: './movement-vs-time.component.html',
  styleUrls: ['./movement-vs-time.component.css']
})
export class MovementVsTimeComponent implements OnInit {

  dataSource: any;
  url: any;
  rawUrl: any;
  headerdata = {};
  constructor(public reportService: ReportService, public utilityService: UtilityService) { }

/**
 * (ngOnInit)
 * Description:
 * this function called when angular initializes the component
 */
  ngOnInit() {
    this.url = document.URL.split("/");
    this.rawUrl = this.url[this.url.length - 1];

    this.utilityService.getLangValue("help").then(res => {
        this.headerdata = {
          helpdata: res.help[this.rawUrl],
          pageName: res[this.rawUrl]
        }
        console.log(res.help[this.rawUrl]);
        this.utilityService.setHeaderHelpData(this.headerdata);
    });
  }


/**
 * (Get Filter Data)
 * Description:
 * this function receives data from filter component
 */
  getFilterData($event) {
    this.dataSource = $event;
  }


/**
 * (Get Page Name)
 * Description:
 * this function sends filter api url and report api url to filter and report.
 */
  getPageName() {
    return { filterUrl: 'report.getFilterDataForReport/movement-vs-time', reportUrl: 'movementVsTime' };
  }
}
