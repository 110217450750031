import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from "@angular/core";
import { Subscription } from 'rxjs';
import { UtilityService } from "src/app/utility.service";
import { environment } from "../../../environments/environment";
const BACKEND_URL = environment.locationUrl;

/**
 * filter list location component
 * Description:
 * this is location filter component
 */
@Component({
  selector: "app-filter-list-location",
  templateUrl: "./filter-list-location.component.html",
  styleUrls: ["./filter-list-location.component.css"]
})
export class FilterListLocationComponent implements OnInit, OnDestroy {
  @Output() shareEvent: EventEmitter<any> = new EventEmitter<string>();
  @Input() childMessage: string;
  @Input() childMessage1: string;
  @Output() childEvent = new EventEmitter();
  private resetLocationFilterSubscription: Subscription;
  selected: any = {};
  public location = [];
  public keys = [];
  public selectedData = {};
  public dropDownValue: any = {};
  icon: boolean = false;
  constructor(
    public utilityService: UtilityService,
  ) { }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    // set icon form form and to
    if (typeof this.childMessage1 == 'undefined' && typeof this.childMessage == 'undefined') {
      this.icon = true;
    }
    this.getLocationHierarchyFilter();
    this.resetLocationFilterSubscription = this.utilityService.getResetLocationObservable().subscribe(data => {
      this.selectedData = {};
      this.dropDownValue = {};
      this.location = [];
      this.getLocationHierarchyFilter();
    });
  }

  /**
   * (getKeys)
   * Description:
   * this function returns selected location
   */
  getKeys() {
    let temp = {};
    let keys = Object.keys(this.selectedData).sort();
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < keys.length; index++) {
      const element = keys[index];
      temp[element] = this.selectedData[element];
    }
    return Object.values(temp);
  }

  /**
   * (ObjectToArray)
   * Description:
   * this function returns array of object keys.
   */
  ObjectToArray() {
    this.location = Object.values(this.dropDownValue);
  }

  /**
   * (getLocationHierarchyFilter)
   * Description:
   * this function returns hierarchy title, location level and dropdown options of first level
   */
  getLocationHierarchyFilter() {
    let url = '/location/api/location.getLocationOptionsByLevel';
    this.utilityService.doPost({ Level_1: null }, url, res => {
        this.dropDownValue[res.level] = res;
        this.location = Object.values(this.dropDownValue);
      },
      err => {
        console.log(err);
      }
    );
  }

  /**
   * (saveData)
   * Description:
   * this function returns selected location to filter component.
   */
  async saveData(ht, hierarchyLevel, hierarchyName, hierarchyParent) {
    var custom_location_filter_ht: HTMLElement = document.getElementById(ht);
    custom_location_filter_ht.style.height = 'auto';
    let temp = {};
    if (hierarchyName != "") {
      let ObjKeys = Object.keys(this.dropDownValue);
      let i = ObjKeys.indexOf(hierarchyLevel);
      for (let index = i + 1; index < ObjKeys.length; index++) {
        const element = ObjKeys[index];
        delete this.selectedData[element];
      }
      temp[hierarchyLevel] = hierarchyName;
      temp['parent'] = hierarchyParent;

      this.selectedData[hierarchyLevel] = hierarchyName;
      let data = await this.getOptionsByLevel(temp);
      if (Object.values(data)[0] != "") {
        this.dropDownValue[data["level"]] = data;
        this.location = Object.values(this.dropDownValue);
      }
    } else {
      let ObjKeys = Object.keys(this.dropDownValue);
      let i = ObjKeys.indexOf(hierarchyLevel);
      for (let index = i + 1; index < ObjKeys.length; index++) {
        const element = ObjKeys[index];
        delete temp[hierarchyLevel];
        delete this.dropDownValue[element];
        delete this.selectedData[element];

      }
      delete this.selectedData[hierarchyLevel];
      if (hierarchyLevel != "Level_1") {
        delete this.dropDownValue[hierarchyLevel];
        this.location = Object.values(this.dropDownValue);
      } else if (hierarchyLevel == "Level_1") {
        console.log(this.dropDownValue);
        this.location = Object.values(this.dropDownValue);
      }
    }

    this.shareEvent.emit(this.selectedData);
  }

  /**
   * (getOptionsByLevel)
   * Description:
   * this function returns dropdown options by passing hierarchy location.
   */
  getOptionsByLevel(query) {
    return new Promise((resolve, reject) => {
      let url = BACKEND_URL + "location.getLocationOptionsByLevel";
      this.utilityService.postLocationService(url, query, data => {
        resolve(data);
      });
    });
  }

  /**
   * (ngOnDestroy)
   * this function is called when angular destroys this component
   */
  ngOnDestroy() {
    if (this.resetLocationFilterSubscription) { this.resetLocationFilterSubscription.unsubscribe(); }
  }
}
