import { environment } from "src/environments/environment";
import { LocalStorageProvider } from "./local-storage-provider";
import { S3StorageProvider } from "./s3-storage-provider";
import { StorageProvider } from "./storage-provider-interface";


export class StorageConfig {

  private static instance: StorageConfig;

  private constructor() { }

  static getInstance() : StorageConfig {
    if(!StorageConfig.instance) {
      StorageConfig.instance = new StorageConfig();
    }
    return StorageConfig.instance;
  }

  public getStorageProvider() : StorageProvider {
    switch(environment.value) {
      case 'docker':
        return S3StorageProvider.getInstance();
      case 'test' :
      case 'prod' :
        return LocalStorageProvider.getInstance();
      default:
        return LocalStorageProvider.getInstance();
    }
  }
}
