import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/utility.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-encryption-setting',
  templateUrl: './encryption-setting.component.html',
  styleUrls: ['./encryption-setting.component.css']
})
export class EncryptionSettingComponent implements OnInit {
  fields: any;
  fieldData: any
  selectedField: any;
  subFields: any;
  AssetType: any;
  selectedType: any;
  selectedSubField = [];
  encryptionConfig = []
  configTreeData: any = {};
  allField: any;
  multipleSelectCond = {
    Fields: false,
    AssetType: true
  }
  constructor(private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.getAllFields("Equipment")
    this.getMetaDataMapping();
 
    const BACKEND_URL = environment.apiUrl;
    const serverUrl = BACKEND_URL + "assets.getAllEncryptionConfig";
    this.utilityService.get(serverUrl, res => {
    this.allField = res;
    res.forEach(element => {
      const keys = Object.keys(element)
      this.configTreeData[keys[1]] = element._id
      });
     }, err => {
    })

  }
  toggle(event) {
    event.target.parentElement.querySelector(".nested").classList.toggle("active");
    event.target.classList.toggle("caret-down");
  }
  getParentName(data) {
    const keys = Object.keys(data)
    return keys[1];
  }
  getSubChild(data, flag) {
    const parentkey = this.getParentName(data);
    const keys = Object.keys(data[parentkey])
    const subChildArray = [];
    keys.forEach(element => {
      if (flag == "string") {
        if (typeof data[parentkey][element] == "string") {
          subChildArray.push(data[parentkey][element]);
        }
      } else {
        if (flag == 'array') {
          if (typeof data[parentkey][element] != "string") {
            subChildArray.push(element);
          }
        }
      }
    });
    return subChildArray;
  }
  getSubsubChild(data, subchild) {
    const parentkey = this.getParentName(data);
    const keys = Object.values(data[parentkey][subchild])
    return keys;
  }
  getMetaDataMapping() {
    const BACKEND_URL = environment.apiUrl;
    const serverUrl = BACKEND_URL + "asset.getMetaDataMapping"
    this.utilityService.get(serverUrl, res => {
      this.AssetType = Object.keys(res);
      this.AssetType.shift();
    }, err => {
      console.log(err);
    })
  }
  getAllFields(type) {
    this.utilityService.post({ "AssetType": type }, "assets.getAllFields", res => {
      if (res) {
        this.fieldData = res[0];
        this.fields = (Object.keys(res[0]));
      }
    })
  }
  getDataByType() {
    this.getAllFields(this.selectedType)
    this.selectedField = "";
    this.selectedSubField = [];
    this.fields = [];
    this.subFields = [];
  }
  getSubField() {
    this.subFields = Object.keys(this.fieldData[this.selectedField]);
  }
  saveEncryptionSetting() {
    let encryptionObj = {}
    if (this.selectedSubField.length > 0) {
      if (this.configTreeData[this.selectedType]) {
        encryptionObj = {
          "$set": {
            [this.selectedType + "." + this.selectedField]: this.selectedSubField
          }
        }
      } else {
        encryptionObj[this.selectedType] = {}
        encryptionObj[this.selectedType][this.selectedField] = {};
        encryptionObj[this.selectedType][this.selectedField] = this.selectedSubField
      }
    } else {
      if (this.configTreeData[this.selectedType]) {
        encryptionObj = {};
        this.selectedSubField = [];
        encryptionObj = {
          "$set": {
            [this.selectedType + "." + this.selectedField]: this.selectedField
          }
        }
      } else {
        encryptionObj[this.selectedType] = {}
        encryptionObj[this.selectedType][this.selectedField] = {};
        encryptionObj[this.selectedType][this.selectedField] = this.selectedField
      }
    }
    if (this.configTreeData[this.selectedType]) {
      this.utilityService.post(encryptionObj, "assets.updateEncryptionConfig/" + this.configTreeData[this.selectedType], res => {
        console.log(res);
      })
    } else {
      this.utilityService.post(encryptionObj, "assets.saveEncryptionConfig", res => {
        console.log(res);
      })
    }
  }

}
