import { Component, OnInit, Output, EventEmitter, OnDestroy, } from '@angular/core';
import { ReportService } from '../report-service.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UtilityService } from 'src/app/utility.service';
import { DeviceService } from 'src/app/device.service';

/**
 * DID-NEXUS-002, DID-NEXUS-003, DID-NEXUS-011,
 * DID-NEXUS-031, DID-NEXUS-033, DID-NEXUS-035,
 * DID-NEXUS-044, DID-NEXUS-059, DID-NEXUS-068
 * DID-NEXUS-069, DID-NEXUS-070, DID-NEXUS-072,
 * DID-NEXUS-073
 * Custom Report Page
 */
@Component({
  selector: 'app-custom-report',
  templateUrl: './custom-report.component.html',
  styleUrls: ['./custom-report.component.css']
})
export class CustomReportComponent implements OnInit, OnDestroy {

  public customReport: any;
  public Devices = [];
  public subtype;
  @Output() DataEvent = new EventEmitter();
  @Output() ExportDataEvent = new EventEmitter();
  headerdata = {};

  // tslint:disable-next-line: max-line-length
  constructor(public reportService: ReportService, public route: ActivatedRoute, public router: Router, public utilityService: UtilityService, public deviceService: DeviceService, ) {

  }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('reportId')) {
        const reportId = paramMap.get('reportId');
        this.reportService.get('custom.getCustomReportById/' + reportId, report => {
          this.customReport = report;
          this.headerdata = {
            helpdata: this.customReport.Description,
            pageName: this.customReport.Name
          };
          this.utilityService.setHeaderHelpData(this.headerdata);
          this.subtype = this.customReport.Subject;
          // tslint:disable-next-line: max-line-length
          this.reportService.setCustomReportFetched({ filterUrl: 'report.getFilterFields/status-fence', reportUrl: 'report.deviceProperties', subject: this.subtype});
          this.buildReport();
        }, error => {
        });
      }
    });

  }

  /**
   * (Build Report)
   * sends the report data to respective components to build the report
   */
  buildReport() {
    // tslint:disable-next-line: max-line-length
    this.reportService.setReportData({target: this.customReport.TargetField, columns: this.customReport.Fields, tableName: this.customReport.Name , Subject: this.subtype });
  }

  ngOnDestroy() {
    this.subtype = null;
    this.customReport = null;
    this.Devices = [];
  }
}
