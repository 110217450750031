import { Component, OnInit } from '@angular/core';

/**
 * DID-NEXUS-009
 * Not supported on internet explorer page
 */
@Component({
  selector: 'app-not-support',
  templateUrl: './not-support.component.html',
  styleUrls: ['./not-support.component.css']
})
export class NotSupportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
