import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/utility.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DeviceService } from 'src/app/device.service';
import { StorageConfig } from 'src/app/storage/storage-config';
import { StorageProvider } from 'src/app/storage/storage-provider-interface';
import { DomSanitizer } from '@angular/platform-browser';
const BACKEND_URL = environment.locationUrl;

@Component({
  selector: 'app-all-assets-map-view',
  templateUrl: './all-assets-map-view.component.html',
  styleUrls: ['./all-assets-map-view.component.css']
})
export class AllAssetsMapViewComponent implements OnInit {

  mapList: any = [];
  query: any = [];
  start: number = 0;
  mapImageSrc;
  mapTitle: string;
  mapParameters: any = {};
  idMap: string;
  Pointer = [];
  changeText = {};
  showPointerFlag: boolean = false;
  public min_zoom: number = 1 / 2;
  public max_zoom: number = 3 / 2;
  public current_val: number = 1;

  dataSource: any;
  url: any;
  rawUrl: any;
  headerdata = {};
  patientDataAccess;
  storageProvider: StorageProvider;

  constructor(public utilityService: UtilityService, private http: HttpClient, public deviceService: DeviceService, public bypassSecurityTrustUrl: DomSanitizer) {
    this.storageProvider = StorageConfig.getInstance().getStorageProvider();
   }

  ngOnInit() {
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 1];

    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      console.log(res.help[this.rawUrl]);
      this.utilityService.setHeaderHelpData(this.headerdata);

    });
    this.getMapList();
    this.patientDataAccess = this.utilityService.isPatientDataAccessAllowed();
  }

  /**
   * (getMapList)
   * Description:
   * this function return list of maps.
   */
  getMapList() {
    this.mapTitle = '';
    this.idMap = '';
    this.mapImageSrc = '';
    this.utilityService.setLoaderflagStatus(true);
    this.deviceService.post(
      this.query,
      'assets.getfilterMap',
      res => {
        this.mapList = res;
        console.log(this.mapList.length);

        if (this.mapList.length > 0) {
          this.getPointer(0);
        } else {
          this.mapImageSrc = null;
          this.mapTitle = null;
          this.utilityService.setLoaderflagStatus(false);
        }
      },
      err => {
        this.utilityService.setLoaderflagStatus(false);
      }
    );
  }

  /**
   * (getPointer)
   * Description:
   * this function gets Pointer for map
   */
  getPointer(index) {
    this.showPointerFlag = true;
    this.loadMap(this.mapList[index].mapId, res => {
      if (res) {
        this.utilityService.setLoaderflagStatus(false);
        this.mapTitle = this.mapList[index].name;
        this.idMap = this.mapList[index].mapId;
      } else {
        this.utilityService.setLoaderflagStatus(false);
      }
    });
  }

  /**
   * (loadMap)
   * Description:
   * this function loads the map.
   */
  loadMap(mapId, cb) {
    this.mapImageSrc = this.getMap(`var/uploads/images/Maps/${mapId}.jpg`);
    if (this.mapImageSrc) {
      this.utilityService.setLoaderflagStatus(false);
      cb(true);
    } else {
      this.utilityService.setLoaderflagStatus(false);
      cb(false);
    }
  }

  /**
   * (dosomething)
   * Description:
   * this function get loction of asstes after map loads.
   */
  onMapLoadFinished(mapId) {
    this.utilityService.setLoaderflagStatus(true);
    this.getImageDemension(mapId, data => {
      if (data) {
        this.getMapDataList(mapId, (data) => {
          console.log(this.Pointer);
        });
      }
    });
    this.utilityService.setLoaderflagStatus(false);
  }

  getMap(filePath:string) : any {
    let image = this.storageProvider.getImageFile(filePath);
    if(image) return image.startsWith('data:image/') ? this.bypassSecurityTrustUrl.bypassSecurityTrustUrl(image) : image;
    else return null;
  }

  /**
   * (getImageDemension)
   * Description:
   * this function calculates map demision (front end)
   */
  getImageDemension(mapId, cb) {
    this.mapParameters = {};
    const serverUrl = BACKEND_URL + 'location.getMap/' + mapId;
    this.http.get(serverUrl).subscribe(
      res => {
        if (typeof res['xDimension'] !== 'undefined' && typeof res['yDimension'] !== 'undefined') {
          if (document.getElementById(mapId)) {
            this.mapParameters = {
              xView: document.getElementById(mapId).offsetWidth,
              yView: document.getElementById(mapId).offsetHeight,
              xDimension: res['xDimension'],
              yDimension: res['yDimension']
            };
          }
        }
        if (Object.keys(this.mapParameters).length > 0) {
          cb(true);
        }
      },
      err => {
        console.log(err);
        this.utilityService.setLoaderflagStatus(false);
      }
    );
  }

  /**
   * (getCurrentLocation)
   * Description:
   * this function calculates pointer location(x,y) according to front-end map.
   */
  setAllPointerCoordinates(CurrentLocation) {
    let styles: any;
    const x = (this.mapParameters.xView / this.mapParameters.xDimension) * CurrentLocation.xCenterPoint;
    const y = (this.mapParameters.yView / this.mapParameters.yDimension) * CurrentLocation.yCenterPoint;
    styles = {
      position: 'absolute',
      left: x + 'px',
      top: y - 20 + 'px',
      width: '37px'
    };
    return styles;
  }

  /**
   * (getMapDataList)
   * Description:
   * this function returns location by paticular mapId.
   */
  getMapDataList(mapId, cb) {
    this.Pointer = [];
    this.query[this.query.length] = { 'CurrentLocation.mapId': mapId };
    this.deviceService.post(
      this.query,
      'assets.MapsPointerAllAssets',
      res => {
        if (res.data.length > 0) {
          this.Pointer = res.data;
          for(let location of this.Pointer) {
            let fullName = '';
            let fullLocation = location.CurrentLocation.fullPath;
            for(let level in fullLocation) {
              fullName = fullName + fullLocation[level] + '>';
            }
            fullName = fullName.substring(0, fullName.length - 1);
            location.CurrentLocation.fullPath = fullName;
          }
          cb(res.data);
        } else {
          this.utilityService.setDataForTable({});
        }
      },
      err => {
        console.log(err);
        this.utilityService.setLoaderflagStatus(false);
      }
    );
  }

  /**
   * (toolTipStyle)
   * Description:
   * this function calculate tool tip location(x,y) according to front-end map.
   */
  toolTipStyle(each) {
    const param = this.mapParameters;
    let styles: any;
    if (typeof param !== 'undefined') {
      const x = (param.xView / param.xDimension) * each.xCenterPoint;
      const y = (param.yView / param.yDimension) * each.yCenterPoint;
      // console.log(param); //xView
      styles = {
        position: 'absolute',
        left: x - 96 + 'px',
        top: y + 55 + 'px',
        width: '230px',
        'z-index': '1',
      };
    }
    return styles;
  }

  /**
   * (previousMap)
   * Description:
   * this function shows previous map on previous btn click.
   */
  previousMap() {
    if (this.start > 0) {
      this.start = this.start - 1;
    }
    if (this.start >= 0 && this.start <= this.mapList.length) {
      this.getPointer(this.start);
    }
  }

  /**
   * (nextMap)
   * Description:
   * this function shows next map on next btn click.
   */
  nextMap() {
    if (this.start !== this.mapList.length) {
      this.start = this.start + 1;
    }
    if (this.start < this.mapList.length) {
      this.getPointer(this.start);
    }
  }

  /**
   * (showTooltip)
   * Description:
   * this function shows detail on hover
   */
  showTooltip(i) {
    this.changeText[i] = true;
  }

  /**
   * (hideTooltip)
   * Description:
   * this function hides the detail on hover
   */
  hideTooltip(i) {
    this.changeText[i] = false;
  }

  /**
   * (openNewTab)
   * Description:
   * this function open asset detail page in new tab
   */
  openNewTab(asset) {
    window.open(`/#/asset-detail;type=${asset.AssetType};id=${asset.AssetId}`);
  }

  /**
   * (zoom_in)
   * Description:
   * this function is used to zoom map.
   */
  zoom_in() {
    var zoom_slider = <HTMLInputElement> (document.getElementById('zoom_slider'));
    if (this.current_val < this.max_zoom) {
      this.current_val = (parseFloat(zoom_slider.value) * 10 + .1 * 10) / 10;
    }
    // console.log(this.min_zoom, (parseFloat(zoom_slider.value) * 10 + .1 * 10) / 10, zoom_slider.value)
    zoom_slider.value = (this.current_val).toString();
    this.zoom_func(this.current_val);
  }

  /**
   * (zoom_out)
   * Description:
   * this function is used to zoom out map.
   */
  zoom_out() {
    var zoom_slider = <HTMLInputElement> (document.getElementById('zoom_slider'));
    if (this.min_zoom < this.current_val) {
      this.current_val = (parseFloat(zoom_slider.value) * 10 - .1 * 10) / 10;
    }
    // console.log(this.current_val)
    zoom_slider.value = (this.current_val).toString();
    this.zoom_func(this.current_val);
  }

  /**
   * (zoom_slider)
   * Description:
   * this function is used for zoom slider.
   */
  zoom_slider(e) {
    var zoom_slider = e.target.value;
    // console.log(zoom_slider);
    this.zoom_func(zoom_slider);
  }

  /**
   * (zoom_func)
   * Description:
   * this function is used for zoom map.
   */
  zoom_func(n) {
    var zoom_id = document.getElementById('zoom_div');
    zoom_id.setAttribute('style', 'transform:scale(' + n + ')');
  }
}
