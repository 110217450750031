import { StorageProvider } from "./storage-provider-interface";


export class LocalStorageProvider implements StorageProvider {

  private static instance: LocalStorageProvider;

  private constructor() {}

  static getInstance() : LocalStorageProvider {
    if(!LocalStorageProvider.instance) {
      LocalStorageProvider.instance = new LocalStorageProvider();
    }
    return LocalStorageProvider.instance;
  }

  writeFile(file: any) {
    // no need to implement
    return;
  }

  getImageFile(filePath: string) {
    return filePath;
  }

  getFiles() {
    // no need to implement
    return;
  }

  getFileCount() {
    // no need to implement
    return;
  }

}
