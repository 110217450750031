import { Component, OnInit} from "@angular/core";
import { UtilityService } from "../../utility.service";
import { FormControl, Validators } from "@angular/forms";
export interface AssetList { name: string; value: string; };
import { MatDialog } from "@angular/material/dialog";

/**
 * DID-NEXUS-045, DID-NEXUS-046, DID-NEXUS-106
 * Asset Data Import Page
 */
@Component({
  selector: "app-device-import",
  templateUrl: "./device-import.component.html",
  styleUrls: ["./device-import.component.css"]
})
export class DeviceImportComponent implements OnInit {

  selectFormControl = new FormControl("", Validators.required);
  fields: any;
  type: string;
  url: any;
  rawUrl: any;
  headerdata = {};
  header;
  isDataValid = true;
  public import;
  constructor(
    public utilityService: UtilityService,
    public dialog: MatDialog
  ) { }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes this component
   */
  ngOnInit() {
    this.url = document.URL.split("/");
    this.rawUrl = this.url[this.url.length - 1];

    this.utilityService.getLangValue("help").then(res => {
        this.headerdata = {
          helpdata: res.help[this.rawUrl],
          pageName: res[this.rawUrl]
        }
        console.log(res.help[this.rawUrl]);
        this.utilityService.setHeaderHelpData(this.headerdata);
    });
  }

  /**
   * (receiveFields)
   * Description:
   * this function receives the csv file data
   */
  async receiveFields($event) {
    this.utilityService.setLoaderflagStatus(true);
    this.type = $event.type;
    this.fields = $event.data;
    if (this.type == "xml") {
      this.fields = await this.makeTwoDemensionArray();
    } else if (this.type == 'json') {
      try {
        JSON.parse(this.fields);
        this.isDataValid = true;
      } catch (e) {
        this.isDataValid = false;
        alert('Invalid JSON format detected in file');
      }
    }
    // csv data is already parsed
    this.utilityService.setLoaderflagStatus(false);
  }

  /**
   * (makeTwoDemensionArray)
   * Description:
   * this function reads data of csv if it is in xml
   */
  makeTwoDemensionArray() {
    return new Promise((resolve, reject) => {
      var finalData = new Array();
      delete this.fields[0]["#text"];
      this.header = Object.keys(this.fields[0]);
      for (var index = 0; index < this.fields.length; index++) {
        const element = this.fields[index];
        delete this.fields[index]["#text"];
        finalData[index] = [];
        for (var i = 0; i < this.header.length; i++) {
          const eachHead = this.header[i];
          if (typeof element[eachHead] != "undefined") {
            finalData[index][i] = element[eachHead];
          } else {
            finalData[index][i] = "NA";
          }
        }
      }
      resolve(finalData);
    });
  }

  /**
   * (saveData)
   * Description:
   * save the imported csv data to the database
   */
  saveData() {
    const temp = { header: "", fields: "" };
    temp.header = JSON.stringify(this.header);
    temp.fields = this.import === 'asset.importMetaDataMapping' ? this.fields : JSON.stringify(this.fields);
    console.log(this.import);
    if (this.import) {
      this.utilityService.setLoaderflagStatus(true);
      this.utilityService.post(temp, this.import, res => {
        this.utilityService.setLoaderflagStatus(false);
        alert('Data Imported Successfully!');
      });
    } else {
      alert('Please select type of import from dropdown');
    }
  }
}

