import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/utility.service';

/**
 * Admin configuration page, can be used for configuring various properties
 */
@Component({
  selector: 'app-admin-configurations',
  templateUrl: './admin-configurations.component.html',
  styleUrls: ['./admin-configurations.component.css']
})
export class AdminConfigurationsComponent implements OnInit {
  geofenceSettings = {
    Type: 'Geofence',
    SubType: 'Geofence',
    Fields: {
      GeofenceTypes: []
    }
  };
  geofenceList: any;
  enteredType;
  url: string[];
  rawUrl: any;
  headerdata: { helpdata: any; pageName: any; };

  constructor(public utilityService: UtilityService) { }
  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    // title and help
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
    this.utilityService.get('/api/settings/getGeofenceSettings', res => {
      if (JSON.stringify(res) !== '[]') {
        this.geofenceSettings = res;
      }
    }, err => {
      console.log(err);
    });
    this.utilityService.get('/location/api/geofence/get', res => {
      this.geofenceList = res;
    }, err => {
      console.log(err);
    });
  }

  /**
   * (addGeofenceType)
   * Description:
   * this function is used to add geofence type by admin
   */
  addGeofenceType() {
    if (!this.geofenceSettings.Fields.GeofenceTypes.includes(this.enteredType)) {
      this.geofenceSettings.Fields.GeofenceTypes.push(this.enteredType);
      this.modifyGeofenceSettings();
      this.enteredType = null;
    } else {
      alert('Geofence Type already present!');
    }
  }

  removeGeofence(geofence) {
    this.utilityService.get('/location/api/geofence/delete/' + geofence._id, res => {
      this.geofenceList.splice(this.geofenceList.indexOf(geofence), 1);
      alert(res.message);
    }, err => {
      console.log(err);
    });
  }

  /**
   * (removeGeofenceType)
   * Description:
   * this function is used to remove geofence type by admin
   */
  removeGeofenceType(type) {
    if (this.geofenceSettings.Fields.GeofenceTypes.includes(type)) {
      this.geofenceSettings.Fields.GeofenceTypes.splice(this.geofenceSettings.Fields.GeofenceTypes.indexOf(type), 1);
      this.modifyGeofenceSettings();
    }
  }

  /**
   * (modifyGeofenceSettings)
   * Description:
   * this function sends the geofence types to the server
   */
  modifyGeofenceSettings() {
    this.utilityService.doPost(this.geofenceSettings, '/api/settings/create', res => {
      console.log(res);
    }, err => {
      console.log(err);
    });
  }
}
