import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
const BACKEND_URL = environment.reportApiUrl;

/**
 * Service dedicated to all report related functionalities
 */
@Injectable({
  providedIn: 'root'
})

export class ReportService {

  heatMapData: any = '';
  newReportCreatedListener = new Subject<{}>();
  reportTypeToReportForm = new Subject<{}>();
  heatMapListener = new Subject<{}>();
  mapFlag = new Subject<{}>();
  customReportFetched = new Subject<{}>();
  constructor(private http: HttpClient) { }
/**
 * (Post)
 * Description:
 * this function is used to call post api for report microservices
 */
  post(data, url: string, success, failure) {
    const serverUrl = BACKEND_URL + url;
    this.http.post(serverUrl, data).subscribe((res) => {
      success(res)
    }, (err) => {
      failure(err);
    }
    );
  }


/**
 * (Csv Data Get)
 * Description:
 * this function returns csv data for export report
 */
  csvdataget(data,url:string,success,failure)
  {
    const serverUrl = BACKEND_URL + url;
    this.http.post(serverUrl, data).subscribe((res) => {
      success(res)
    }, (err) => {
      failure(err);
    }
    );

  }


/**
 * (Get)
 * Description:
 * this function is used to call get api for report microservices
 */
  get(url, success, failure) {
    const serverUrl = BACKEND_URL + url;
    this.http.get(serverUrl).subscribe((res) => {
      success(res)
    }, (err) => {
      failure(err);
    });
  }

/**
 * (Get New Report Created Listener)
 * Description:
 * this event gets data on sendNewReportCreatedMessage event
 */
  getNewReportCreatedListener() {
    return this.newReportCreatedListener.asObservable();
  }

/**
 * (Get Map Flag)
 * Description:
 * this event gets data on setMapFlag event
 */
  getReportData(){
    return this.mapFlag.asObservable();
  }

/**
 * (Set Map Flag)
 * Description:
 * this event is used to send map data flag to component.
 */
  setReportData(flag){
    this.mapFlag.next(flag);
  }

/**
 * (Send New Report Created Message)
 * Description:
 * this event is used when new custom report is created
 */
  sendCustomReportChangeMessage() {
    this.newReportCreatedListener.next();
  }

/**
 * (sendReportTypeToReportForm)
 * Description:
 * this event is used to send report type to report form
 */
  sendReportTypeToReportForm(type){
    this.reportTypeToReportForm.next(type);
  }


/**
 * (Get Report Type To Report Form)
 * Description:
 * this event get data on sendReportTypeToReportForm event
 */
  getReportTypeToReportForm(){
    return this.reportTypeToReportForm.asObservable();
  }

 /**
  * (Get Change Heat Map Listener)
  * Description:
  * this event gets data on changeHeatMapListeners event
  */
  getChangeHeatMapListener() {
    return this.heatMapListener.asObservable();
  }

 /**
  * (Change Heat Map Listeners)
  * Description:
  * this event fires when we click heat map custom report.
  */
  changeHeatMapListeners(parameters) {
    this.heatMapListener.next(parameters);
  }

 /**
  * (Delete Custom Report From Database)
  * Description:
  * this function calls delete api of custom reports.
  */
  deleteCustomReportFromDb(url,success, failure){
    const serverUrl = BACKEND_URL +url;
    this.http.delete(serverUrl).subscribe((res)=>{
      success(res)
    },(err)=>{
      failure(err);
    }
    )

  }

 /**
  * (Get All List Report)
  * Description:
  * this function returns list of all custom reports.
  */
  getAllListReport(url,success, failure){
    const serverUrl = BACKEND_URL +url;

    this.http.get(serverUrl).subscribe((res)=>{
      success(res)
    },(err)=>{
      failure(err);
    }
    )
  }

 /**
  * (Edit Custom Report)
  * Description:
  * this function call edit api of custom reports.
  */
  editCustomReport(url,object,success, failure){
    const serverUrl = BACKEND_URL +url;
    console.log("sdf");
    this.http.put(serverUrl,object).subscribe((res)=>{
      success(res)
    },(err)=>{
      failure(err);
    }
    )
  }

  /**
   * (Get Custom Report Fetched Listener)
   * Description
   * Observable for alerting when a custom report is fetched
   * from db
   */
  getCustomReportFetchedListener() {
    return this.customReportFetched.asObservable();
  }

  /**
   * (set Custom Report Fetched)
   * Description
   * Broadcast for when custom report is fetched succesfully
   */
  setCustomReportFetched(data) {
    this.customReportFetched.next(data);
  }
}
