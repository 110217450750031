import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DeviceService } from "src/app/device.service";
import { ReportService } from "src/app/reports/report-service.service";
import {
  ActivatedRoute,
  ParamMap,
  Router,
  NavigationEnd,
} from "@angular/router";
import { UtilityService } from "src/app/utility.service";
import { PageEvent } from "@angular/material/paginator";
import { Location } from "@angular/common";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { environment } from "../../../environments/environment";
import { ThemePalette } from "@angular/material/core";
import { StorageConfig } from "src/app/storage/storage-config";
import { StorageProvider } from "src/app/storage/storage-provider-interface";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
const BACKEND_URL = environment.locationUrl;

/**
 * DID-NEXUS-010, DID-NEXUS-011, DID-NEXUS-047,
 * DID-NEXUS-059, DID-NEXUS-062, DID-NEXUS-063
 * This page displayes all the details of a specific asset
 */
@Component({
  selector: "app-device-detail",
  templateUrl: "./device-detail.component.html",
  styleUrls: ["./device-detail.component.css"],
})
export class DeviceDetailComponent implements OnInit {
  mapQuery: any = [];
  staticDetailsArray: Array<String> = [
    "AssetName",
    "SerialNo",
    "PrimaryCategory",
    "WifiStrength",
    "BatteryLevel",
    "VolumeInfused",
  ];
  dynamicDetailsArray: Array<String> = [];
  device: any;
  deviceId: any;
  perPageItems = 5;
  currentPage = 1;
  perPageLItems = 5;
  currentLPage = 1;
  heading = [];
  dialogHeading = [];
  locationTableHeadings = [];
  locationDataArray: any = [];
  end: number;
  homeLocation: any;
  statusDataArray: any = [];
  statusTableHeadings: string[];
  backgroundLocLoad = false;
  backGroundStatusLoad = false;
  statusEnd: number;
  totalAssets = 0;
  totalLAssets: number;
  assetMapping: any = [];
  MetaDataMapping: any;
  AssetId = "";
  AssetType = "";
  AssetTypes = ["E", "P", "C"];
  typedAssetIds: [];
  asset = new FormControl();
  form: FormGroup;
  form2: FormGroup;
  newTab: boolean;
  dataLoadCycles;
  cyclesDone;
  isChecked = true;
  formGroup: FormGroup;
  mappingSelection;
  customTags: string[];
  newCustomTag: string = "";
  CustomTagsList: any = [];
  groupedAssetIds;
  color: ThemePalette = "primary";
  public assetFilter: FormControl = new FormControl();
  btnTxt: string = "Back";
  // tslint:disable-next-line: max-line-length
  locationData: {
    reportData: string;
    showPagination: number;
    fields: string[];
    tableName: string;
    currentPage: number;
    items: number;
    total: any;
    assetId: any;
    assetType: string;
  };
  // tslint:disable-next-line: max-line-length
  statusData: {
    reportData: string;
    showPagination: number;
    fields: string[];
    tableName: string;
    currentPage: number;
    items: number;
    total: any;
    assetId: any;
    assetType: string;
  };
  locationTitle: any;
  patientDataAccess: boolean = false;
  storageProvider: StorageProvider;
  currentTableView: string = undefined; // STATUS | LOCATION | undefined

  // tslint:disable-next-line: max-line-length
  constructor(
    private deviceService: DeviceService,
    public route: ActivatedRoute,
    public utilityService: UtilityService,
    public reportService: ReportService,
    private location: Location,
    public router: Router,
    public bypassSecurityTrustUrl: DomSanitizer
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.id === 1) {
          this.btnTxt = "Close";
          console.log(0);
        } else {
          console.log(1);
        }
      }
    });
    this.patientDataAccess = this.utilityService.isPatientDataAccessAllowed();
    this.storageProvider = StorageConfig.getInstance().getStorageProvider();
  }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes this component
   */
  ngOnInit() {
    this.utilityService.setLoaderflagStatus(true); // start loader
    this.getLocationTitle(); //load sequence 1
    this.getGroupedAssetIds();
    this.fetchCustomTags();
    this.form = new FormGroup({
      fields: new FormControl(null, {
        validators: [Validators.required],
      }),
      type: new FormControl(null, {
        validators: [Validators.required],
      }),
    });

    this.form2 = new FormGroup({
      tag: new FormControl(null, {
        validators: [Validators.required],
      }),
    });

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has("deviceId")) {
        this.deviceId = paramMap.get("deviceId");
        this.getAssetMap();
        this.AssetType = "";
        this.AssetId = "";
        this.getDevice("", this.deviceId);
      } else {
        this.AssetId = paramMap.get("id");
        this.deviceId = "";
        this.AssetType = paramMap.get("type");
        this.getDevice(this.AssetType, this.AssetId);
        this.getAssetMap();
        if (this.AssetType === "Equipment") {
          if (this.patientDataAccess)
            this.AssetTypes = ["Patient", "Clinician"];
          else this.AssetTypes = ["Clinician"];
        } else {
          if (this.AssetType === "Patient") {
            if (this.patientDataAccess) this.AssetTypes = ["Clinician"];
            else this.AssetTypes = [""];
          } else {
            if (this.AssetType === "Clinician") {
              if (this.patientDataAccess) this.AssetTypes = ["Patient"];
              else this.AssetTypes = [""];
            }
          }
        }
      }
    });
  }

  /**
   * (getLocationTitle)
   * Description:
   * gets location title/alias from back-end
   */
  getLocationTitle() {
    let url = BACKEND_URL + "location.getLocationTitle";
    this.utilityService.get(
      url,
      async (res) => {
        console.log(res);
        this.locationTitle = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * (Fetch CustomTags)
   * Description:
   * this function fetches all the Custom Tags of RTLS
   */
  fetchCustomTags() {
    this.utilityService.get(
      "/api/customTag/list",
      (res) => {
        this.CustomTagsList = res.data;
        console.log(this.CustomTagsList);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * (Remove Custom Tag)
   * Description:
   * this function sends request to remove a given custom tag
   */
  removeCustomTag(customTagName) {
    let data: any = {
      customTag: customTagName,
      AssetId: this.device.AssetId,
    };

    this.utilityService.post(data, "assets.removeCustomTag", (res) => {
      let index = -1;
      this.customTags.forEach((val, i) => {
        if (val === customTagName) {
          index = i;
        }
      });
      this.customTags.splice(index, 1);
    });
  }

  /**
   * (saveMapping)
   * Description:
   * this function saves relation between two assets.
   */
  saveMapping() {
    if (this.form.valid) {
      this.utilityService.setLoaderflagStatus(true);
      const Mapping = [];
      this.form.value.fields.forEach((element) => {
        Mapping.push({
          [this.device.AssetType]: this.device.AssetId,
          [this.mappingSelection]: element,
        });
      });
      let data = {};
      data["Mapping"] = Mapping;
      this.deviceService.post(
        data,
        "assets.addAssetMapping",
        (res) => {
          this.getAssetMap();
          console.log(Mapping);
          this.utilityService.setLoaderflagStatus(false);
        },
        (err) => {
          console.log(err);
          this.utilityService.setLoaderflagStatus(false);
        }
      );
    }
    this.form.reset();
  }

  /**
   * (saveNewCustomTag)
   * Description:
   * this function add new custom tag when we click model `Add` button.
   */
  saveNewCustomTag() {
    let data = {};

    data["newCustomTag"] = this.newCustomTag;
    data["AssetId"] = this.device.AssetId;

    this.deviceService.post(
      data,
      "assets.addCustomTag",
      (res) => {
        if (res.status == 1) {
          this.customTags.push(this.newCustomTag);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * (onChangedPage)
   * Description:
   * gets status history by pagination
   */
  onChangedPage(pageData: PageEvent) {
    this.currentPage = pageData.pageIndex + 1;
    this.perPageItems = pageData.pageSize;
    // for showing loader
    this.dataLoadCycles = 1;
    this.cyclesDone = 0;
    this.getStatusHistory();
  }

  /**
   * (onChangedPageLocation)
   * Description:
   * gets asset location history by pagination
   */
  onChangedPageLocation(pageData: PageEvent) {
    this.currentLPage = pageData.pageIndex + 1;
    console.log(this.currentLPage);
    this.perPageLItems = pageData.pageSize;
    // for showing loader
    this.dataLoadCycles = 1;
    this.cyclesDone = 0;
    this.getLocationHistory();
  }

  /**
   * (getKeysOfLocation)
   * Description:
   * this function returns keys of location history object
   */
  getKeysOfLocation(loc) {
    if (typeof loc != "undefined" && loc) {
      const keys = Object.keys(loc);
      const index = keys.indexOf("fullPath");
      if (index !== -1) {
        keys.splice(index, 1);
      }
      return keys;
    }
  }

  getKeysOfFullPath(loc) {
    return Object.keys(loc);
  }

  /**
   * (getLocationHeaders)
   * Description:
   * this function returns keys of location or homelocation, whichever are more
   */
  getLocationHeaders() {
    if (this.device) {
      if (
        this.device.CurrentLocation &&
        this.device.CurrentLocation.fullPath &&
        !this.homeLocation
      ) {
        const keys = Object.keys(this.device.CurrentLocation.fullPath);
        const index = keys.indexOf("fullPath");
        if (index !== -1) {
          keys.splice(index, 1);
        }
        return keys;
      } else if (
        this.device.CurrentLocation &&
        !this.device.CurrentLocation.fullPath &&
        this.homeLocation
      ) {
        const keys = Object.keys(this.homeLocation);
        const index = keys.indexOf("fullPath");
        if (index !== -1) {
          keys.splice(index, 1);
        }
        return keys;
      } else if (
        this.device.CurrentLocation &&
        this.device.CurrentLocation.fullPath &&
        this.homeLocation
      ) {
        if (
          Object.keys(this.homeLocation).length >
          Object.keys(this.device.CurrentLocation.fullPath).length
        ) {
          const keys = Object.keys(this.homeLocation);
          const index = keys.indexOf("fullPath");
          if (index !== -1) {
            keys.splice(index, 1);
          }
          return keys;
        } else {
          const keys = Object.keys(this.device.CurrentLocation.fullPath);
          const index = keys.indexOf("fullPath");
          if (index !== -1) {
            keys.splice(index, 1);
          }
          return keys;
        }
      }
    } else return [];
  }

  /**
   * (getResponse)
   * Description:
   * this function re-initializes ngOnInit when home location is added.
   */
  getResponse(event) {
    if (event) {
      this.ngOnInit();
    }
  }

  /**
   * (getStatusHistory)
   * Description:
   * this function returns asset status history data.
   */
  getStatusHistory() {
    // tslint:disable-next-line: max-line-length
    this.deviceService.getHistoryFromDb(
      {
        currentPage: this.currentPage,
        items: this.perPageItems,
        deviceId: this.device.AssetId,
        count: this.totalAssets,
      },
      "deviceStatus.history",
      (res) => {
        if (res.assets && res.assets.length > 0) {
          // tslint:disable-next-line: forin
          for (const a in res.assets) {
            const status = res.assets[a];
            status.Time = moment(new Date(status.Time)).format(
              "MM/DD/YYYY, HH:mm:ss"
            );
            if (status["Wifi Strength"]) {
              status["Wifi Strength"] =
                this.MetaDataMapping["Equipment"]["WifiStrength"]["Options"][
                  status["Wifi Strength"]
                ].alias;
            }
            if (status["Connection Status"]) {
              status["Connection Status"] =
                this.MetaDataMapping["Equipment"]["DeviceStatus"]["Options"][
                  status["Connection Status"]
                ].alias;
            }
            if (status["Battery Level"]) {
              status["Battery Level"] =
                this.MetaDataMapping["Equipment"]["BatteryLevel"]["Options"][
                  status["Battery Level"]
                ].alias;
            }
            if (status["Infusion Status"]) {
              status["Infusion Status"] =
                this.MetaDataMapping["Equipment"]["InfusionStatus"]["Options"][
                  status["Infusion Status"]
                ].alias;
            }
          }
          console.log(res.assets);
          this.statusDataArray = res.assets;
          this.backGroundStatusLoad = true;
          if (typeof res.assets[0] != "undefined") {
            this.statusTableHeadings = Object.keys(res.assets[0]);
          }
          this.statusEnd = this.heading.length;
        }
        console.log(this.statusTableHeadings);
        this.totalAssets = res.maxAssets;

        this.statusData = {
          reportData: this.statusDataArray,
          showPagination: 1,
          fields: this.statusTableHeadings,
          tableName: "",
          currentPage: this.currentPage,
          items: this.perPageItems,
          total: res.maxAssets,
          assetId: this.device.AssetId,
          assetType: this.device.AssetType,
        };
        this.getStatusOfBackgroundApis("Status", false);
        this.utilityService.setLoaderflagStatus(false);
        // this.onDataLoadFinish();
      },
      (err) => {
        console.log(err);
        this.backGroundStatusLoad = true;
        this.utilityService.setLoaderflagStatus(false);
        // this.onDataLoadFinish();
      }
    );
  }

  /**
   * (getLocationHistory)
   * Description:
   * gets the loacation history for selected asset
   */
  getLocationHistory() {
    let id = "";
    id = this.device.AssetId;
    let url = `location.getLocationHistory`;
    this.deviceService.getLocationHistoryFromDb(
      {
        currentPage: this.currentLPage,
        items: this.perPageLItems,
        assetId: id,
      },
      url,
      (res) => {
        this.locationDataArray = res.assets;
        this.backgroundLocLoad = true;
        if (res.assets && res.assets.length > 0) {
          this.locationTableHeadings = Object.keys(res.assets[0]);
          this.end = this.heading.length;
          this.totalLAssets = res.maxAssets;
          this.dialogHeading = [...this.heading];
          // tslint:disable-next-line: forin
          for (const a in res.assets) {
            var status = res.assets[a];
            status.Time = moment(new Date(status.Time)).format(
              "MM/DD/YYYY, HH:mm:ss"
            );
            if (status["Wifi Strength"]) {
              status["Wifi Strength"] =
                this.MetaDataMapping["Equipment"]["WifiStrength"]["Options"][
                  status["Wifi Strength"]
                ].alias;
            }
            if (status["Connection Status"]) {
              status["Connection Status"] =
                this.MetaDataMapping["Equipment"]["DeviceStatus"]["Options"][
                  status["Connection Status"]
                ].alias;
            }
            if (status["Battery Level"]) {
              status["Battery Level"] =
                this.MetaDataMapping["Equipment"]["BatteryLevel"]["Options"][
                  status["Battery Level"]
                ].alias;
            }
            if (status["Infusion Status"]) {
              status["Infusion Status"] =
                this.MetaDataMapping["Equipment"]["InfusionStatus"]["Options"][
                  status["Infusion Status"]
                ].alias;
            }
          }
        }
        console.log("Location History", this.locationDataArray);
        console.log("Location Heading", this.locationTableHeadings);
        // send location data to table component
        this.locationData = {
          reportData: this.locationDataArray,
          showPagination: 1,
          fields: this.locationTableHeadings,
          tableName: "",
          currentPage: this.currentLPage,
          items: this.perPageLItems,
          total: res.maxAssets,
          assetId: this.device.AssetId,
          assetType: this.device.AssetType,
        };
        this.getStatusOfBackgroundApis("Location", false);
        this.utilityService.setLoaderflagStatus(false);
      },
      (err) => {
        this.backgroundLocLoad = true;
        this.utilityService.setLoaderflagStatus(false);
      }
    );
  }

  /**
   * (getKeys)
   * Description:
   * returns keys of a javascript object
   */
  getKeys(Obj) {
    return Object.keys(Obj);
  }

  /**
   * (GetSrcImage)
   * Description:
   * gets the category image of the device
   */
  getSrcImage(propertyName: string, propertyValue: string) {
    if (this.MetaDataMapping) {
      let imagePath =
        this.MetaDataMapping["Equipment"][propertyName]["Options"][
          propertyValue
        ]["image"];
      let image = this.getMetadataFile(imagePath);
      if (image) return image;
      else return "assets/images/no_img_details.svg";
    } else return "assets/images/no_img_details.svg";
  }

  getMetadataFile(filePath: string): any {
    let image = this.storageProvider.getImageFile(filePath);
    if (image)
      return image.startsWith("data:image/")
        ? this.bypassSecurityTrustUrl.bypassSecurityTrustUrl(image)
        : image;
    else return null;
  }

  /**
   * (getDevice)
   * Description:
   * gets device details according to device id.
   */
  getDevice(assetType, assetId) {
    this.mapQuery = [];
    let query: any = {};
    this.dataLoadCycles = 3; // 1st get device, 2nd get location history, 3rd get status hostory
    this.cyclesDone = 0;
    this.deviceService
      .getDeviceFromDb(assetType, assetId)
      .subscribe((fetchedDevice) => {
        this.device = fetchedDevice;
        this.customTags = this.device.CustomTags;
        console.log("Device Data", this.device);
        let Obj: any = Object.keys(this.device.Fields);
        this.dynamicDetailsArray = Obj.filter(
          (value) => this.staticDetailsArray.indexOf(value) === -1
        );
        let temp = document.URL.includes("type");
        if (temp) {
          // tslint:disable-next-line: no-string-literal
          // tslint:disable-next-line: max-line-length
          this.utilityService.setHeaderHelpData({
            helpdata: this.device["AssetType"] + " Detail Page",
            pageName: this.device["AssetType"] + " Detail Page",
          });
        }
        query["_id"] = this.device._id;
        this.mapQuery.push(query);
        console.log("query", this.mapQuery);
        this.utilityService.sendFilterMessage(this.mapQuery);
        this.deviceService.getData(
          "asset.getMetaDataMapping",
          (res) => {
            this.MetaDataMapping = res;
            // tslint:disable-next-line: forin
            for (const a in this.dynamicDetailsArray) {
              const key = this.dynamicDetailsArray[a] + "";
              if (
                key in this.device.Fields &&
                this.MetaDataMapping["Equipment"][key]
              ) {
                this.device.Fields[key] = this.MetaDataMapping["Equipment"][
                  key
                ]["Options"]
                  ? this.MetaDataMapping["Equipment"][key]["Options"][
                      this.device.Fields[key]
                    ]["alias"]
                  : this.MetaDataMapping["Equipment"][key]["Alias"];
              }
            }
            // Now that device and aliases are loaded, get history data
            this.getLocationHistory();
            this.getStatusHistory();
          },
          (err) => {
            console.log(err);
          }
        );
        this.homeLocation = this.device.HomeLocation;
      });
  }

  /**
   * (getLevels)
   * Description:
   * shows battery icon acccording to battery level
   */
  getLevels(bars) {
    if (bars == "LEVEL_4") {
      return `./assets/images/Battery4.svg`;
    } else if (bars == "LEVEL_3") {
      return `./assets/images/Battery3.svg`;
    } else if (bars == "LEVEL_2") {
      return `./assets/images/Battery2.svg`;
    } else if (bars == "LEVEL_1") {
      return `./assets/images/Battery1.svg`;
    } else {
      return `./assets/images/b.svg`;
    }
  }

  /**
   * (getStatusIcons)
   * Description:
   * shows device status icon acccording to device status(online,offline)
   */
  getStatusIcons(status) {
    if (status == "comm-status-online") {
      return `./assets/images/online.svg`;
    } else {
      return `./assets/images/offline.svg`;
    }
  }

  /**
   * (getWifiLevels)
   * Description:
   * shows device status icon acccording to wifi level.
   */
  getWifiLevels(bars) {
    if (bars == "BAR_ONE") {
      return `./assets/images/Wifi1.svg`;
    } else if (bars == "BAR_TWO") {
      return `./assets/images/Wifi2.svg`;
    } else if (bars == "BAR_THREE") {
      return `./assets/images/Wifi3.svg`;
    } else if (bars == "BAR_FOUR") {
      return `./assets/images/Wifi4.svg`;
    } else if (bars == "BAR_FIVE") {
      return `./assets/images/Wifi5.svg`;
    } else {
      return `./assets/images/Wifi.svg`;
    }
  }

  /**
   * (getMapParams)
   * Description:
   * gets the map height and width to be shown on this page
   */
  getMapParams() {
    return new Promise((resolve, reject) => {
      let url = `location.getMap/${this.device.CurrentLocation.mapId}`;
      this.deviceService.get(
        url,
        (res) => {
          resolve(res);
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  /**
   * (getAssetMap)
   * Description:
   * fetches details of asset relation to other assets
   */
  getAssetMap() {
    this.utilityService.setLoaderflagStatus(true);
    let temp: any = {};
    if (this.AssetType) {
      temp[this.AssetType] = this.AssetId;
    } else {
      temp["Equipment"] = this.deviceId;
    }
    this.deviceService.post(
      temp,
      "asset.getAssetMappingById",
      (res) => {
        this.assetMapping = res;
        this.utilityService.setLoaderflagStatus(false);
      },
      (err) => {
        console.log(err);
        this.utilityService.setLoaderflagStatus(false);
      }
    );
  }

  getGroupedAssetIds() {
    this.utilityService.setLoaderflagStatus(true);
    this.deviceService.getData(
      "asset.getGroupedAssetIds",
      (res) => {
        this.groupedAssetIds = res;
        this.utilityService.setLoaderflagStatus(false);
      },
      (err) => {
        this.utilityService.setLoaderflagStatus(false);
        console.log(err);
      }
    );
  }

  /**
   * (getStatusOfBackgroundApis)
   * Description:
   * this function checks whether apis running in background have completed or not.
   */
  getStatusOfBackgroundApis(apiName, isFromTemplate) {
    if (isFromTemplate) {
      this.currentTableView = apiName;
      const initData = {
        reportData: [],
        showPagination: 0,
        fields: [],
        tableName: "",
        currentPage: this.currentPage,
        items: this.perPageItems,
        total: 0,
        assetId: this.device.AssetId,
        assetType: this.device.AssetType,
      };

      //to clear data from table, need to do this because not using *ngIf hence table component will not destroy
      this.utilityService.setDataForTable(initData);
    }

    if (apiName == "Location") {
      if (this.locationDataArray.length === 0 && !this.backgroundLocLoad) {
        this.utilityService.setLoaderflagStatus(true);
      } else if (isFromTemplate || this.currentTableView === "Location") {
        this.utilityService.setDataForTable(this.locationData);
        this.utilityService.setLoaderflagStatus(false);
      } else {
        this.utilityService.setLoaderflagStatus(false);
      }
    } else if (apiName == "Status") {
      if (this.statusDataArray.length === 0 && !this.backGroundStatusLoad) {
        this.utilityService.setLoaderflagStatus(true);
      } else if (isFromTemplate || this.currentTableView === "Status") {
        this.utilityService.setDataForTable(this.statusData);
        this.utilityService.setLoaderflagStatus(false);
      } else {
        this.utilityService.setLoaderflagStatus(false);
      }
    }
  }

  /**
   * (goBack)
   * Description:
   * this function redirects to dashboard page.
   */
  goBack() {
    if (typeof this.location.back() == "undefined") {
      window.close();
    } else {
      this.location.back();
    }
  }

  /**
   * (closeTab)
   * Description:
   * this function is used to close the current tab.
   */
  closeTab() {
    window.close();
  }

  /**
   * (sendPaginationDetails)
   * Description:
   * this function sends pagination detail to map-view component.
   */
  sendPaginationDetails(event) {
    this.utilityService.setLoaderflagStatus(true);
    this.currentLPage = event.currentPage;
    this.perPageLItems = event.items;
    this.getLocationHistory();
  }

  /**
   * (sendPaginationDetailsForMednet)
   * Description:
   * this function sends pagination detail to mednet table component.
   */
  sendPaginationDetailsForMednet(event) {
    this.utilityService.setLoaderflagStatus(true);
    this.currentPage = event.currentPage;
    this.perPageItems = event.items;
    this.getStatusHistory();
  }

  getInputForHomeLocation() {
    return { AssetType: this.AssetType, AssetId: this.AssetId };
  }

  onMappingTypeChanged() {
    if (this.mappingSelection === "Equipment") {
      this.typedAssetIds = this.groupedAssetIds.Equipment;
    }
    if (this.mappingSelection === "Clinician") {
      this.typedAssetIds = this.groupedAssetIds.Clinician;
    }
    if (this.mappingSelection === "Patient") {
      this.typedAssetIds = this.groupedAssetIds.Patient;
    }
  }
}
