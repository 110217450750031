import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,

} from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { UtilityService } from "src/app/utility.service";
import {
  FormControl,
  Validators,
  FormGroup,

} from "@angular/forms";
import { ReportService } from "../report-service.service";
import { MatInput } from "@angular/material/input";
import { MatSelect } from "@angular/material/select";
import { DeviceService } from "src/app/device.service";
import { Subscription } from 'rxjs';

/**
 * Custom Report Generation Page
 */
@Component({
  selector: "app-generate-report-form",
  templateUrl: "./generate-report-form.component.html",
  styleUrls: ["./generate-report-form.component.css"]
})
export class GenerateReportFormComponent implements OnInit, OnDestroy {
  public url;
  private formSubscription: Subscription;
  rawUrl: any;
  selectedSubject: string;

  selectedType: string;
  isedit: boolean = false;
  reportId: any;
  selectedTag: string;
  selectedShare: string;
  tags:any=["Heat Map","Battery","Clinician","Patient"];
  shareGroup:any=["Everyone","None"]
  customReport: any;
  MetadataMapId: any;
  targetField: any;
  public typeOfReport;
  @ViewChildren("custom") custom: QueryList<MatSelect>;
  @ViewChildren("input") input: QueryList<MatInput>;
  headerdata: { helpdata: any; pageName: any };
  form: FormGroup;
  heatForm: FormGroup;
  public ReportSubjects = {};
  public heatData = {
    targetField: [],

  };

  ReportColumns: any = {};
  AliasToFieldNameMapping: any = {};
  reportFields;
  heatMapReportTarget;
  reportIcons: string[] = [
    'icon-alert-report',
    'icon-status-time-report',
    'icon-nearby-pump-report',
    'icon-alarm-report',
    'icon-custom-report',
  ];
  selectedIcon: string;

  constructor(
    public router: Router,
    public utilityService: UtilityService,
    public reportService: ReportService,
    public route: ActivatedRoute,
    public deviceService: DeviceService
  ) {}

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {

    this.formSubscription =  this.reportService.getReportTypeToReportForm().subscribe(data => {
      this.typeOfReport = data;
    });
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has("reportId")) {
        this.isedit = true;
        this.reportId = paramMap.get("reportId");
        this.reportService.get(
          "custom.getCustomReportById/" + this.reportId,
          report => {
            console.log(report);
            this.customReport = report;
            this.typeOfReport = this.customReport.Type;
            this.selectedSubject = this.customReport.Subject;
            this.selectedShare=this.customReport.share;
            this.selectedIcon = this.customReport.Icons;


            this.customReport.TargetField
              ? (this.targetField = this.customReport.TargetField)
              : "";

            // this.buildReport();
          },
          error => {
            console.log(error);
          }
        );
      }
    });

    this.url = document.URL.split("/");
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue("help").then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
    this.form = new FormGroup({
      // here we configure our form
      // angular automatically runs all validators, and uses it in form.valid
      name: new FormControl(null, {
        validators: [Validators.required]
      }),
      description: new FormControl(null, {
        validators: [Validators.required, Validators.minLength(3)]
      }),
      subject: new FormControl(null, {
        validators: [Validators.required]
      }),
      tag: new FormControl(null, {
        validators: [Validators.minLength(3)]
      }),
      share: new FormControl(null, {
        validators: [Validators.required]
      }),

      fields: new FormControl(null, {
        validators: [Validators.required]
      })
    });

    this.heatForm = new FormGroup({
      // here we configure our form
      // angular automatically runs all validators, and uses it in form.valid
      name: new FormControl(null, {
        validators: [Validators.required]
      }),
      description: new FormControl(null, {
        validators: [Validators.required, Validators.minLength(3)]
      }),
    fields: new FormControl(null, {
        validators: [Validators.required]
      }),
      subject: new FormControl(null, {
        validators: [Validators.required]
      }),
      heat: new FormControl(null, {
        validators: [Validators.required]
      }),
      tag: new FormControl(null, {
        validators: [Validators.minLength(3)]
      }),
      share: new FormControl(null, {

      }),

    });
    this.setMatOption();
    this.getFormField();
  }

  /**
   * (Set Mat Option)
   *  Description:
   *  this function returns dropdown according to report type i.e; list or heat.
   */
  setMatOption() {
    setTimeout(() => {
      if (this.isedit) {
        if (typeof this.custom != "undefined") {
          // if undefined nothing happen
          this.custom.forEach(innelement => {
            // loop for each matselect
            innelement.options.forEach(option => {
              // loop for each options in matselect
              if (this.typeOfReport == "List") {
                if (this.selectedSubject.includes(option.value)) {
                  option.select();
                }

                if (this.customReport.Fields.includes(option.value)) {
                  option.select();
                }
                 if (this.selectedShare == option.value) {
                  option.select();
                }



              } else {
                if (this.typeOfReport == "Heat Map") {
                  if (this.targetField.includes(option.value)) {
                    option.select();
                  }
                  if (this.customReport.Fields.includes(option.value)) {
                    option.select();
                  }
                  if (this.selectedSubject.includes(option.value)) {
                    option.select();
                  }
                  if (this.selectedShare == option.value) {
                    option.select();
                  }
                }
              }
            });
          });
        }
      }
      if (this.typeOfReport == "List") {
        this.form.get("name").setValue(this.customReport.Name);
        this.form.get("description").setValue(this.customReport.Description);
      } else {
        if (this.typeOfReport == "Heat Map") {
          this.heatForm.get("name").setValue(this.customReport.Name);
          this.heatForm
            .get("description")
            .setValue(this.customReport.Description);
        }
      }
    }, 1000);
  }

  /**
   * (Get Form Field)
   * Description:
   * this function returns form fields for heat and list form.
   */
  getFormField() {
    this.deviceService.getFilterOptionFromDb(
      "asset.getMetaDataMapping",
      res => {
        delete res._id;
        for(let a in res) {
          let assetType = a;
          this.ReportColumns[assetType] = [];
          this.AliasToFieldNameMapping[assetType] = {};
          let assetTypeFields = res[a];
          for(let b in assetTypeFields) {
            let alias = assetTypeFields[b]['Alias'];
            this.ReportColumns[assetType].push(alias);
            this.AliasToFieldNameMapping[assetType][alias] = b;
          }
        }
        console.log(this.ReportColumns);
        console.log(this.AliasToFieldNameMapping);
        this.heatData.targetField.push(res.Equipment);
        let subject = Object.keys(res);
        this.MetadataMapId = res["_id"];
        const index = subject.indexOf("_id");
        if (index > -1) {
          subject.splice(index, 1);
        }
        this.ReportSubjects = subject;
      },
      err => {
        console.log(err);
      }
    );
  }

  /**
   * (On Edit Report)
   * Description:
   * this function edits the custom report details
   */
  onEditReport() {

    this.utilityService.setLoaderflagStatus(true);
    const postData = {
      Name:
        this.form.value.name ||
        this.heatForm.value.name ||
        this.customReport.Name,
      Subject:
        this.form.value.subject ||
        this.heatForm.value.subject ||
        this.customReport.Subject,
      Type: this.typeOfReport,
      Description:
        this.form.value.description ||
        this.heatForm.value.description ||
        this.customReport.Description,
      Fields:
        this.form.value.fields ||
        this.heatForm.value.fields ||
        this.customReport.Fields,
      TargetField: this.heatForm
        ? this.heatForm.value.heat
        : "" || this.customReport.TargetField,

      share: this.heatForm.value.share || this.form.value.fields || this.customReport.share,
      Icons: this.selectedIcon
    };
    console.log(postData.Fields);
    console.log(this.form.value.fields);
    this.reportService.editCustomReport(
      "custom.editCustomReportById/" + this.reportId + "",
      postData,
      res => {
        this.utilityService.setLoaderflagStatus(false);
        this.reportService.sendCustomReportChangeMessage();
        console.log(res);
      },
      err => {
        this.utilityService.setLoaderflagStatus(false);
        console.log(err);
      }
    );

    console.log("data", postData);
  }

  /**
   * (On Generate Report)
   * Description:
   * this function generates custom list report.
   */
  onGenerateReport() {
    this.utilityService.setLoaderflagStatus(true);
    if (this.form.invalid) {
      return;
    }
    const postData = {
      Name: this.form.value.name,
      Subject: this.form.value.subject,
      Type: this.typeOfReport,
      Description: this.form.value.description,
      share:this.form.value.share=="Everyone"?"Everyone":"None",
      Fields: this.form.value.fields,
      Icons: this.selectedIcon,
    };
    console.log(postData);
    this.reportService.post(
      postData,
      "custom.createCustomReport",
      res => {
        console.log(res);
        this.form.reset();
        this.reportService.sendCustomReportChangeMessage();
        this.utilityService.setLoaderflagStatus(false);
        this.router.navigate(["/devices"]);
      },
      err => {
        this.utilityService.setLoaderflagStatus(false);
        console.log(err);
      }
    );
  }

  /**
   * (Get Object Keys)
   * Description:
   * this function return keys of an object in a array.
   */
  getObjectKeys(Obj) {
    return Object.keys(Obj);
  }

  /**
   * (On Generate Heat Report)
   * Description:
   * this function generates custom heat map report.
   */
  onGenerateHeatReport() {
    if (this.heatForm.invalid) {
      console.log(this.heatForm.invalid);
      return;
    }
    const postData = {
      Name: this.heatForm.value.name,
      Subject: this.heatForm.value.subject,
      Type: this.typeOfReport,
      Description: this.heatForm.value.description,
      Fields: this.heatForm.value.fields,
      share: this.heatForm.value.share == "Everyone" ? "Everyone" : "None",
      TargetField: this.heatForm.value.heat,
      Icons: this.selectedIcon
    };
    this.reportService.post(
      postData,
      "custom.createCustomReport",
      res => {
        console.log(res);
        this.heatForm.reset();
        this.reportService.sendCustomReportChangeMessage();
        this.router.navigate(["/devices"]);
      },
      err => {
        console.log(err);
        this.utilityService.setLoaderflagStatus(false);
      }
    );
  }

  /**
   * (ngOnDestroy)
   * this function is called when angular destroys this component
   */
  ngOnDestroy() {
    if (this.formSubscription) { this.formSubscription.unsubscribe(); }
  }

  onIconSelect(icon: string) {
    this.selectedIcon = icon;
  }
}
