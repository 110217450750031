import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DeviceService } from 'src/app/device.service';
import { UtilityService } from 'src/app/utility.service';
import { ReportService } from 'src/app/reports/report-service.service';
import { Router } from '@angular/router';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { StorageConfig } from 'src/app/storage/storage-config';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { StorageProvider } from 'src/app/storage/storage-provider-interface';
const BACKEND_URL = environment.locationUrl;

/**
 * DID-NEXUS-002, DID-NEXUS-003, DID-NEXUS-010,
 * DID-NEXUS-029, DID-NEXUS-068, DID-NEXUS-069
 * Map View component
 */
@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.css']
})
export class MapViewComponent implements OnInit, OnDestroy {

  @Input() subject;
  @Output() shareDataEvent = new EventEmitter();
  private mapFlagSubscription: Subscription;
  private dashboardSubscription: Subscription;
  private filterSubscription: Subscription;
  mapList: any = [];
  changeText = {};
  assetType: string;
  showPointerFlag: boolean = false;
  href: SafeUrl;
  mapTitle: string;
  mapParameters: any = {};
  idMap: string;
  Pointer = [];
  mapType: string = '';
  query = [];
  heatMapTarget = '';
  page: string;
  start: number = 0;
  AssetIds: any;
  Fields: any;
  tName: any;
  reportData : any;
  MetaDataMapping: any = {};
  subjects: any;
  tagetfeild : any;
  public min_zoom: number = 1 / 2;
  public max_zoom: number = 3 / 2;
  public current_val: number = 1;
  storageProvider: StorageProvider;

  // MetaDataMapping: any;
  constructor(
    private http: HttpClient,
    public deviceService: DeviceService,
    public utilityService: UtilityService,
    public reportService: ReportService,
    public router: Router,
    public route: ActivatedRoute,
    public bypassSecurityTrustUrl: DomSanitizer
  ) {
    this.storageProvider = StorageConfig.getInstance().getStorageProvider();
    // For dashboard map view
    if (router.url == '/devices') {
      this.query = this.utilityService.getCurrentFilterCriteria();
      this.dashboardSubscription = this.utilityService.getMapData().subscribe((data: any) => {
        this.query = data;
        this.getMapList();
      });
    }
    this.getMapList();
  }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    // If map view used for heat report
    if (this.router.url.includes('heat-map-report')) {
      this.page = 'Heat Map';
    }

    // get map list when filter applied
    this.filterSubscription =  this.utilityService.getFilterClickListener().subscribe((data: any) => {
      this.Pointer = [];
      if (Object.keys(data).length > 0) {
        this.query = data;
        console.log('MAP Filter Subscription', this.query);
        this.getMapList();
      }
    });

    // get map info for heat report
    this.mapFlagSubscription = this.reportService.getReportData().subscribe((data: any) => {
      this.mapType = data.type || '';
      this.Fields = data.columns;
      this.tName = data.tableName;
      this.heatMapTarget = data.target;
      this.assetType = data.Subject;
      this.getFilterOption();
      this.getMapList();
    });

    // get map info for custom reports
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
    if (paramMap.has('reportId')) {
      const reportId = paramMap.get('reportId');
      this.reportService.get('/custom.getCustomReportById/' + reportId, report => {
        this.reportData = report;
        this.tagetfeild = report.TargetField;
        console.log(this.reportData);
      }, error => {
        console.log(error);
      });
    }
  });
  }

  /**
   * (returnKey)
   * Description:
   * this function return keys array of an Object
   */
  returnKey(Obj){
    return Object.keys(Obj);
  }

  /**
   * (dosomething)
   * Description:
   * this function get loction of asstes after map loads.
   */
  dosomething(mapId) {
    this.utilityService.setLoaderflagStatus(true);
    this.getImageDemension(mapId, data => {
      if (data) {
        this.getMapDataList(mapId, (data) => {
          //console.log(this.Pointer);
        });
      }
    });
    this.utilityService.setLoaderflagStatus(false);
  }

  /**
   * (getMapList)
   * Description:
   * this function return list of maps.
   */
  getMapList() {
    this.mapTitle = '';
    this.idMap = '';
    this.href = '';
    console.log(this.query);
    if (this.router.url.includes('custom-report')) {
      // tslint:disable-next-line: forin
      this.removeExtraObjects();
      this.query.push({AssetType: this.assetType});
    }
    this.utilityService.setLoaderflagStatus(true);
    this.deviceService.post(
      this.query,
      'assets.getfilterMap',
      res => {
        this.mapList = res;
        if (this.mapList.length > 0) {
          this.getPointer(0);
        } else {
          this.href = null;
          this.mapTitle = null;
          this.utilityService.setLoaderflagStatus(false);
        }
      },
      err => {
        this.utilityService.setLoaderflagStatus(false);
      }
    );
  }


  /**
   * (loadMap)
   * Description:
   * this function loads the map.
   */
  loadMap(mapId, cb) {
    this.href = this.getImage(`var/uploads/images/Maps/${mapId}.jpg`);
    if (this.href) {
      // console.log();
      this.utilityService.setLoaderflagStatus(false);
      cb(true);
    } else {
      this.utilityService.setLoaderflagStatus(false);
      cb(false);
    }
  }


  /**
   * (getMapDataList)
   * Description:
   * this function returns location by paticular mapId.
   */
  getMapDataList(mapId, cb) {
    this.Pointer = [];
    //console.log('IN MAP DATA LIST BEFORE', this.query);
    this.query[this.query.length] = { 'CurrentLocation.mapId': mapId };
    if (this.subject) {
      this.removeExtraObjects();
      this.query.push({ AssetType: this.subject });
    }
    //console.log('IN MAP DATA LIST AFTER', this.query);
    this.deviceService.post(
      this.query,
      'assets.MapsPointer',
      res => {
        if (res.data.length > 0) {
          // console.log("object",res);
          this.Pointer = res.data;
          this.AssetIds = res.AssetIds;
          for(let location of this.Pointer) {
            let fullName = '';
            let fullLocation = location.CurrentLocation.fullPath;
            for(let level in fullLocation) {
              fullName = fullName + fullLocation[level] + '>';
            }
            fullName = fullName.substring(0, fullName.length - 1);
            location.CurrentLocation.fullPath = fullName;
          }
          //console.log(this.Pointer);
          this.sendDataToReportTable();
          cb(res.data);
        } else {
          console.log("debug", "not data found");
          this.utilityService.setDataForTable({
            tableName: this.tName
          });
          this.AssetIds = [];
          this.sendDataToReportTable();
        }
      },
      err => {

        console.log(err);
        this.utilityService.setLoaderflagStatus(false);
      }
    );
  }

  /**
   * (sendDataToReportTable)
   * Description:
   * this function passes data(pointer on each map) to table component
   */
  sendDataToReportTable() {
    if(!this.router.url.includes('devices') && !this.router.url.includes('asset-detail')) {
      let temp: any = {};
      temp['Ids'] = this.AssetIds;
      temp['type'] = this.assetType;
      this.reportService.post(temp, 'report.reportTableData', res => {
        this.utilityService.setDataForTable({
          reportData: res.assets,
          showPagination: 0,
          fields: this.Fields,
          tableName: this.tName
        });
      }, err => {
        console.log(err);
        this.utilityService.setLoaderflagStatus(false);
      });
    }
  }

  /**
   * (showTooltip)
   * Description:
   * this function shows detail on hover
   */
  showTooltip(i) {
    this.changeText[i] = true;
  }

  /**
   * (hideTooltip)
   * Description:
   * this function hides the detail on hover
   */
  hideTooltip(i) {
    this.changeText[i] = false;
  }

  /**
   * (getImageDemension)
   * Description:
   * this function calculates map demision (front end)
   */
  getImageDemension(mapId, cb) {
    this.mapParameters = {};
    let serverUrl = BACKEND_URL + 'location.getMap/' + mapId;
    this.http.get(serverUrl).subscribe(
      res => {
        if (
          typeof res['xDimension'] != 'undefined' &&
          typeof res['yDimension'] != 'undefined'
        ) {
          if (document.getElementById(mapId)) {
            this.mapParameters = {
              xView: document.getElementById(mapId).offsetWidth,
              yView: document.getElementById(mapId).offsetHeight,
              xDimension: res['xDimension'],
              yDimension: res['yDimension']
            };
          }
        }
        if (Object.keys(this.mapParameters).length > 0) {
          cb(true);
        }
      },
      err => {
        console.log(err);
        this.utilityService.setLoaderflagStatus(false);
      }
    );
  }

  /**
   * (getPointer)
   * Description:
   * this function gets Pointer for map
   */
  getPointer(index) {
    // this.href = null;
    this.showPointerFlag = true;


    this.loadMap(this.mapList[index].mapId, res => {
      // console.log(res);

      if (res) {
        this.utilityService.setLoaderflagStatus(false);
        this.mapTitle = this.mapList[index].name;
        this.idMap = this.mapList[index].mapId;
        // let even = _.find(res, (data)=>{ return data. });
      } else {
        this.utilityService.setLoaderflagStatus(false);
      }
    });
  }

  /**
   * (toolTipStyle)
   * Description:
   * this function calculate tool tip location(x,y) according to front-end map.
   */
  toolTipStyle(each) {
    const param = this.mapParameters;
    let styles: any;
    if (typeof param != 'undefined') {
      const x = (param.xView / param.xDimension) * each.xCenterPoint;
      const y = (param.yView / param.yDimension) * each.yCenterPoint;
      // console.log(param); //xView
      styles = {
        'position': 'absolute',
        'left': x - 96 + 'px',
        'top': y + 55 + 'px',
        'width': '230px',
        'z-index': '1',
      };
    }
    return styles;
  }

  /**
   * (getCurrentLocation)
   * Description:
   * this function calculates pointer location(x,y) according to front-end map.
   */
  getCurrentLocation(CurrentLocation) {
    let styles: any;
    const x =
      (this.mapParameters.xView / this.mapParameters.xDimension) *
      CurrentLocation.xCenterPoint;
    const y =
      (this.mapParameters.yView / this.mapParameters.yDimension) *
      CurrentLocation.yCenterPoint;
    if (this.page == 'Heat Map') {
      styles = {
        position: 'absolute',
        left: x + 'px',
        top: (y - 20) + 'px',
        width: '137px'
      };
    } else {
      styles = {
        position: 'absolute',
        left: x + 'px',
        top: y - 20 + 'px',
        width: '37px'
      };
    }

    return styles;
  }

  /**
   * (getFilterOption)
   * Description:
   * this function gets meta data information for custom map view.
   */
  getFilterOption() {
    this.deviceService.getFilterOptionFromDb(
      'asset.getMetaDataMapping',
      data => {
        this.MetaDataMapping = data;
      },
      err => {
      }
    );

  }

  /**
   * (returnSourcePath)
   * Description:
   * this function returns map icons for custom map view.
   */
  returnSourcePath(asset: any) {
    let value;
    if (asset['Fields'][this.heatMapTarget]) {
      let pathTofind = Object.keys(asset['Fields'][this.heatMapTarget]);
      if (typeof asset['Fields'][this.heatMapTarget] != 'object') {
        value = asset['Fields'][this.heatMapTarget];
      } else {
        value = asset['Fields'][this.heatMapTarget][pathTofind[0]];
      }
    }
    if (typeof value != 'undefined') {
      let imagePath = this.MetaDataMapping[this.assetType][this.heatMapTarget]['Options'][value].secondaryImage;
      return this.getImage(imagePath);
    } else { // info not present on asset
      return 'assets/images/no_image_icon.png';
    }
  }

  /**
   * (previousMap)
   * Description:
   * this function shows previous map on previous btn click.
   */
  previousMap() {
    if (this.start > 0) {
      this.start = this.start - 1;
    }
    if (this.start >= 0 && this.start <= this.mapList.length) {
      this.getPointer(this.start);
    }
  }

  /**
   * (nextMap)
   * Description:
   * this function shows next map on next btn click.
   */
  nextMap() {

    if (this.start != this.mapList.length) {
      this.start = this.start + 1;
    }
    if (this.start < this.mapList.length) {
      this.getPointer(this.start);
    }
  }

  getImage(filePath:string) : any {
    let image = this.storageProvider.getImageFile(filePath);
    if(image) return image.startsWith('data:image/') ? this.bypassSecurityTrustUrl.bypassSecurityTrustUrl(image) : image;
    else return null;
  }

  /**
   * (openNewTab)
   * Description:
   * this function open asset detail page in new tab
   */
  openNewTab(asset) {
    window.open(`/#/asset-detail;type=${asset.AssetType};id=${asset.AssetId}`);
  }

  /**
   * (zoom_in)
   * Description:
   * this function is used to zoom map.
   */
  zoom_in() {
    var zoom_slider = <HTMLInputElement> (document.getElementById('zoom_slider'));
    if (this.current_val < this.max_zoom) {
      this.current_val = (parseFloat(zoom_slider.value) * 10 + .1 * 10) / 10;
    }
    // console.log(this.min_zoom, (parseFloat(zoom_slider.value) * 10 + .1 * 10) / 10, zoom_slider.value)
    zoom_slider.value = (this.current_val).toString();
    this.zoom_func(this.current_val);
  }

  /**
   * (zoom_out)
   * Description:
   * this function is used to zoom out map.
   */
  zoom_out() {
    var zoom_slider = <HTMLInputElement> (document.getElementById('zoom_slider'));
    if (this.min_zoom < this.current_val) {
      this.current_val = (parseFloat(zoom_slider.value) * 10 - .1 * 10) / 10;
    }
    // console.log(this.current_val)
    zoom_slider.value = (this.current_val).toString();
    this.zoom_func(this.current_val);
  }

  /**
   * (zoom_slider)
   * Description:
   * this function is used for zoom slider.
   */
  zoom_slider(e) {
    var zoom_slider = e.target.value;
    // console.log(zoom_slider);
    this.zoom_func(zoom_slider);
  }

  /**
   * (zoom_func)
   * Description:
   * this function is used for zoom map.
   */
  zoom_func(n) {
    var zoom_id = document.getElementById('zoom_div');
    zoom_id.setAttribute('style', 'transform:scale(' + n + ')');
  }

  /**
   * (remove Extra Object)
   * Description
   * a helper function which removes extra objects from map query
   */
  removeExtraObjects() {
    var newQuery = [];
    for (const a in this.query) {
      if (!this.query[a].hasOwnProperty('AssetType')) {
        newQuery.push(this.query[a]);
      }
    }
    this.query = newQuery;
  }

  /**
   * (ngOnDestroy)
   * this function is called when angular destroys this component
   */
  ngOnDestroy() {
    if (this.mapFlagSubscription) { this.mapFlagSubscription.unsubscribe(); }
    if (this.dashboardSubscription) { this.dashboardSubscription.unsubscribe(); }
    if (this.filterSubscription) { this.filterSubscription.unsubscribe(); }
  }

}
