import { Component, OnInit } from '@angular/core';

/**
 * DID-NEXUS-016
 * This page is displayed when a user does not have permission
 * to view a page
 */
@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.css']
})
export class AccessDeniedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
