import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report-service.service';
import { UtilityService } from "../../utility.service";

/**
 * This page shows the received Mednet Alarm events
 * DID-NEXUS-079
 */
@Component({
  selector: 'app-mednet-alarm',
  templateUrl: './mednet-alarm.component.html',
  styleUrls: ['./mednet-alarm.component.css']
})
export class MednetAlarmComponent implements OnInit {
  url: any;
  rawUrl: any;
  headerdata = {};
  constructor(public reportService: ReportService, public utilityService: UtilityService) { }

/**
 * (ngOnInit)
 * Description:
 * this function is called when angular initializes the component
 */
  ngOnInit() {
    this.url = document.URL.split("/");
    this.rawUrl = this.url[this.url.length - 1];

    this.utilityService.getLangValue("help").then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
}

/**
 * (Get Page Name)
 * Description:
 * this function sends filter api url and report api url to filter and report.
 */
  getPageName() {
    return { filterUrl: 'report.getFilterFields/mednet-alarm', reportUrl: 'report.deviceProperties' };
  }


}
