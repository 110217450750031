import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ReportService } from '../report-service.service';
import { UtilityService } from "../../utility.service";

/**
 * DID-NEXUS-057
 * Device Movement Report page
 */
@Component({
  selector: 'app-pump-movement',
  templateUrl: './pump-movement.component.html',
  styleUrls: ['./pump-movement.component.css']
})
export class PumpMovementComponent implements OnInit {
  @Output() locationFilter: EventEmitter<any> = new EventEmitter<any>();
  dataSource: any;
  url: any;
  rawUrl: any;
  headerdata = {};

  constructor(public reportService: ReportService, public utilityService: UtilityService) {
  }

/**
 * (ngOnInit)
 * Description:
 * this function is called when angular initializes the component
 */
  ngOnInit() {
    this.url = document.URL.split("/");
    this.rawUrl = this.url[this.url.length - 1];

    this.utilityService.getLangValue("help").then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      }
      console.log(res.help[this.rawUrl]);
      this.utilityService.setHeaderHelpData(this.headerdata);

    });
    this.reportService.setReportData({columns: ['AssetId', 'LocationA', 'LocationB']});
  }

/**
 * (Get Filter Data)
 * Description:
 * this function receives data from filter component
 */
  getFilterData($event) {
    this.dataSource = $event;
  }

/**
 * (Get Page Name)
 * Description:
 * this function sends filter api url and report api url to filter and report.
 */
  getPageName() {
    return { filterUrl: 'report.getFilterFields/pump-movement', reportUrl: 'pumpMovement' };
  }
}
