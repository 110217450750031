import { Component, OnInit } from '@angular/core';
import { UtilityService } from "../../utility.service";
import { FormControl, FormGroup, Validators, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { AuthGuardService } from 'src/app/auth/auth-guard.service';

/**
 * DID-NEXUS-015, DID-NEXUS-050
 * This page is used to configure the iSAS privilege settings
 * and the respective allowed routes
 */
@Component({
  selector: 'app-privilege-settings',
  templateUrl: './privilege-settings.component.html',
  styleUrls: ['./privilege-settings.component.css']
})
export class PrivilegeSettingsComponent implements OnInit {

  options: string[] = [];
  url: any;
  rawUrl: any;
  headerdata = {};
  privilegeList = [];
  privilegeName: any;
  description: any;
  isAddPrivilegeOpen: boolean = false;
  form: FormGroup;


  constructor(public utilityService: UtilityService, private authGaurd: AuthGuardService) { }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.url = document.URL.split("/");
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue("help").then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };

      for (let route in res) {
        if (typeof res[route] === "string") {
          this.options.push(route);
        }
      }
      console.log(res.help[this.rawUrl]);
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
    this.form = new FormGroup({
      // here we configure our form
      // angular automatically runs all validators, and uses it in form.valid
      privilegeName: new FormControl(null, {
        validators: [Validators.required, Validators.minLength(3), Validators.pattern('^May(.*)')]
      }),
      description: new FormControl(null, {
        validators: [Validators.required, Validators.minLength(3)]
      })
    });
    this.fetchPrivilege();
  }

  /**
   * (Fetch Privilege)
   * Description:
   * this function fetches all the privileges of RTLS
   */
  fetchPrivilege() {
    this.utilityService.get('/api/privilege/getPrivileges', res => {
      this.privilegeList = res.privilegeList;
      this.privilegeList.map(val => {
        val.isFormOpen = false;
        val.addRouteform = new FormControl();
        val.filteredOptions = val.addRouteform.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filter(value, val.allowedPagesRoute)));
      });
    }, err => {
      console.log(err);
    });
  }

  /**
   *
   * (Add Privilege)
   * Description:
   * this function sends request to add a new privilege to RTLS system
   */
  addPrivilege() {
    if (this.form.invalid) {
      alert('Please fill all the details correctly');
    } else {
      let data = {
        privilegeName: this.privilegeName,
        routes: [],
        description: this.description,
        apis: []
      };
      this.utilityService.post(data, 'privilege/addPrivilege', res => {
        if (res.status === 500) {
          alert(res.error.msg);
          return;
        }
        alert('Privilege Added Successfully!');
        this.privilegeList.push(res);
        this.privilegeList.map(val => {
          val.addRouteform = new FormControl();
          val.filteredOptions = val.addRouteform.valueChanges.pipe(
            startWith(''),
            map((value: any) => this._filter(value, val.allowedPagesRoute))
          );
        });
        this.privilegeName = undefined;
        this.description = undefined;
        this.utilityService.isConfigured = true;
      });
    }
  }

  /**
   * (Add Route)
   * Description:
   * this function sends request to add a new route to a given privilege
   */
  addRoute(privilegeName) {
    let route = "";
    this.privilegeList.forEach((val) => {
      if (val.privilegeName === privilegeName && !route) {
        route = val.addRouteform.value;
      }
    });

    if (this.options.includes(route)) {
      let data = {
        privilegeName: privilegeName,
        routes: '/' + route
      };
      this.utilityService.post(data, 'privilege/addRoutes', res => {
        if (res.err === 0) {
          let index = -1;
          this.privilegeList.forEach((val, i) => {
            if (val.privilegeName === privilegeName) {
              index = i;
            }
          });
          this.privilegeList[index].allowedPagesRoute = res.allowedRoutes;
          this.privilegeList[index].addRouteform.setValue("");
          this.authGaurd.addAllowedRoute(route);
        }
      });
    } else {
      alert('route does not exixt!!');
    }
  }

  /**
   * (Remove Route)
   * Description:
   * this function sends request to remove a given route of a given privilege
   */
  removeRoute(privilegeName, route) {
    let rawRoute = route.split('/')[1];
    let data: any = {
      privilegeName: privilegeName,
      routes: route
    };
    this.utilityService.post(data, 'privilege/removeRoutes', res => {
      let index = -1;
      this.privilegeList.forEach((val, i) => {
        if (val.privilegeName === privilegeName) {
          index = i;
        }
      });
      if (res.err === 0) { this.privilegeList[index].allowedPagesRoute = res.allowedRoutes; }
      this.authGaurd.removeAllowedRoute(route);
    });
  }

  /**
   * (openAddPrivilege)
   * This fuction opens the form to add new privilege
   */
  openAddPrivilege() {
    this.isAddPrivilegeOpen = !this.isAddPrivilegeOpen;
  }

  /**
   * (openPrivilege)
   * This function shows the details of an already registered privilege
   */
  openPrivilege(privilegeName) {
    this.privilegeList.forEach((val, index) => {
      if (val.privilegeName === privilegeName) {
        this.privilegeList[index].isFormOpen = !this.privilegeList[index].isFormOpen;
      }
      else if (val.isFormOpen === true) {
        this.privilegeList[index].isFormOpen = !this.privilegeList[index].isFormOpen;
      }
    })
  }

  /**
   * (_filter)
   * Helper function used by various functions on this page
   */
  private _filter(value: string, allowedRoutes: any[]): string[] {
    const filterValue = value.toLowerCase();

    const currOpt = this.options.filter(val => !allowedRoutes.includes('/' + val));
    return currOpt.filter(option => option.toLowerCase().includes(filterValue));
  }
}
