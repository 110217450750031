import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/utility.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

/**
 * DID-NEXUS-053, DID-NEXUS-099
 * Mirth settings configuration page, used for declaring all the
 * settings related to Mirth Connect
 */
@Component({
  selector: 'app-mirth-settings',
  templateUrl: './mirth-settings.component.html',
  styleUrls: ['./mirth-settings.component.css']
})
export class MirthSettingsComponent implements OnInit {

  form:FormGroup;
  Fields:any = {
    AssetLocation: null,
    GeofenceAlert: null,
    Asset: null,
    AssetAssociation: null,
    LocationHierarchy: null,
    DeviceStatus: null
  };
  url: string[];
  rawUrl: any;
  ipString = 'http://' + environment.statusServerIp + '/api/status/saveDeviceStatus';
  headerdata: { helpdata: any; pageName: any; };

  constructor(public utilityService: UtilityService) { }

/**
 * (ngOnInit)
 * Description:
 * this function is called when angular initializes the component
 */
  ngOnInit() {
    // title and help
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });

    // define validation rules
    this.form = new FormGroup({
      assetLocation: new FormControl(null, {
        validators: [Validators.minLength(3)]
      }),
      geofenceAlert: new FormControl(null, {
        validators: [Validators.minLength(3)]
      }),
      asset: new FormControl(null, {
        validators: [Validators.minLength(3)]
      }),
      assetAssociation: new FormControl(null, {
        validators: [Validators.minLength(3)]
      }),
      locationHierarchy: new FormControl(null, {
        validators: [Validators.minLength(3)]
      }),
      deviceStatus: new FormControl(null, {
        validators: [Validators.minLength(3)]
      })
    });

    // get settings value if stored in db.
    this.utilityService.post({Type:"Mirth",SubType:"Mirth"}, 'settings/getSettings', (res)=>{
      if (res.status){
        this.Fields = res.data['Fields'];
      }
    });
  }

/**
 * (saveData)
 * Description:
 * this function validates the data and saves the data(settings) to db
 */
  saveData() {
    if (this.form.invalid) {
      alert('Please fill all the details correctly');
      return;
    }
    let temp = {};
    temp['Type'] = 'Mirth';
    temp['SubType'] = 'Mirth';
    temp['Fields'] = this.Fields;
    console.log(temp);
    this.utilityService.post(temp,'settings/create',(res)=>{
      if (res.includes('Setting Updated')) {
        alert('Settings Saved!');
      } else {
        alert('Failed to change settings');
      }
  });
  }

}
