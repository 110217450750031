import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

/**
 * File upload component
 * this is file upload component use to extarct csv
 */
@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.css"]
})
export class FileUploadComponent implements OnInit {
  locationTags: any = [];
  fields: any;
  @Output() messageEvent = new EventEmitter();
  @Input() data;

  constructor() {}

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
  }

  /**
   * (openFile)
   * Description:
   * selects CSV which we want to import.
   */
  openFile(fileupload) {
    if (fileupload.target.files) {
      let input = fileupload.target;
      let reader = new FileReader();
      reader.onload = () => {
        let fileType = fileupload.target.files[0].name
          .split(".")[1]
          .toLowerCase();
        if (fileType === "csv") {
          this.convertCsvToJson(reader.result);
        } else if (fileType === 'json') {
          this.parseJsonFile(reader.result);
        }
        document.querySelector(".box label span").innerHTML =
          fileupload.target.files[0].name;
      };
      if (input.files[0]) {
        reader.readAsText(input.files[0]);
      }
    }
  }

  /**
   * (csvToJson)
   * Description:
   * converts csv to json and sent it to export csv setting
   */
  convertCsvToJson(csvData) {
    let lines = csvData.split("\n");
    let result = [];
    let headers = lines[0].split(",");
    for(let i = 1; i < lines.length; i++) {
      let object = {};
      let currentline = lines[i].split(",");
      for(let j = 0; j < headers.length; j++) {
        object[headers[j]] = currentline[j];
      }
      result.push(object);
    }
    this.messageEvent.emit({ type: "csv", data: result });
  }

  /**
   * (parseJsonFile)
   * Description:
   * parses the incoming json file
   */
  parseJsonFile(jsonData) {
    this.messageEvent.emit({ type: "json", data: jsonData });
  }
}
