import * as S3 from 'aws-sdk/clients/s3';
import { Observable, Subject } from 'rxjs';
import { StorageProvider } from './storage-provider-interface';
var async = require('async');

export class S3StorageProvider implements StorageProvider {

  private BUCKET = 'rtls-files';

  private static instance: S3StorageProvider;
  private bucket: S3;
  private imageFileMap : Map<string, string>;
  private imageFileSource$ : Subject<Map<string, string>> = new Subject();

  private constructor() {}

  static getInstance() : S3StorageProvider {
    if(!S3StorageProvider.instance) {
      S3StorageProvider.instance = new S3StorageProvider();
      S3StorageProvider.instance.bucket = new S3(
        {
          accessKeyId: 'AKIA5OKWW6DNFQANFNIO',
          secretAccessKey: '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',
          region: 'us-west-2'
        }
      );
      S3StorageProvider.instance.imageFileMap = new Map([ ['0', '0'] ]);
    }
    return S3StorageProvider.instance;
  }

  writeFile(file) {

  }

  getImageFile(filePath:string) : any {
    let image = S3StorageProvider.instance.imageFileMap.get(filePath) || null;
    return image;
  }

  getImageFileObservable(): Observable<Map<string, string>> {
    return this.imageFileSource$.asObservable();
  }

  getFiles() : Promise<number> {
    return new Promise(resolve => {
      let params = {
        Bucket: S3StorageProvider.instance.BUCKET,
        Prefix: 'var/uploads/images/'
      };

      S3StorageProvider.instance.bucket.listObjects(params, function (err: any, data: any) {
        if (err) {
          console.log('There was an error getting your files: ' + err);
          resolve(-1);
        }
        console.log('Total Image Files: ' + data.Contents.length);
        async.eachSeries(data.Contents, function(fileObj: any, callback: any){
          var key: string = fileObj.Key;

          var fileParams = {
            Bucket: S3StorageProvider.instance.BUCKET,
            Key: key
          }

          S3StorageProvider.instance.bucket.getObject(fileParams, function(err: any, fileContents: any){
            if (err) {
              callback(err);
            } else {
              let image = encode(fileContents.Body);
              S3StorageProvider.instance.imageFileMap.set(key, 'data:image/jpg;base64,' + image);
              S3StorageProvider.instance.imageFileSource$.next(S3StorageProvider.instance.imageFileMap);
              callback();
            }
          });

          function encode(data) {
            var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
            return str;
          }
        }, function(err: any) {
          if (err) {
            console.log('Failed: ' + err);
            resolve(-1);
          } else {
            console.log('Finished downloading images from S3');
            resolve(data.Contents.length);
          }
        });
      });

      /* S3StorageProvider.instance.bucket.listObjects(params, function (err: any, data: any) {
        if (err) {
          console.log('There was an error getting your files: ' + err);
          resolve(1);
        }
        console.log('Total Files to be deleted: ' + data.Contents.length);
        async.eachSeries(data.Contents, function(fileObj: any, callback: any){
          var key: string = fileObj.Key;

          var fileParams = {
            Bucket: S3StorageProvider.instance.BUCKET,
            Key: key
          }

          S3StorageProvider.instance.bucket.deleteObject(fileParams, function(err: any, fileContents: any){
            if (err) {
              callback(err);
            } else {
              callback();
            }
          });
        }, function(err: any) {
          if (err) {
            console.log('Failed: ' + err);
            resolve(0);
          } else {
            console.log('Finished deleting files');
            resolve(1);
          }
        });
      }); */
    });
  }

  getFileCount()  : Promise<number> {
    return new Promise(resolve => {
      let params = {
        Bucket: S3StorageProvider.instance.BUCKET,
        Prefix: 'var/uploads/images/'
      };
      S3StorageProvider.instance.bucket.listObjects(params, function (err: any, data: any) {
        if (err) {
          console.log('There was an error while getting file count: ' + err);
          resolve(-1);
        }
        resolve(data.Contents.length);
      });
    });
  }
}
