import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { StorageConfig } from 'src/app/storage/storage-config';
import { StorageProvider } from 'src/app/storage/storage-provider-interface';
import { UtilityService } from 'src/app/utility.service';

/**
 * This page is used to mark monitors on a map,
 * useful in case of loction providers like Centrak
 */
@Component({
  selector: 'app-marking-on-map',
  templateUrl: './marking-on-map.component.html',
  styleUrls: ['./marking-on-map.component.css']
})
export class MarkingOnMapComponent implements OnInit, AfterViewInit, OnDestroy {

  mapId;
  headerdata: { helpdata: any; pageName: any };
  rawUrl: any;
  url: string[];
  context: CanvasRenderingContext2D;
  @ViewChild('myCanvas', {static: true}) myCanvas;
  availableWidth; availableHeight;
  scaleX = 1; scaleY = 1;
  storageProvider: StorageProvider;

  constructor(public route: ActivatedRoute, public utilityService: UtilityService) {}

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.utilityService.setLoaderflagStatus(true);
    this.storageProvider = StorageConfig.getInstance().getStorageProvider();
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('mapId')) {
        this.mapId = paramMap.get('mapId');
        this.drawMap();
      }
    });
    // title and help
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 2];
    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
    this.availableWidth = window.screen.availWidth - 150;
    this.availableHeight = window.screen.availHeight - 250;
  }

  /**
   * (ngAfterViewInit)
   * Description:
   * this function is called when angular has finished initialising the views
   */
  ngAfterViewInit() {
    window.addEventListener('mousemove', this.draw, false);
  }

  /**
   * (drawMap)
   * This functions draws the map image
   */
  drawMap() {
    var thisComponent = this;
    let canvas = this.myCanvas.nativeElement;
    let context = canvas.getContext('2d');
    var image = new Image();
    image.onload = function() {
        console.log('Available: ' + thisComponent.availableWidth, thisComponent.availableHeight);
        console.log('Image: ' + image.width, image.height);
        if (image.width > thisComponent.availableWidth ) {
          canvas.width = thisComponent.availableWidth;
          thisComponent.scaleX = image.width / canvas.width;
        } else {
          canvas.width = image.width;
        }
        if (image.height > thisComponent.availableHeight) {
          canvas.height = thisComponent.availableHeight;
          thisComponent.scaleY = image.height / canvas.height;
        } else {
          canvas.height = image.height;
        }
        console.log('Canvas: ' + canvas.width, canvas.height);
        console.log('Scale: ' + thisComponent.scaleX, thisComponent.scaleY);
        context.drawImage(image, 0, 0, image.width, image.height,
                              0, 0, canvas.width, canvas.height);
        thisComponent.utilityService.get('/location/api/hierarchy/markedMonitors/get/' + thisComponent.mapId, (res) => {
          console.log(res);
          const markedMonitors = res;
          if (markedMonitors && markedMonitors.length > 0) {
            // tslint:disable-next-line: forin
            for (const a in markedMonitors) {
              const monitor = markedMonitors[a];
              var pointer = new Image();
              pointer.onload = function() {
                // tslint:disable-next-line: max-line-length
                context.drawImage(pointer, (monitor.xCoordinate - 21) / thisComponent.scaleX, (monitor.yCoordinate - 48) / thisComponent.scaleY, 40, 55);
              };
              pointer.src = 'assets/images/monitor.webp';
            }
          }
          thisComponent.utilityService.setLoaderflagStatus(false);
        }, (err) =>  {
          console.log(err);
          thisComponent.utilityService.setLoaderflagStatus(false);
        });
    };
    image.src = this.getMap(`var/uploads/images/Maps/${this.mapId}.jpg`);
  }


  /**
   * (draw)
   * Description:
   * this function records the coordinates of a location on map
   */
  draw(evt) {
    const canvas: any = document.getElementById('target');
    const rect = canvas.getBoundingClientRect();
    const x = (Math.round((evt.clientX - rect.left) / (rect.right - rect.left) * canvas.width));
    const y = Math.round((evt.clientY - rect.top) / (rect.bottom - rect.top) * canvas.height);
    localStorage.setItem('monitorX', x + '');
    localStorage.setItem('monitorY', y + '');
  }

  /**
   * (saveMarkedLocation)
   * Description:
   * this function saves the marked location to db
   */
  saveMarkedLocation() {
    var thisComponent = this;
    const canvas: any = document.getElementById('target');
    const monitorX = parseInt(localStorage.getItem('monitorX'), 10);
    const monitorY = parseInt(localStorage.getItem('monitorY'), 10);
    console.log('Monitor: ' + monitorX, monitorY);
    if (monitorX >= canvas.width || monitorY >= canvas.height) {
      // can't mark out of image
      return;
    }
    const providerName = prompt('Enter the Location Provider name').toLowerCase();
    const monitorId = prompt('Enter the Monitor Id');
    if (providerName && monitorId) {
      const monitor = {
        hierarchyId : providerName + '-' + monitorId,
        provider : providerName,
        mapId : this.mapId,
        type : 'monitor',
        xCoordinate: monitorX * this.scaleX,
        yCoordinate: monitorY * this.scaleY
      };
      console.log(monitor);
      const postData = [monitor]; // api expects body as array
      this.utilityService.doPost(postData, '/location/api/provider/saveHierarchies', res => {
          let canvas = this.myCanvas.nativeElement;
          let context = canvas.getContext('2d');
          var pointer = new Image();
          pointer.onload = function() {
            // tslint:disable-next-line: max-line-length
            context.drawImage(pointer, (monitor.xCoordinate - 21) / thisComponent.scaleX, (monitor.yCoordinate - 48) / thisComponent.scaleY, 40, 55);
          };
          pointer.src = 'assets/images/monitor.webp';
          alert('Monitor Marked Successfully!');
        },
        err => {
          alert(err.error.message);
      });
    }
  }

  getMap(filePath:string) : any {
    return this.storageProvider.getImageFile(filePath);
  }

  /**
   * (ngOnDestroy)
   * Description:
   * This function is called when angular destroys this component
   */
  ngOnDestroy() {
    window.removeEventListener('mousemove', this.draw, false);
  }

}
