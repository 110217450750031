import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeviceListComponent } from './pages/device-list/device-list.component';
import { DeviceDetailComponent } from './pages/device-detail/device-detail.component';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { DeviceImportComponent } from './pages/device-import/device-import.component';
import { StatusVsTimeComponent } from './reports/status-vs-time/status-vs-time.component';
import { PumpMovementComponent } from './reports/pump-movement/pump-movement.component';
import { MovementVsTimeComponent } from './reports/movement-vs-time/movement-vs-time.component';
import { AlertComponent } from './reports/alert/alert.component';
import { AlarmComponent } from './reports/alarm/alarm.component';
import { NotSupportComponent } from './pages/not-support/not-support.component';
import { FourZeroFourComponent } from './pages/four-zero-four/four-zero-four.component';
import { MednetAlarmComponent } from './reports/mednet-alarm/mednet-alarm.component';
import { DownloadErrorLogsComponent } from './pages/download-error-logs/download-error-logs.component';
import { MetadataMappingComponent } from './pages/metadata-mapping/metadata-mapping.component';
import { MapImportComponent } from './pages/map-import/map-import.component';
import { GeneratedReportsComponent } from './reports/generated-reports/generated-reports.component';
import { GenerateReportFormComponent } from './reports/generate-report-form/generate-report-form.component';
import { CustomReportComponent } from './reports/custom-report/custom-report.component';
import { HeatMapReportComponent } from './reports/heat-map-report/heat-map-report.component';
import { GeofencingComponent } from './pages/geofencing/geofencing.component';
import { NearbyReportsComponent } from './reports/nearby-reports/nearby-reports.component';
import { GeofenceAlertsComponent } from './pages/geofence-alerts/geofence-alerts.component';
import { GeofenceAlertOnMapComponent } from './pages/geofence-alert-on-map/geofence-alert-on-map.component';
import { MarkingOnMapComponent } from './pages/marking-on-map/marking-on-map.component';
import { ViewMapComponent } from './pages/view-map/view-map.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { TableComponent } from '../app/widgets/table/table.component';
import { MirthSettingsComponent } from './pages/mirth-settings/mirth-settings.component';
import { AdminConfigurationsComponent } from './pages/admin-configurations/admin-configurations.component';
import { GeofenceListComponent } from './pages/geofence-list/geofence-list.component';
import { PrivilegeSettingsComponent } from './pages/privilege-settings/privilege-settings.component';
import { CustomTagsComponent } from './pages/custom-tags/custom-tags.component';
import { AllAssetsMapViewComponent } from './reports/all-assets-map-view/all-assets-map-view.component';
import { NoCredentialsComponent } from './pages/no-credentials/no-credentials.component';
import { HierarchyListTwoComponent } from './pages/hierarchy-list-two/hierarchy-list-two.component';
import { ProviderSettingsComponent } from './pages/provider-settings/provider-settings.component';
import { ScalingConfigComponent } from './pages/scaling-config/scaling-config.component';
import { MednetParameterComponent } from './pages/mednet-parameter/mednet-parameter.component';
import { ListAssetsComponent } from './pages/list-assets/list-assets.component';
import { AddLsNotifyRuleComponent } from './pages/add-ls-notify-rule/add-ls-notify-rule.component';
import { EncryptionSettingComponent } from './pages/encryption-setting/encryption-setting.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { LsNotifyRulesComponent } from './pages/ls-notify-rules/ls-notify-rules.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'device-detail/:deviceId',
    component: DeviceDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'scaling-option',
    component: ScalingConfigComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'asset-detail',
    component: DeviceDetailComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'devices',
    component: DeviceListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'device-import',
    component: DeviceImportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'report/status-vs-time',
    component: StatusVsTimeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'report/pump-movement',
    component: PumpMovementComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'report/movement-vs-time',
    component: MovementVsTimeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'report/nearby-pump',
    component: NearbyReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'report/rtls-alert',
    component: AlertComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'report/rtls-alarm',
    component: AlarmComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'report/all-assets-map-view',
    component: AllAssetsMapViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'report/mednet-alarm',
    component: MednetAlarmComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'temp',
    component: TableComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'not_support',
    component: NotSupportComponent
  },
  {
    path: 'error-logs',
    component: DownloadErrorLogsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'metadata-mappings',
    component: MetadataMappingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "map-import/:hierarchyId",
    component: MapImportComponent,
    canActivate: [AuthGuard]

  },
  {
    path: "hierarchy-list-two",
    component: HierarchyListTwoComponent,
    canActivate: [AuthGuard]

  },
   {
    path: 'generate-report',
    component: GeneratedReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'generate-report-form',
    component: GenerateReportFormComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'custom-report/:reportId',
    component: CustomReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'heat-map-report/:reportId',
    component: HeatMapReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'geofencing/:mapId',
    component: GeofencingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-map/:hierarchyId',
    component: ViewMapComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'geofence-alerts',
    component: GeofenceAlertsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'geofence-alert/:alertId',
    component: GeofenceAlertOnMapComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'generate-report-form/:reportId',
    component: GenerateReportFormComponent
  },
  {
    path: 'map-marking/:mapId',
    component: MarkingOnMapComponent
  },
  {
    path:'access-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'geofencelist',
    component: GeofenceListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'assetlist',
    component: ListAssetsComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'encryption-setting',
    component: EncryptionSettingComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: "accessdenied",
    component: AccessDeniedComponent,
  },
  {
    path:'mirth-settings',
    component:MirthSettingsComponent,
    canActivate:[AuthGuard]
  },
  {
    path: 'admin-config',
    component: AdminConfigurationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'privilege-settings',
    component: PrivilegeSettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'custom-tags',
    component: CustomTagsComponent,
    //canActivate: [AuthGuard]
  },
  {
    path: 'provider-settings/:provider',
    component: ProviderSettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-ls-notify-rule',
    component: AddLsNotifyRuleComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ls-notify-rules',
    component: LsNotifyRulesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'mednet-param',
    component: MednetParameterComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'no-credentials',
    component: NoCredentialsComponent
  },
  { path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',   /*url for 404 page*/
    component: FourZeroFourComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
