import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/utility.service';
import { DeviceService } from 'src/app/device.service';
import { Subscription } from 'rxjs';
import { StorageConfig } from 'src/app/storage/storage-config';
import { DomSanitizer } from '@angular/platform-browser';
import { StorageProvider } from 'src/app/storage/storage-provider-interface';
declare var $: any;
const BACKEND_URL = environment.locationUrl;

/**
 * Heat map component
 */
@Component({
  selector: 'app-device-heat-map',
  templateUrl: './device-heat-map.component.html',
  styleUrls: ['./device-heat-map.component.css']
})
export class DeviceHeatMapComponent implements OnInit, OnDestroy {
  @Input() getCoordinate;
  @Input() ngShareData;
  private paginationSubscription: Subscription;
  changeText = {};
  mapIds = [];
  mapParameters = {};
  currentLocFlag;
  Devices = [];
  end: number = 1;
  start: number = 0;
  page: string = '';
  mapNameObj = {};
  searchText = '';
  MetaDataMapping: any = {};
  storageProvider: StorageProvider;

  constructor(public deviceService: DeviceService,private http: HttpClient, public router: Router, public utilityService: UtilityService, public bypassSecurityTrustUrl: DomSanitizer) {
    this.storageProvider = StorageConfig.getInstance().getStorageProvider();
  }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {

    /* Get pagination data form map view page */
    this.paginationSubscription = this.utilityService.getPaginationData().subscribe(data => {
      this.sendData(data);
    });

    /* Get data for maps */
    this.getData((data) => {
      if (typeof data != "undefined" && data.length > 0) {
        this.sendData(data);
      }
    });

    /* listener , data coming from another component  */
    this.fetchData((data) => {
      if (typeof data != "undefined" && data.length > 0) {
        console.log("data", data);
        this.sendData(data);
      }
    });

    this.deviceService.getData("asset.getMetaDataMapping", data => {
        this.MetaDataMapping = data;
    }, res => { console.log(res);}
    );
  }

  /**
   * (nextMap)
   * Description:
   * loads next maps on click.
   */
  nextMap() {
    if (this.end < this.mapIds.length) {
      this.end = this.end + 1;
      this.start = this.end - 1;
    }
  }

  /**
   * (previousMap)
   * Description:
   * loads previous maps on click.
   */
  previousMap() {
    if (this.end > 1) {
      this.end = this.end - 1;
      this.start = this.end - 1;
    }
  }

  /**
   * (getData)
   * Description:
   * gets map x,y
   */
  getData(cb) {
    if (typeof this.getCoordinate != "undefined") {
      this.getCoordinate.then((res) => {
        cb(res);
      });
    } else {
      cb([]);
    }

  }

  /**
   * (getData)
   * Description:
   * gets data from parent component.
   */
  fetchData(cb) {
    if (typeof this.ngShareData != "undefined") {
      cb(this.ngShareData);
    } else {
      cb([]);
    }

  }

  /**
   * (getData)
   * Description:
   * Validates map data.
   */
  sendMapData(event) {
    if (event) {
      let flag = Object.keys(event)[1];
      if (flag == 'page') {
        console.log(event.result);

        this.page = event['page'];
        this.sendData(event.result);
      } else {
        console.log(event);
        if (event.length == 0) {
          this.mapIds = []
        } else {
          this.sendData(event);
        }
      }
    }

  }

  /**
   * (sendData)
   * Description:
   * sends data to report component
   */
  sendData(event) {
    if (this.router.url == '/report/nearby-pump') {
      this.utilityService.setDataForTable({
        reportData: event.result,
        showPagination: 0,
        fields: ['AssetId','AssetType','AssetName','Location','Distance'],
        tableName: 'Nearby Assets'
      });
      this.Devices = event.result;
      for(let location of this.Devices) {
        let fullName = '';
        let fullLocation = location.CurrentLocation.fullPath;
        for(let level in fullLocation) {
          fullName = fullName + fullLocation[level] + '>';
        }
        fullName = fullName.substring(0, fullName.length - 1);
        location.CurrentLocation.fullPath = fullName;
      }
      this.mapIds = [];
      for (let index = 0; index < event.result.length; index++) {
        const element = event.result[index];
        console.log(element.CurrentLocation.mapId);
        // tslint:disable-next-line: max-line-length
        if (this.mapIds.length === 0 || (typeof element.CurrentLocation != 'undefined' && this.mapIds.indexOf(element.CurrentLocation.mapId)) > 0) {
          if (typeof element.CurrentLocation != 'undefined' && typeof element.CurrentLocation.mapId != 'undefined') {
            this.mapIds.push(element.CurrentLocation.mapId);
          }
        }
      }
    } else if (this.router.url == '/report/fence-status') {
      // console.log(event.result);
      this.Devices = event.result;
      for(let location of this.Devices) {
        let fullName = '';
        let fullLocation = location.CurrentLocation.fullPath;
        for(let level in fullLocation) {
          fullName = fullName + fullLocation[level] + '>';
        }
        fullName = fullName.substring(0, fullName.length - 1);
        location.CurrentLocation.fullPath = fullName;
      }
      this.mapIds = [];
      for (let index = 0; index < event.result.length; index++) {
        const element = event.result[index];
        if (this.mapIds.length == 0) {
          if (typeof element.CurrentLocation != 'undefined' && typeof element.CurrentLocation.mapId != 'undefined') {
            this.mapIds.push(element.CurrentLocation.mapId);
          }
        } else if (typeof element.CurrentLocation != 'undefined' && this.mapIds.indexOf(element.CurrentLocation.mapId) < 0) {
          if (typeof element.CurrentLocation.mapId != 'undefined') {
            this.mapIds.push(element.CurrentLocation.mapId);
          }
        }
      }
    } else {
      this.Devices = event.assets ? event.assets : event;
      for(let location of this.Devices) {
        let fullName = '';
        let fullLocation = location.CurrentLocation.fullPath;
        for(let level in fullLocation) {
          fullName = fullName + fullLocation[level] + '>';
        }
        fullName = fullName.substring(0, fullName.length - 1);
        location.CurrentLocation.fullPath = fullName;
      }
      console.log("sd",this.Devices)
      this.mapIds = [];
      // console.log(event);
      for (let index = 0; index < event.length; index++) {
        const element = event[index];
        if (this.mapIds.length == 0) {
          if (typeof element.CurrentLocation != 'undefined' && typeof element.CurrentLocation.mapId != 'undefined') {
            this.mapIds.push(element.CurrentLocation.mapId);
          }
        } else if (typeof element.CurrentLocation != 'undefined' && this.mapIds.indexOf(element.CurrentLocation.mapId) < 0) {
          if (typeof element.CurrentLocation.mapId != 'undefined') {
            this.mapIds.push(element.CurrentLocation.mapId);
          }
        }

      }
    }
  }

  /**
   * (getPath)
   * Description:
   * this function returns image path.
   */
  getPath(mapId) {
   return this.getMap(`var/uploads/images/Maps/${mapId}.jpg`);
  }

  getMap(filePath:string) : any {
    let image = this.storageProvider.getImageFile(filePath);
    if(image) return image.startsWith('data:image/') ? this.bypassSecurityTrustUrl.bypassSecurityTrustUrl(image) : image;
    else return null;
  }

  /**
   * (getMapDetails)
   * Description:
   * this function get data(pointer x,y each map)
   */
  getMapDetails(mapId) {
    let serverUrl = BACKEND_URL + 'location.getMap/' + mapId;
    this.http.get(serverUrl).subscribe((res) => {
      console.log(res);
      this.mapNameObj[mapId] = res['name'];
      this.mapParameters[mapId] = {
        'xView': document.getElementById(mapId).offsetWidth,
        'yView': document.getElementById(mapId).offsetHeight,
        'xDimension': res['xDimension'],
        'yDimension': res['yDimension']
      }
      console.log(this.mapParameters);

      this.currentLocFlag = Object.keys(this.mapParameters).length;
      console.log(this.currentLocFlag);
    }, (err) => {
      console.log(err);
    });
  }

  /**
   * (getCurrentLocation)
   * Description:
   * this function calculates data(pointer on each map).
   */
  getCurrentLocation(each) {

    const param = this.mapParameters[each.mapId];
    if (typeof param != 'undefined') {
      const x = (param.xView / param.xDimension) * each.xCenterPoint;
      const y = (param.yView / param.yDimension) * each.yCenterPoint;
      // console.log(param); //xView
      if (this.page == "Heat Map") {
        var styles = {
          position: 'absolute',
          left: x + 'px',
          top: (y - 20) + 'px',
          width: '137px'
        };
      } else {

        var styles = {
          position: 'absolute',
          left: x + 'px',
          top: (y - 20) + 'px',
          width: '37px'
        };
      }

    }
    return styles;
  }

  /**
   * (toolTipStyle)
   * Description:
   * this function calculates tool tip location(x,y) according to front-end map.
   */
  toolTipStyle(each) {
    const param = this.mapParameters[each.mapId];
    if (typeof param != 'undefined') {
      const x = (param.xView / param.xDimension) * each.xCenterPoint;
      const y = (param.yView / param.yDimension) * each.yCenterPoint;
      // console.log(param); //xView
      var styles = {
        'position': 'absolute',
        'left': x - 96 + 'px',
        'top': y + 55 + 'px',
        'width': '230px',
        'z-index': '1',
      };
    }

    return styles;

  }

  /**
   * (showTooltip)
   * Description:
   * this function show detail on hover
   */
  showTooltip(i) {
   if (!this.router.url.includes('/device-detail')) {
    this.changeColorPointer();
   }

    // alert(i);
   this.changeText[i] = true;
  }


  /**
   * (hideTooltip)
   * Description:
   * this function hide detail on hover
   */
  hideTooltip(i) {
    this.changeText[i] = false;
  }

  /**
   * (returnSourcePath)
   * Description:
   * this function returns source path
   */
  returnSourcPath(filePath: any) {
    return this.getMetadataFile(filePath);
  }

  getMetadataFile(filePath:string) : any {
    let image = this.storageProvider.getImageFile(filePath);
    if(image) return image.startsWith('data:image/') ? this.bypassSecurityTrustUrl.bypassSecurityTrustUrl(image) : image;
    else return null;
  }

  /**
   * (changeColorPointer)
   * Description:
   * this function changes pointer color on hover.
   */
  changeColorPointer() {

    $(document).ready(function () {
      $('.same_device_details').mouseover(function () {
        //var pointer = $('.same_device_details').attr("class");
        var activePointer = $(this).attr("class").split(/\s+/)[1];

        $('.same_device_details').each(function (i, obj) {
          if (obj.nodeName === 'IMG') {
            let getImg = $(this).attr('src').replace("marker_yellow", "marker_green");
            $(this).attr('src', getImg);
          } else {
            $(this).removeClass('bg-primary');
          }
        });

        $('.' + activePointer).each(function (i, obj) {
          if (obj.nodeName === 'IMG') {
            let getImg = $(this).attr('src').replace("marker_green", "marker_yellow");
            $(this).attr('src', getImg);
          } else {
            $(this).addClass('bg-primary');
          }
        });

      });
    })
  }

  /**
   * (openNewTab)
   * Description:
   * this function opens asset detail page in new tab
   */
  openNewTab(asset) {
    console.log(asset);
    window.open(`/#/asset-detail;type=${asset.AssetType || asset.Type};id=${asset.AssetId}`);
  }

  /**
   * (ngOnDestroy)
   * this function is called when angular destroys this component
   */
  ngOnDestroy() {
    if (this.paginationSubscription) { this.paginationSubscription.unsubscribe(); }
  }
}
