import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StorageConfig } from 'src/app/storage/storage-config';
import { StorageProvider } from 'src/app/storage/storage-provider-interface';
import { UtilityService } from 'src/app/utility.service';
import { DomSanitizer } from '@angular/platform-browser';

declare var jQuery: any;

@Component({
  selector: 'app-hierarchy-list-two',
  templateUrl: './hierarchy-list-two.component.html',
  styleUrls: ['./hierarchy-list-two.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HierarchyListTwoComponent implements OnInit {

  rtlsHierarchies = [];
  providerHierarchies = {};
  mappedProviderIds = {};
  providerAssociations: any = {};
  providerSelections: any = {}; // for maintaining data on which provider hierarchies were manualy selected on this screen

  public locationProviders = [];
  dataLoadCyclesDone = 0;
  storedData: any = {};

  url: string[];
  rawUrl: any;
  headerdata: { helpdata: any; pageName: any; };
  storageProvider: StorageProvider;

  constructor(public utilityService: UtilityService, public bypassSecurityTrustUrl: DomSanitizer) {
    this.utilityService.setLoaderflagStatus(true);
    this.storageProvider = StorageConfig.getInstance().getStorageProvider();
  }

  ngOnInit() {
    // title and help
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
    this.utilityService.get('/location/api/hierarchy/getActivatedHierarchyProviders', res => {
      this.locationProviders = res;
      this.getRTLSHierarchies();
    }, err => {
      console.log(err);
    });
    this.custom_tooltip();
  }

  /**
   * (modifyRtlsHierarchy)
   * Description:
   * this function modifies the rtls hierarchy to contain the provider mappings
   */
  modifyRtlsHierarchy(provider, rtlsHierarchy) {
    if (!rtlsHierarchy.mappings) {
      rtlsHierarchy.mappings = {};
    }
    let providerHierarchy = this.providerAssociations[provider][rtlsHierarchy.hierarchyId];
    let mapping = {
      hierarchyId: providerHierarchy.hierarchyId,
        scaling: {
          x: 1,
          y: 1
        }
    };
    rtlsHierarchy.mappings[provider] = mapping;
    let fullDisplayName = rtlsHierarchy.fullDisplayName;
    delete rtlsHierarchy.fullDisplayName;
    // call update api
    this.utilityService.doPost(rtlsHierarchy, 'location/api/hierarchy/modifyRtlsHierarchy', (res) => {
      rtlsHierarchy.fullDisplayName = fullDisplayName;
      alert(res.message);
    }, (err) => {
      console.log(err);
      rtlsHierarchy.fullDisplayName = fullDisplayName;
    });
  }

  /**
   * (removeMappingFromHierarchy)
   * Description:
   * this function removes provider hierarchy from rtls hieararchy mapping
   */
  removeMappingFromHierarchy(provider, rtlsHierarchy) {
    const rtlsHierarchyId = rtlsHierarchy.hierarchyId;
    delete this.providerAssociations[provider][rtlsHierarchyId];
    if (rtlsHierarchy.mappings && rtlsHierarchy.mappings[provider]) {
      delete rtlsHierarchy.mappings[provider];
    }
    if (this.providerSelections.hasOwnProperty(provider)) {
      this.providerSelections[provider].splice(this.providerSelections[provider].indexOf(rtlsHierarchyId));
    }
    const image: any = document.getElementById('img-' + provider + '-' +  rtlsHierarchyId);
    image.src = `xyz.jpg`;
    this.utilityService.doPost(rtlsHierarchy, 'location/api/hierarchy/modifyRtlsHierarchy', (res) => {
      // alert(res.message);
    }, (err) => {
      console.log(err);
    });
  }

  /**
   * (onSelectionChange)
   * Description:
   * this function is called when there is a selection change in a provider column.
   */
  onSelectionChange($event, provider, rtlsHierarchyId) {
    const selectedProviderHierarchy = $event.value;
    const mapId = selectedProviderHierarchy.mapId;
    let id = 'img-' + provider + '-' + rtlsHierarchyId;
    let map: any = this.setMapDynamically(`var/uploads/images/Maps/${mapId}.jpg`);
    document.getElementById(id).setAttribute('src', map);
    if (selectedProviderHierarchy === -1) {
      if (this.providerSelections.hasOwnProperty(provider)) {
        this.providerSelections[provider].splice(this.providerSelections[provider].indexOf(rtlsHierarchyId));
      }
      if (this.providerAssociations.hasOwnProperty(provider)) {
        delete this.providerAssociations[provider][rtlsHierarchyId];
      }
    } else {
      if (!this.providerSelections.hasOwnProperty(provider)) {
        this.providerSelections[provider] = [];
      }
      this.providerSelections[provider].push(rtlsHierarchyId);
      if (!this.providerAssociations.hasOwnProperty(provider)) {
        this.providerAssociations[provider] = {};
      }
      this.providerAssociations[provider][rtlsHierarchyId] = selectedProviderHierarchy;
    }
  }

  /**
   * (checkSelection)
   * Description:
   * this function is called to check whether an provider hierarchy is selected from dropdown
   */
  checkSelection(provider, key, providerHierarchy) {
    for (const a in this.providerAssociations[provider]) {
      if (this.providerAssociations[provider][a]._id === providerHierarchy._id) {
        if (a === key) {
          return false;
        }
        return true;
      }
    }
  }

  /**
   * (shouldShowRemoveButton)
   * Description:
   * this function checks whether remove button is to be shown besides a provider hierarchy
   */
  shouldShowRemoveButton(provider, rtlsHierarchyId) {
    return this.providerAssociations.hasOwnProperty(provider) && this.providerAssociations[provider].hasOwnProperty(rtlsHierarchyId);
  }

  /**
   * (getRTLSHierarchies)
   * Description:
   * this function fetches all rtls hierarchies from backend.
   */
  getRTLSHierarchies() {
    this.utilityService.get('/location/api/hierarchy/rtlsHierarchies', res => {
      this.rtlsHierarchies = res;
      // tslint:disable-next-line: forin
      for (let a in this.rtlsHierarchies) {
        const rtlsHierarchy = this.rtlsHierarchies[a];
        const mappings = rtlsHierarchy.mappings ? rtlsHierarchy.mappings : {};
        // tslint:disable-next-line: forin
        for (const providerName in mappings) {
          let providerHierarchyId = mappings[providerName].hierarchyId;
          if (!this.mappedProviderIds.hasOwnProperty(providerName)) {
            this.mappedProviderIds[providerName] = {};
          }
          this.mappedProviderIds[providerName][providerHierarchyId] = rtlsHierarchy.hierarchyId;
        }
        // full name generation
        let fullName = '';
        for(const level in rtlsHierarchy.fullName) {
          fullName = fullName + rtlsHierarchy.fullName[level] + '>';
        }
        fullName = fullName.substring(0, fullName.length - 1);
        rtlsHierarchy['fullDisplayName'] = fullName;
      }
      this.getAllProviderHierarchies();
      this.onDataLoadFinish();
    }, err => {
      console.log(err);
      this.onDataLoadFinish();
    });
  }

  /**
   * (getProviderHierarchies)
   * Description:
   * this function fetches all provider hierarchies from backend.
   */
  getAllProviderHierarchies() {
    this.utilityService.get('/location/api/hierarchy/providerHierarchies', res => {
      this.providerHierarchies = res;
      // tslint:disable-next-line: forin
      for (const providerName in this.providerHierarchies) {
        const hierarchies: any[] = this.providerHierarchies[providerName];
        let a: number;
        for (a = 0 ; a < hierarchies.length ; a++) {
          const providerHierarchy = hierarchies[a];
          // tslint:disable-next-line: max-line-length
          if (this.mappedProviderIds.hasOwnProperty(providerName)
              && this.mappedProviderIds[providerName].hasOwnProperty(providerHierarchy.hierarchyId)) {
                const rtlsHierarchyId = this.mappedProviderIds[providerName][providerHierarchy.hierarchyId];
                if (!this.providerAssociations.hasOwnProperty(providerName)) {
                  this.providerAssociations[providerName] = {};
                }
                this.providerAssociations[providerName][rtlsHierarchyId] = providerHierarchy;
                let map = this.setMapDynamically(`var/uploads/images/Maps/${providerHierarchy.mapId}.jpg`);
                document.getElementById('img-' + providerName + '-' + rtlsHierarchyId).setAttribute('src', map);
          }
        }
      }
      this.mappedProviderIds = null;
      this.onDataLoadFinish();
    }, err => {
      console.log(err);
      this.onDataLoadFinish();
    });
  }

  getHierarchiesByProvider(provider: string) {
    return this.providerHierarchies[provider];
  }

  getMap(filePath:string) : any {
    let image = this.storageProvider.getImageFile(filePath);
    if(image) return image.startsWith('data:image/') ? this.bypassSecurityTrustUrl.bypassSecurityTrustUrl(image) : image;
    else return null;
  }

  setMapDynamically(filePath:string) : any {
    return this.storageProvider.getImageFile(filePath);
  }

  /**
   * (openNewTab)
   * Description:
   * this function open a page in a new tab.
   */
  openNewTab(url) {
    window.open(url);
  }

  /**
   * (onDataLoadFinish)
   * Description:
   * this function stops the loader when all location provider hierarchies are loaded.
   */
  onDataLoadFinish() {
    this.dataLoadCyclesDone++;
    if (this.dataLoadCyclesDone === 2) {
      this.utilityService.setLoaderflagStatus(false);
    }
  }

  /**
   * (custom_tooltip)
   * This function is used to display tooltip
   */
  custom_tooltip() {
    jQuery(document).ready(function () {
      jQuery("[data-toggle='tooltip']").tooltip();
    });
  }


}
