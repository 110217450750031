import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { UtilityService } from '../utility.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
const BACKEND_URL = environment.apiUrl;

/**
 * DID-NEXUS-016
 * (Auth Guard Service)
 * This service handles the access of pages for a particular session
 */

@Injectable()
export class AuthGuardService implements CanActivate {

  private AllowedRoutes: string[] = [];

  constructor(public auth: AuthService, public router: Router, public utilityService: UtilityService, public http: HttpClient) {}

  /**
   * (Can Activate)
   * Standard method required to be implemented by CanActivate interface
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|boolean {
    if (!this.auth.isAuthenticated()) {
       this.router.navigate(['login']);
       return false;
    } else if (state.url.includes('privilege-settings') && !this.utilityService.isConfigured) {
      return true;
    } else if (!state.url.includes('privilege-settings') && !this.utilityService.isConfigured) {
      console.log(this.utilityService.isConfigured);
      this.router.navigate(['privilege-settings']);
      return false;
    } else if (this.AllowedRoutes.length === 0) {
      return this.http.get(BACKEND_URL + 'privilege/getAllowedRoutes').pipe(map((data: any) => {
        console.log(data);
        this.AllowedRoutes = data.allowedRoutes;
        if (this.checkUrl(state.url)) {
          return true;
        } else {
          this.router.navigate(['accessdenied']);
          return false;
        }
      }));
    } else {
      if (this.checkUrl(state.url)) {
        return true;
      } else {
        this.router.navigate(['accessdenied']);
        return false;
      }
    }
  }

  /**
   * (Check URL)
   * Helper function used by canActivate above
   */
  private checkUrl(url: string): boolean {
    // tslint:disable-next-line: prefer-for-of


    for (let i = 0; i < this.AllowedRoutes.length; i++) {
      // let pathCount = (url.match(new RegExp('/', 'g')) || []).length;
      // let parsedUrl;
      // if(pathCount > 1) {
      //   if (url.includes('custom-report') || url.includes('heat-map-report') || url.includes('provider-settings')) {
      //     parsedUrl = url.split('/')[pathCount - 1];
      //   } else parsedUrl = url.split('/')[pathCount];
      // } else parsedUrl = url;

      if (this.AllowedRoutes[i].includes(url) || url.includes(this.AllowedRoutes[i])) {
        return true;
      }
    }
    return false;
    // return true;
  }

  /**
   * (Add Allowed Routes)
   * Helper function used by canActivate above
   */
  public addAllowedRoute(route: string) {
    this.AllowedRoutes.push(route);
  }

  /**
   * (Add Allowed Routes)
   * Helper function used by canActivate above
   */
  public removeAllowedRoute(route: string) {
    this.AllowedRoutes.splice(this.AllowedRoutes.indexOf(route), 1);
  }

  /**
   * (Clear Permission)
   * Helper function used by canActivate above
   */
  clearPermission() {
    this.AllowedRoutes = [];
  }
}
