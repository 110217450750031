import { Component, OnInit } from '@angular/core';
import { DeviceService } from 'src/app/device.service';
import { UtilityService } from 'src/app/utility.service';

/**
 * DID-NEXUS-092, DID-NEXUS-107, DID-NEXUS-108
 * Through this page, a user can download error logs generated by system
 */
@Component({
  selector: 'app-error-logs',
  templateUrl: './download-error-logs.component.html',
  styleUrls: ['./download-error-logs.component.css']
})
export class DownloadErrorLogsComponent implements OnInit {
  mednet: boolean = false;
  icumed: boolean = false;
  awarepoint: boolean = false;
  centrak: boolean = false;
  cisco: boolean = false;
  report: boolean = false;
  location: boolean = false;
  // unlocation:boolean=false;
  aeroscout: boolean = false;
  select: boolean = false;
  url: string[];
  rawUrl: string;
  headerdata: { helpdata: any; pageName: any; };
  constructor(public deviceService: DeviceService, public utilityService: UtilityService) {
  }

/**
 * (ngOnInit)
 * Description:
 * this function is called when angular initializes the component
 */
  ngOnInit() {
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      }
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
  }

/**
 * (selectAll)
 * Description:
 * Select all functionlity.
 */
selectAll(e) {
  if (e.checked) {
    this.mednet = true;
    this.icumed = true;
    this.awarepoint = true;
    this.centrak = true;
    this.cisco = true;
    this.report = true;
    this.aeroscout = true;
    this.location = true;
    // this.unlocation=true;
  } else {
    this.mednet = false;
    this.icumed = false;
    this.awarepoint = false;
    this.centrak = false;
    this.cisco = false;
    this.report = false;
    this.aeroscout = false;
    this.location = false;
    // this.unlocation=false;
  }

}

/**
 * (submit)
 * Description:
 * this function downloads the error log files.
 */
submit() {
  // tslint:disable-next-line: max-line-length
  if (!this.aeroscout && !this.awarepoint && !this.centrak && !this.cisco && !this.icumed && !this.location && !this.report && !this.mednet) {
    alert('Please select at least one microservice');
  }
  // download mednet micro-services
  if (this.mednet) {
    document.getElementById('mednet').click();
  }

  // download mednet micro-services
  if (this.icumed) {
    document.getElementById('icumed').click();
  }

  // download aeroscout micro-services
  if (this.aeroscout) {
    document.getElementById('aeroscout').click();
  }

  // download awarepoint micro-services
  if (this.awarepoint) {
    document.getElementById('awarepoint').click();
  }

  // download awarepoint micro-services
  if (this.centrak) {
    document.getElementById('centrak').click();
  }

  // download cisco micro-services
  if (this.cisco) {
    document.getElementById('cisco').click();
  }

  // download report micro-services
  if (this.report) {
    document.getElementById('report').click();
  }

  // download location micro-services
  if (this.location) {
    document.getElementById('location').click();
  }

}

}
