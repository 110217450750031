import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/utility.service';


@Component({
  selector: "app-scaling-config",
  templateUrl: "./scaling-config.component.html",
  styleUrls: ["./scaling-config.component.css"],
})
export class ScalingConfigComponent implements OnInit {

  redisEnabled = false;
  redisSettingObj: any;
  deviceThreshold: any = 0;
  selectedRedisItem: any;
  kafkaIsChecked = false;
  kafkaSettingObj: any;
  kafkaMaxDevice: any;
  selectedKafkaItem: any;
  kafkaSetting: any;
  redisSetting: any;
  url: any;
  rawUrl: any;
  headerdata = {};

  constructor(public utilityService: UtilityService) {}

  ngOnInit() {
    this.getKafkaSetting();
    this.getRedisSetting();
    this.url = document.URL.split("/");
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue("help").then((res) => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl],
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
  }

  /**
   *
   * This funciton is used to fetch already saved kafka setting
   *
   */
  getKafkaSetting() {
    const body = { Type: "scale", SubType: "kafka" };
    this.utilityService.post(body, "settings/getSettings", (res) => {
      this.kafkaSetting = res;

      if (res.data.Fields.status == "Active") {
        this.kafkaIsChecked = true;
        this.kafkaMaxDevice = res.data.Fields.maxDevice;
        this.selectedKafkaItem = res.data.Fields.cachingData;
      } else {
        this.kafkaIsChecked = false;
        this.kafkaMaxDevice = res.data.Fields.maxDevice;
        this.selectedKafkaItem = res.data.Fields.cachingData;
      }
    });
  }

  /**
   *
   * This funciton is used to fetch already saved redis setting
   *
   */
  getRedisSetting() {
    const body = { Type: "Scale", SubType: "redis" };
    this.utilityService.post(body, "settings/getSettings", (res) => {
      this.redisSetting = res;
      console.log(res);

      if (res.data.Fields.Enabled) {
        this.redisEnabled = true;
        this.deviceThreshold = res.data.Fields.deviceThreshold;
        this.selectedRedisItem = res.data.Fields.cachingData;
      } else {
        this.redisEnabled = false;
        this.deviceThreshold = res.data.Fields.deviceThreshold;
        this.selectedRedisItem = res.data.Fields.cachingData;
      }
    });
  }

  /**
   *
   * This funciton is used to save kafka setting to db
   *
   */

  saveKafkaSetting() {
    this.kafkaSettingObj = {
      Type: "scale",
      SubType: "kafka",
      Fields: {
        status: this.kafkaIsChecked ? "Active" : "Inactive",
        maxDevice: this.kafkaMaxDevice,
        cachingData: this.selectedKafkaItem,
      },
    };
    console.log(this.kafkaSettingObj);
    this.utilityService.post(this.kafkaSettingObj, "settings/create", (res) => {
      console.log(res);
      alert("Setting Saved");
    });
  }

  /**
   *
   * This funciton is used to save redis setting to db
   *
   */
  saveRedisSetting() {
    this.redisSettingObj = {
      Type: "Scale",
      SubType: "redis",
      Fields: {
        Enabled: this.redisEnabled,
        deviceThreshold: parseInt(this.deviceThreshold),
        cachingData: this.selectedRedisItem,
      },
    };
    console.log(this.redisSettingObj);
    this.utilityService.post(this.redisSettingObj, "settings/create", (res) => {
      console.log(res);
      alert("Setting Saved");
    });
  }
}
