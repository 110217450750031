import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UtilityService } from 'src/app/utility.service';
import { DeviceService } from 'src/app/device.service';
import { StorageConfig } from 'src/app/storage/storage-config';
import { StorageProvider } from 'src/app/storage/storage-provider-interface';

/**
 * DID-NEXUS-067
 * This age displayes a specific geofence alert on map
 * along with other information
 */
@Component({
  selector: 'app-geofence-alert-on-map',
  templateUrl: './geofence-alert-on-map.component.html',
  styleUrls: ['./geofence-alert-on-map.component.css']
})
export class GeofenceAlertOnMapComponent implements OnInit {

  alert; linkedGeofence; linkedLocation; linkedAsset; mapId;
  headerdata: { helpdata: any; pageName: any };
  rawUrl: any;
  url: string[];
  associatedClinician;
  MetaDataMapping;
  context: CanvasRenderingContext2D;
  @ViewChild('myCanvas', {static: true}) myCanvas;
  availableWidth; availableHeight;
  scaleX = 1; scaleY = 1;
  storageProvider: StorageProvider;

  // tslint:disable-next-line: max-line-length
  constructor(public route: ActivatedRoute, public utilityService: UtilityService, public deviceService: DeviceService) {}

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.storageProvider = StorageConfig.getInstance().getStorageProvider();
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('alertId')) {
        const alertId = paramMap.get('alertId');
        this.deviceService.getGeofenceAlertById(alertId).subscribe(data => {
          console.log(data);
          this.alert = data;
          this.linkedGeofence = this.alert.LinkedGeofence;
          this.linkedLocation = this.alert.LinkedLocation;
          this.linkedAsset = this.alert.LinkedAsset;
          this.mapId = this.linkedLocation.mapId;
          //fetch alias for metadata
          this.deviceService.getData('asset.getMetaDataMapping', res => {
            this.MetaDataMapping = res;
            this.linkedAsset.Fields.BatteryLevel.Level = this.MetaDataMapping.Equipment.BatteryLevel.Options[this.linkedAsset.Fields.BatteryLevel.Level].alias;
            this.linkedAsset.Fields.WifiStrength.Strength = this.MetaDataMapping.Equipment.WifiStrength.Options[this.linkedAsset.Fields.WifiStrength.Strength].alias;
          }, err => {
            console.log(err);
          });
          // full location path generation
          let fullName = '';
          let fullLocation = this.linkedLocation.fullPath;
          for(let level in fullLocation) {
            fullName = fullName + fullLocation[level] + '>';
          }
          fullName = fullName.substring(0, fullName.length - 1);
          this.linkedLocation.fullPath = fullName;
          //draw Geofence area on map
          this.drawGeofenceAlert();
          if (this.linkedAsset.Fields.Mappings.Clinician) {
            for (let a in this.linkedAsset.Fields.Mappings.Clinician) {
              this.associatedClinician += this.linkedAsset.Fields.Mappings.Clinician[a];
            }
          } else {
              this.associatedClinician = '--';
          }
        });
      }
    });
    // title and help
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 2];
    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
    this.availableWidth = window.screen.availWidth - 150;
    this.availableHeight = window.screen.availHeight - 250;
  }

  /**
   * (drawGeofenceAlert)
   * Description:
   * this function is used to draw geofence alert details on map
   */
  drawGeofenceAlert() {
    var thisComponent = this;
    let canvas = this.myCanvas.nativeElement;
    let context = canvas.getContext('2d');
    var image = new Image();
    // draw map when page is loaded
    image.onload = function() {
        if (image.width > thisComponent.availableWidth ) {
          canvas.width = thisComponent.availableWidth;
          thisComponent.scaleX = image.width / canvas.width;
        } else {
          canvas.width = image.width;
        }
        if (image.height > thisComponent.availableHeight) {
          canvas.height = thisComponent.availableHeight;
          thisComponent.scaleY = image.height / canvas.height;
        } else {
          canvas.height = image.height;
        }
        context.drawImage(image, 0, 0, image.width, image.height,
                              0, 0, canvas.width, canvas.height);
        var element = thisComponent.linkedGeofence.Coordinates;
        let ctx = canvas.getContext('2d');
        ctx.beginPath();
        ctx.lineWidth = "3";
        ctx.strokeStyle = "red";
        // tslint:disable-next-line: max-line-length
        ctx.rect(element.x / thisComponent.scaleX, element.y / thisComponent.scaleY , element.w / thisComponent.scaleX, element.h / thisComponent.scaleY);
        ctx.stroke();

        var pointer = new Image();
        console.log(canvas);
        pointer.onload = function() {
          // tslint:disable-next-line: max-line-length
          ctx.drawImage(pointer, (thisComponent.linkedLocation.xCenterPoint - 21) / thisComponent.scaleX, (thisComponent.linkedLocation.yCenterPoint - 48) / thisComponent.scaleY, 35, 50);
        };
        pointer.src = 'assets/images/marker_green.svg';
    };
    image.src = this.getMap(`var/uploads/images/Maps/${this.mapId}.jpg`);
  }

  getMap(filePath:string) : any {
    return this.storageProvider.getImageFile(filePath);
  }
}
