import { Component, OnInit, Output, EventEmitter,} from '@angular/core';
import { indexOf, map } from 'lodash';
import { element } from 'protractor';
import { DeviceService } from 'src/app/device.service';
import { UtilityService } from 'src/app/utility.service';
@Component({
  selector: 'app-asset-metadata',
  templateUrl: './asset-metadata.component.html',
  styleUrls: ['./asset-metadata.component.css']
})
export class AssetMetadataComponent implements OnInit {
  step = 0;
  filterList: any = [];
  assetList:any = [];
  ruleArray:any = {};
  ruleName:string;
  template:any = {};
  template2:any = {};
  locationFilterArray = [];
  @Output() locationFilter: EventEmitter<any> = new EventEmitter<any>();

  constructor(public deviceService: DeviceService,public utilityService:UtilityService) { }

  ngOnInit() {
    this.getMetaData();
    this.getRuleList();
  }
  
  getMetaData(){
    this.deviceService.getFilterOptionFromDb('assets.getFilterOption',(data) => {
        console.log(data);
        this.getFilterOption();
    },(err)=> {
      console.log(err);
    })
  }

    /**
   * (getFilterOption)
   * Description:
   * this function gets filter according to asset types.
   */
  getFilterOption() {
    this.deviceService.getFilterOptionFromDb(
      'assets.getFilterOption',
      data => {
       
        console.log(data);
        
        this.filterList = data[0];
       
        
        this.assetList = Object.keys(data[0]);
        for (let index = 0; index < this.assetList.length; index++) {
          
          const element = this.assetList[index];
          this.template[element] = false;
          this.template2[element] = false;
        }
        if( this.assetList.length > 0){
          this.assetList.shift();
        }
        
        console.log(this.assetList);
      },
      err => {
        console.log(err);
      }
    );
  }


  /**
 * (getLocationFilterData)
 * Description:
 * this function gets selected location from location list.
 */
  getLocationFilterData(data) {
  
    if (data) {
      this.locationFilterArray = [];
      // tslint:disable-next-line: forin
      for (const key in data) {
        const value = data[key];
        const newKey = 'CurrentLocation_level_' + key;
        const newObject = {};
        newObject[newKey] = value;
        this.locationFilterArray.push(newObject);
      }
      this.locationFilter.emit(data);
      console.log(this.locationFilterArray);
     
     
    }
  }

  /**
* (getLocationFields)
* Description:
* this function gets location fields
*/
  
  getFields(asset,fields){
  
    
    
    return Object.values(fields);
  }

  /**
* (setRule )
* Description:
* this function set the rule for cron into db
*/
  setRule(){
    let dbData:any = {};
    let ruleArray={};
    let keys=Object.keys(this.ruleArray);
    dbData["ruleName"] = this.ruleName;
     keys.forEach(element => {
       let new_key=element.replace(".","_");
       while (new_key.includes(".")) {
         new_key = new_key.replace(".", "_");
       }
       ruleArray[new_key]=this.ruleArray[element];
     });
    
  
     dbData["ruleCondition"]=ruleArray;
     dbData["ruleCondition"]["location"]=this.locationFilterArray;
   
     dbData["status"]=false;this.deviceService.post({"Equipment":dbData},'setRule',(res)=>{
       console.log(dbData);
       
      console.log(res);   
    },(err)=>{
        console.log(err);
        
    });
  }

  /**
* (getTheRuleList)
* Description:
* this function gets the rule list from the db
*/
  
  getRuleList(){
    this.deviceService.post({},'getRule',(res)=>{
      console.log(res);
      
    },(err)=>{
      console.log(err);
      
        
    });
  }

  opneForm(flag){
    for (let index = 0; index <  this.assetList.length; index++) {
      const element = this.assetList[index];
      console.log(flag,element);
     
        this.template2[element] = false;
        if(element === flag){
          this.template[element] = !this.template[flag];
        }else{
          this.template[element] = false;
        }
        console.log( this.template[flag]);
    }
  }
  
  checkFlag(flag){
    for (let index = 0; index <  this.assetList.length; index++) {
      const element = this.assetList[index];
      this.template[element] = false;
      if(element === flag){
        this.template2[element] = !this.template2[flag];
      }else{
        this.template2[element] = false;
      }
    }
    this.getRuleList();
    // for (let index = 0; index <  this.assetList.length; index++) {
    //   const element = this.assetList[index];
    //   console.log(flag,element);
     
      
        
    //     console.log( this.template2[flag]);
    // }

  }

  
  
}

