import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts'; //Labels imported not used
import * as moment from 'moment';
import { DeviceService } from 'src/app/device.service';

/**
 * Fleet Status Graphs Drawing component
 */
@Component({
  selector: 'app-status-vs-time-chart',
  templateUrl: './status-vs-time-chart.component.html',
  styleUrls: ['./status-vs-time-chart.component.css']
})
export class StatusVsTimeChartComponent implements OnInit {
  // configure graph options i.e; tooltip, color etc.
  public dataAvailable = false;
  public lineChartData: ChartDataSets[] = [
    {
       label: '',
       data: [],
       fill: true,

   }
  ];
  public fillCores = [];
  public timeFormat = 'MM/DD/YYYY, HH:mm';
  public lineChartOptions: (ChartOptions) = {
    responsive: true,
    title: {
      display: true,
      text: "Time Scale"
    },
    scales: {
      xAxes: [{
        type: "time",
        time: {
          parser: this.timeFormat
        },
        scaleLabel: {
          display: true,
          labelString: 'Date'
        }
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'value'
        },
        stacked: true
      }]
    },
    legend: {
      position: 'bottom'
    }
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgb(0, 152, 219)',
      pointBackgroundColor: '#ffffff',
      pointBorderColor: '#55bae7',
      pointHoverBackgroundColor: '#55bae7',
      pointHoverBorderColor: '#55bae7',
    },
    {
      borderColor: 'blue',
      backgroundColor: 'rgb(17, 222, 161)',
      pointBackgroundColor: '#b71c1c',
      pointBorderColor: '#55bae7',
      pointHoverBackgroundColor: '#55bae7',
      pointHoverBorderColor: '#55bae7'
    },
    {
      borderColor: 'grey',
      backgroundColor: 'rgb(247, 238, 22)',
      pointBackgroundColor: '#bbdefb',
      pointBorderColor: '#55bae7',
      pointHoverBackgroundColor: '#55bae7',
      pointHoverBorderColor: '#55bae7'
    },
    {
      borderColor: 'green',
      backgroundColor: 'rgb(245, 145, 9)',
      pointBackgroundColor: '#ffffff',
      pointBorderColor: '#55bae7',
      pointHoverBackgroundColor: '#55bae7',
      pointHoverBorderColor: '#55bae7'
    },
    {
      borderColor: 'yellow',
      backgroundColor: 'rgb(250, 88, 88)',
      pointBackgroundColor: '#000000',
      pointBorderColor: '#55bae7',
      pointHoverBackgroundColor: '#55bae7',
      pointHoverBorderColor: '#55bae7'
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  public MetaDataMapping = {};
  constructor(public deviceService: DeviceService) {}


  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {

  }


  /**
   * (buildGraph)
   * Description:
   * this function builds graph by fetching data
   */
  buildGraph(data: any, type: string) {
    // get alias for map data
    this.deviceService.getData('asset.getMetaDataMapping', res => {
      this.lineChartData = [];
      this.dataAvailable = true;
      this.MetaDataMapping = res;

      // label settings for wifi configuration
      if (type === 'wifi') {
        let wifi_1 = {
          label: `WiFi ${this.MetaDataMapping['Equipment']['WifiStrength']['Options']['BAR_ONE']['alias']}`,
          data: [],
          fill: true
        };
        let wifi_2 = {
          label: `WiFi ${this.MetaDataMapping['Equipment']['WifiStrength']['Options']['BAR_TWO']['alias']}`,
          data: [],
          fill: true
        };
        let wifi_3 = {
          label: `WiFi ${this.MetaDataMapping['Equipment']['WifiStrength']['Options']['BAR_THREE']['alias']}`,
          data: [],
          fill: true
        };
        let wifi_4 = {
          label: `WiFi ${this.MetaDataMapping['Equipment']['WifiStrength']['Options']['BAR_FOUR']['alias']}`,
          data: [],
          fill: true
        };
        let wifi_5 = {
          label: `WiFi ${this.MetaDataMapping['Equipment']['WifiStrength']['Options']['BAR_FIVE']['alias']}`,
          data: [],
          fill: true
        };
        let i: number;
        for (i = 0; i < data.length; i++) {
          let dataPoint = data[i];
          let time = moment(new Date(dataPoint.createdAt)).format(this.timeFormat);
          let wifi1Obj = {
            x: time,
            y: dataPoint.Fields.Wifi_1
          };
          wifi_1.data.push(wifi1Obj);

          let wifi2Obj = {
            x: time,
            y: dataPoint.Fields.Wifi_2
          };

          wifi_2.data.push(wifi2Obj);

          let wifi3Obj = {
            x: time,
            y: dataPoint.Fields.Wifi_3
          };
          wifi_3.data.push(wifi3Obj);

          let wifi4Obj = {
            x: time,
            y: dataPoint.Fields.Wifi_4
          };
          wifi_4.data.push(wifi4Obj);

          let wifi5Obj = {
            x: time,
            y: dataPoint.Fields.Wifi_5
          };
          wifi_5.data.push(wifi5Obj);
        }
        this.lineChartData.push(wifi_1);
        this.lineChartData.push(wifi_2);
        this.lineChartData.push(wifi_3);
        this.lineChartData.push(wifi_4);
        this.lineChartData.push(wifi_5);
    } else if (type === 'battery') {
      let battery_1 = {
        label: `Battery ${this.MetaDataMapping['Equipment']['BatteryLevel']['Options']['LEVEL_1']['alias']}`,
        data: [],
        fill: true
      };
      let battery_2 = {
        label: `Battery ${this.MetaDataMapping['Equipment']['BatteryLevel']['Options']['LEVEL_2']['alias']}`,
        data: [],
        fill: true
      };
      let battery_3 = {
        label: `Battery ${this.MetaDataMapping['Equipment']['BatteryLevel']['Options']['LEVEL_3']['alias']}`,
        data: [],
        fill: true
      };
      let battery_4 = {
        label: `Battery ${this.MetaDataMapping['Equipment']['BatteryLevel']['Options']['LEVEL_4']['alias']}`,
        data: [],
        fill: true
      };
      let i: number;
      for (i = 0; i < data.length; i++) {
        let dataPoint = data[i];
        let time = moment(new Date(dataPoint.createdAt)).format(this.timeFormat);

        let battery1Obj = {
          x: time,
          y: dataPoint.Fields.Battery_1
        };
        battery_1.data.push(battery1Obj);

        let battery2Obj = {
          x: time,
          y: dataPoint.Fields.Battery_2
        };
        battery_2.data.push(battery2Obj);

        let battery3Obj = {
          x: time,
          y: dataPoint.Fields.Battery_3
        };

        battery_3.data.push(battery3Obj);

        let battery4Obj = {
          x: time,
          y: dataPoint.Fields.Battery_4
        };

        battery_4.data.push(battery4Obj);
      }
      this.lineChartData.push(battery_1);
      this.lineChartData.push(battery_2);
      this.lineChartData.push(battery_3);
      this.lineChartData.push(battery_4);
    } else if (type === 'online') {
      let online = {
        label: this.MetaDataMapping['Equipment']['DeviceStatus']['Options']['comm-status-online']['alias'],
        data: [],
        fill: true
      };
      let offline = {
        label: this.MetaDataMapping['Equipment']['DeviceStatus']['Options']['comm-status-offline']['alias'],
        data: [],
        fill: true
      };
      let i: number;
      for (i = 0; i < data.length; i++) {
        let dataPoint = data[i];
        let time = moment(new Date(dataPoint.createdAt)).format(this.timeFormat);

        let onlineObject = {
          x: time,
          y: dataPoint.Fields.Online
        };
        online.data.push(onlineObject);

        let offlineObject = {
          x: time,
          y: dataPoint.Fields.Offline
        };
        offline.data.push(offlineObject);
      }
      this.lineChartData.push(online);
      this.lineChartData.push(offline);
    } else if (type === 'infusing') {
      let infusing = {
        label: this.MetaDataMapping['Equipment']['InfusionStatus']['Options']['pump-status-infusing']['alias'],
        data: [],
        fill: true
      };
      let notInfusing = {
        label: this.MetaDataMapping['Equipment']['InfusionStatus']['Options']['pump-status--not-infusing']['alias'],
        data: [],
        fill: true
      };
      let i: number;
      for (i = 0; i < data.length; i++) {
        let dataPoint = data[i];
        let time = moment(new Date(dataPoint.createdAt)).format(this.timeFormat);

        let infusingObject = {
          x: time,
          y: dataPoint.Fields.Infusing
        };
        infusing.data.push(infusingObject);

        let notInfusingObject = {
          x: time,
          y: dataPoint.Fields.NotInfusing
        };
        notInfusing.data.push(notInfusingObject);
      }
      this.lineChartData.push(infusing);
      this.lineChartData.push(notInfusing);
    }
    }, err => {
      console.log(err);
    });
  }
}
