import { Component, OnInit } from "@angular/core";
import { UtilityService } from "src/app/utility.service";
import { DeviceService } from "src/app/device.service";

/**
 * This page lists all the geofences active in the system
 */
@Component({
  selector: "app-geofence-list",
  templateUrl: "./geofence-list.component.html",
  styleUrls: ["./geofence-list.component.css"]
})
export class GeofenceListComponent implements OnInit {
  Fields = [];
  Asset = [];
  headerdata:any={};
  url:any;
  rawUrl:any;
    constructor(
    private deviceService: DeviceService,
    private utilityService: UtilityService
  ) {}

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
    this.deviceService.get(
      "geofence/get",
      res => {
        this.sendDataToReportTable(res);
      },
      err => {
      }
    );
  }


  /**
   * (sendDataToReportTable)
   * Description:
   * this function sends geofence list data to report table.
   */
  sendDataToReportTable(data) {
    let field = Object.keys(data[0]);
    field.forEach(element => {
      if (element == "Name") {
        this.Fields.push(element);
      }
    });

    Object.keys(data[0]).forEach(element => {
      if (
        element != "MapId" &&
        element != "Coordinates" &&
        element != "__v" &&
        element != "createdAt" &&
        element != "updatedAt" &&
        element != "_id" &&
        element != "Name"
      ) {
        this.Fields.push(element);
      }
    });

    this.utilityService.setDataForTable({
      reportData: data,
      showPagination: 0,
      fields: this.Fields,
      tableName: "Geofence List"
    });

    this.utilityService.setLoaderflagStatus(false);
  }
}
