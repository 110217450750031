import { Component, OnInit } from '@angular/core';
import { DeviceService } from 'src/app/device.service';
import { UtilityService } from 'src/app/utility.service';
import { Router } from '@angular/router';
import { StorageConfig } from 'src/app/storage/storage-config';
import { StorageProvider } from 'src/app/storage/storage-provider-interface';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Alias configuration page (Meta data mappings)
 */
@Component({
  selector: 'app-metadata-mapping',
  templateUrl: './metadata-mapping.component.html',
  styleUrls: ['./metadata-mapping.component.css']
})
export class MetadataMappingComponent implements OnInit {
  aliasList = [];
  topLevelFormData: any;
  divFlag = {};
  alistObj: any = {};
  fileData: any;
  headerdata: { helpdata: any; pageName: any };
  rawUrl: any;
  url: string[];
  MetaDataMapping: any;
  AssetType: any = [];
  tabClicked: string;
  metaDataArray: any;
  formData: any;
  list: any = '';
  base64: any = '';
  base64LEVEL: any = {};
  InputType: string = '';
  dataBindObj: any = {};
  orignalDataObject: any = {};
  newMetaName: string = '';
  newMetaAlias: string = '';
  newMetaPreName: string = '';
  metaData: string = '';
  newMetaHeading = '';
  firstMetaInputName = '';
  secondMetaInputName = '';
  parentclicked = false;
  MetadataMapId;
  parentClick = false;

  imageObjectFlag = {
    image: false,
    secondaryImage: false
  };
  inpuTypeOptions = ['input', 'multiple', 'select'];
  topLevel: number = -1;
  storageProvider: StorageProvider;
  private isRecentUpload: boolean = false;

  constructor(public deviceService: DeviceService, public utilityService: UtilityService, public router: Router, public bypassSecurityTrustUrl: DomSanitizer) {
    this.storageProvider = StorageConfig.getInstance().getStorageProvider();
    this.getFilterOption(res => {
      if (res) {
        this.AssetType = Object.keys(res);
        this.MetadataMapId = res['_id'];
        const index = this.AssetType.indexOf('_id');
        if (index > -1) {
          this.AssetType.splice(index, 1);
        }
      }
    });
  }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initialises this component
   */
  ngOnInit() {
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
  }

  /**
   * (getFilterOption)
   * Description:
   * this function gets the all the metadata mappings from server
   */
  getFilterOption(cb) {
    this.deviceService.getFilterOptionFromDb(
      'asset.getMetaDataMapping',
      data => {
        this.MetaDataMapping = data;
        cb(this.MetaDataMapping);
      },
      err => {
        console.log(err);
      }
    );
  }

  /**
   * (metaDataName)
   * Description:
   * this function returns metadata name(wifi,battery,status etc), which is shown after clicking asset type(Device, Clinician etc).
   */
  metaDataName(asset) {
    if (typeof asset === 'undefined') {
      return '';
    } else if (this.MetaDataMapping[asset] == null) {
      return [];
    } else {
      return Object.keys(this.MetaDataMapping[asset]);
    }
  }

  /**
   * (activeTab)
   * Description:
   * this function sets a flag of which tab is active and returns that flags.
   */
  activeTab(tabName) {
    this.dataBindObj.alias = '';
    this.topLevel = -1;
    this.parentClick = false;
    this.tabClicked = tabName;
    this.parentclicked = true;
  }

  /**
   * (returnFormData)
   * Description:
   * this function returns metadata fields to create form.
   */
  returnFormData(name) {
    console.log(name);
    this.metaData = name;
    this.dataBindObj = {};
    this.newMetaName = '';
    this.imageObjectFlag['image'] = false;
    this.imageObjectFlag['secondaryImage'] = false;

    // if metadata options (i.e; one bar, two bar etc) is undefined
    if (typeof this.MetaDataMapping[this.tabClicked][name]['Options'] == 'undefined') {
      this.topLevel = 1;
    } else { // if metadata options (i.e; one bar, two bar etc) is undefined
      if (this.MetaDataMapping[this.tabClicked][name]['Options'] != null){
        this.formData = Object.keys(this.MetaDataMapping[this.tabClicked][name]['Options']);
      } else {
        this.formData = [''];
      }
      // extract form data for binding
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < this.formData.length; index++) {
        const element = this.formData[index];
        // tslint:disable-next-line: max-line-length
        if (this.MetaDataMapping[this.tabClicked][name]['Options'] != null && typeof this.MetaDataMapping[this.tabClicked][name]['Options'][element]['alias'] != 'undefined' && this.MetaDataMapping[this.tabClicked][name]['Options'][element]['alias']) {
          this.dataBindObj[element] = this.MetaDataMapping[this.tabClicked][name]['Options'][element]['alias'];
          this.orignalDataObject[element] = element;
        } else {
          this.dataBindObj[element] = '';
        }
      }

      this.dataBindObj['image'] = '';
      this.dataBindObj['secondaryImage'] = '';

      this.metaDataArray = this.MetaDataMapping[this.tabClicked][name]['Options'];

      this.topLevel = 0;
    }

    this.topLevelFormData = this.MetaDataMapping[this.tabClicked][name];
    this.InputType = this.MetaDataMapping[this.tabClicked][name]['inputType'];
    this.dataBindObj['input'] = this.InputType;
    this.dataBindObj['alias'] = this.topLevelFormData['Alias'];
    this.dataBindObj['model'] = this.topLevelFormData['Model'];
    this.dataBindObj['tableKey'] = this.topLevelFormData['tableKey'];
    this.dataBindObj['filterFlag'] = this.topLevelFormData['filterFlag'] == '1' ? true : false;
    this.dataBindObj['icon'] = this.topLevelFormData['icon'];
  }

  /**
   * (inputTypeChange)
   * Description:
   * this function is called when we change the intput type.
   */
  inputTypeChange(event) {
 
    if (event.value == 'multiple' || event.value == 'select'){
      this.MetaDataMapping[this.tabClicked][this.metaData]['Options'] = null;
      this.formData = [''];
      this.topLevel = 0;
    } else {
      delete this.MetaDataMapping[this.tabClicked][this.metaData]['Options'];
      this.topLevel = 1;
    }
  }

  /**
   * (changeFilterFlag)
   * Description:
   * this function changes the filter show/hide flag.
   */
  changeFilterFlag() {
    this.dataBindObj['filterFlag'] = !this.dataBindObj['filterFlag'];
    
  }

  /**
   * (toggleFunction)
   * Description:
   * this function sets parentClick to true.
   */
  toggleFunction(name) {
    this.list = name;
    this.parentClick = true;
  }

  /**
   * (save)
   * Description:
   * this function creates/updates metadata settings.
   */
  save() {
    this.utilityService.setLoaderflagStatus(true);
    let concate = this.tabClicked + '.' + this.metaData + '.Options';
    let tempObj = {};
    let imageData: any = {};
    for (let key in this.dataBindObj) {
      if (typeof this.dataBindObj[key] == 'object') {
        imageData[this.tabClicked + '#' + this.metaData + '#' + key] = this.dataBindObj[key];
      } else {

        // extract all info i.e; input, alias, model etc.
        switch (true) {
          case key == 'input':
            var inputKey = this.tabClicked + '.' + this.metaData + '.inputType';
            if (this.dataBindObj[key]) {
              tempObj['' + inputKey] = this.dataBindObj[key];
            }
            break;
          case key == 'alias':
            var inputKdataBindObj = this.tabClicked + '.' + this.metaData + '.Alias';
            if (this.dataBindObj[key]) {
              tempObj['' + inputKdataBindObj] = this.dataBindObj[key];
            }
            break;
          case key == 'model':
            var inputKey = this.tabClicked + '.' + this.metaData + '.Model';
            if (this.dataBindObj[key]) {
              tempObj['' + inputKey] = this.dataBindObj[key];
            }
            break;
          case key == 'tableKey':
            var inputKey = this.tabClicked + '.' + this.metaData + '.tableKey';
            if (this.dataBindObj[key]) {
              tempObj['' + inputKey] = this.dataBindObj[key];
            }
            break;
          case key == 'filterFlag':
            var inputKey = this.tabClicked + '.' + this.metaData + '.filterFlag';
            if (this.dataBindObj[key]) {
              tempObj['' + inputKey] = '1';
            } else {
              tempObj[inputKey] = '0';
            }
            break;
          default:
            var newKey = concate + '.' + this.orignalDataObject[key] + '.alias';
            if (this.dataBindObj[key]) {
              tempObj['' + newKey] = this.dataBindObj[key];
            }
            break;
        }
      }
    }
   
    this.deviceService.post(
      { id: this.MetadataMapId, imageInfo: imageData },
      'assets.uploadMetaDataFiles',
      res => { },
      res => { }
    );
    this.deviceService.post(
      { id: this.MetadataMapId, query: tempObj },
      'assets.updateMetaDataMapping',
      res => {
        this.utilityService.setLoaderflagStatus(false);
      },
      res => {
        this.utilityService.setLoaderflagStatus(false);
      }
    );
  }

  /**
   * (onSelectFile)
   * Description:
   * this function sets base64 data on particular selected file.
   */
  onSelectFile(fileInput: any, index: any, flag) {
    let indexdata = index.split('#');
    this.fileData = fileInput.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = event => {
      this.dataBindObj[index] = {
        base64: event['target']['result']
      };
      this.base64 = this.dataBindObj[index]['base64'];
      this.isRecentUpload = true;
      this.uploadedFile(index, 'image', flag);
    };

    if (flag == 1){
      this.MetaDataMapping[this.tabClicked][this.metaData]['icon'] = 'm';
    } else {
      this.MetaDataMapping[this.tabClicked][this.metaData]['Options'][indexdata[1]][indexdata[0]] = 'm';
    }
   
  }

  /**
   * (uploadedFile)
   * Description:
   * this function returns path of file for primary and secondary images.
   */
  uploadedFile(item, imageType, flag) {
    if (this.isRecentUpload) {
      this.base64LEVEL[item] = this.base64;
      this.isRecentUpload = false;
    } else {
      if (flag == 1 && this.MetaDataMapping[this.tabClicked][this.metaData]['icon']) {
        return this.getMetadataFile(this.MetaDataMapping[this.tabClicked][this.metaData]['icon']);
      } else if (flag == 2 && this.MetaDataMapping[this.tabClicked][this.metaData]['Options'][item][imageType]) {
        return this.getMetadataFile(this.MetaDataMapping[this.tabClicked][this.metaData]['Options'][item][imageType]);
      } else {
        return 'assets/images/no_img_details.svg';
      }
    }
  }

  getMetadataFile(filePath:string) : any {
    let image = this.storageProvider.getImageFile(filePath);
    if(image) return image.startsWith('data:image/') ? this.bypassSecurityTrustUrl.bypassSecurityTrustUrl(image) : image;
    else return null;
  }

  /**
   * (showitem)
   * Description:
   * this function sets flag to show add image button
   */
  showitem(item, imageType, flag) {
    // tslint:disable-next-line: max-line-length
    if (this.MetaDataMapping[this.tabClicked][this.metaData]['Options'] == null || typeof this.MetaDataMapping[this.tabClicked][this.metaData]['Options'][item] == 'undefined' || typeof this.MetaDataMapping[this.tabClicked][this.metaData]['Options'][item][imageType] == 'undefined'){
      this.imageObjectFlag[imageType] = true;
      return !flag;
    } else {
      if (this.MetaDataMapping[this.tabClicked][this.metaData]['Options'][item][imageType]){
          return flag;
      } else {
         return !flag;
      }
    }
  }


  /**
   * (removeImage)
   * Description:
   * this function removes icon image from backend
   */
  removeImage(item, type, flag) {
    const data = {
      id: this.MetadataMapId,
      flag: flag,
      item: item,
      type: type,
      sub: this.metaData,
      parent: this.tabClicked
    };
    this.utilityService.deleteAliasImage(
      data,
      'assets.deleteAliasImage',
      res => {
      
        window.location.reload();
      },
      err => {
        console.log(err);
      }
    );
  }


  /**
   * (setNewMetaParent)
   * Description:
   * this function shows the text for adding a new asset and metadata.
   */
  setNewMetaParent(flag){
    if (flag == 1) {
      this.newMetaPreName = '';
      this.newMetaHeading = 'Add Asset Type';
      this.firstMetaInputName = 'Asset Type';
      this.secondMetaInputName = '';
    } else if (flag == 2){
      this.newMetaPreName = this.tabClicked;
      this.newMetaHeading = 'Add New Meta';
      this.firstMetaInputName = 'Meta Name';
      this.secondMetaInputName = 'Alias';
    }
    this.newMetaName = '';
    this.newMetaAlias = '';
  }


  /**
   * (addOptionRow)
   * Description:
   * this function is called when we add new option row
   */
  addOptionRow() {
   
    const index = this.formData.length;
    this.orignalDataObject[`Option ${index + 1}`] = `Option ${index + 1}`;
    this.dataBindObj[`Option ${index + 1}`] = '';

    this.formData.push(this.orignalDataObject[`Option ${index + 1}`]);
  }


  /**
   * (saveNewMetaData)
   * Description:
   * this function sets value in a variable when we click model `Add` button.
   */
  saveNewMetaData(){
    let data = {};
    let flag: number;

    if (this.newMetaPreName != ''){
      data['newMetaData'] = this.newMetaPreName + '/' + this.newMetaName;
      flag = 2;
    } else {
      data['newMetaData'] = this.newMetaName;
      flag = 1;
    }

    data['newMetaAlias'] = this.newMetaAlias;

    if (this.AssetType.includes(this.newMetaAlias) && flag==1) {
        alert("Already Exist");
    } else {
      this.saveToDb(data, flag);
    }
   
    if (flag==2) {
      const innerKey=this.MetaDataMapping[this.newMetaPreName]; 
      innerKey.includes(this.newMetaName)?
        alert("Already Exist") : this.saveToDb(data, flag);
    } 
     
    
    

   
  }

  saveToDb(data,flag){
    this.deviceService.post(data, 'assets.saveNewMetaDataMapping', res => {
      if (flag == 1) {
        this.AssetType.push(this.newMetaName);
      } else if (flag == 2) {
        this.MetaDataMapping[this.newMetaPreName][this.newMetaName] = res.object;
      }
     

    }, err => {
      console.log(err);
    })
  }

}
