import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DeviceService } from 'src/app/device.service';
import { UtilityService } from 'src/app/utility.service';

@Component({
  selector: 'app-provider-settings',
  templateUrl: './provider-settings.component.html',
  styleUrls: ['./provider-settings.component.css']
})
export class ProviderSettingsComponent implements OnInit {

  addedProviders: string[] = [];

  form: FormGroup;
  form2: FormGroup;

  urlParameter: string;
  pageTitle: string;

  Fields: any[] = [];

  url: string[];
  rawUrl: any;
  headerdata: { helpdata: any; pageName: any; };

  constructor(public utilityService: UtilityService, public deviceService: DeviceService, public route: ActivatedRoute) { }

  ngOnInit() {
    this.utilityService.get('/api/settings/getAddedLocationProviders', res => {
      this.addedProviders = res;
    }, err => {
      console.log(err);
    });
    this.form = new FormGroup({
      // here we configure our form
      // angular automatically runs all validators, and uses it in form.valid
      providerName: new FormControl(null, {
        validators: [Validators.required, Validators.minLength(3)]
      }),
      providesHierarchy: new FormControl(null, {
        validators: [Validators.required]
      }),
      enabled: new FormControl(null, {
        validators: [Validators.required]
      })
    });
    this.form2 = new FormGroup({
      // here we configure our form
      // angular automatically runs all validators, and uses it in form.valid
      fieldName: new FormControl(null, {
        validators: [Validators.required, Validators.minLength(2)]
      }),
      fieldValue: new FormControl(null, {
        validators: [Validators.required, Validators.minLength(2)]
      })
    });
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('provider')) {
        this.urlParameter = paramMap.get('provider');
        if (this.urlParameter === 'new') {
          this.pageTitle = 'Add New Location Provider';
          this.form.setValue({
            providerName : '',
            providesHierarchy : '',
            enabled : ''
          });
          this.Fields = [];
          this.url = document.URL.split('/');
          this.rawUrl = this.url[this.url.length - 2];
          this.utilityService.getLangValue('help').then(res => {
              this.headerdata = {
                helpdata: res.help[this.rawUrl],
                pageName: res[this.rawUrl]
              };
              this.utilityService.setHeaderHelpData(this.headerdata);
          });
        } else {
          this.getSavedSettings(this.urlParameter);
          this.pageTitle = this.urlParameter.charAt(0).toUpperCase() + this.urlParameter.slice(1) + ' Settings';
          this.headerdata = {
            // tslint:disable-next-line: max-line-length
            helpdata: 'This page allows you to configure/edit the settings of ' + this.urlParameter.charAt(0).toUpperCase() + this.urlParameter.slice(1) + ' location provider',
            pageName: this.pageTitle
          };
          this.utilityService.setHeaderHelpData(this.headerdata);
        }
      }
    });
  }

  saveData() {
    if (this.form.invalid) {
      alert('Please fill all the details correctly');
      return;
    }
    if (this.urlParameter === 'new' && this.addedProviders.includes(this.form.value.providerName.toLowerCase())) {
      alert('This provider is already added. Please visit its respective page to edit its settings');
      return;
    }
    let postData: any = {
      Type : 'Provider',
      SubType : this.form.value.providerName.toLowerCase(),
      Fields : {
        Enabled : this.form.value.enabled === 'true',
        HierarchyProvider : this.form.value.providesHierarchy === 'true'
      }
    };
    if (this.Fields.length > 0) {
      let i: number;
      for (i = 0 ; i < this.Fields.length ; i++) {
        let item = this.Fields[i];
        postData.Fields[item.Name] = item.Value;
      }
    }
    this.utilityService.doPost(postData, '/api/settings/create', (res) => {
      if (this.urlParameter === 'new') {
        this.deviceService.sendProviderAddedMessage();
      }
      alert('Settings Saved!');
    }, (err) => {
      console.log(err);
    });
  }

  getSavedSettings(provider: string) {
    this.utilityService.doPost({ Type: 'Provider', SubType: provider }, '/api/settings/getSettings', (res) => {
      const setting = res.data;
      // tslint:disable-next-line: no-shadowed-variable
      const provider = setting.SubType.charAt(0).toUpperCase() + setting.SubType.slice(1);
      const isEnabled = String(setting.Fields.Enabled);
      const hierarchyProvider = String(setting.Fields.HierarchyProvider);
      this.form.setValue({
        providerName : provider,
        providesHierarchy : hierarchyProvider,
        enabled : isEnabled
      });
      this.Fields = [];
      for (const property in setting.Fields) {
        if (property !== 'Enabled' && property !== 'HierarchyProvider') {
          this.Fields.push({ Name: property, Value: setting.Fields[property] });
        }
      }
    }, (err) => {
      console.log(err);
    });
  }

  addField() {
    if(!this.form2.valid) {
      alert('Please add the field name and value correctly');
      return;
    }
    let fieldAlreadyAdded = false;
    let i: number;
    for (i = 0 ; i < this.Fields.length ; i++) {
      let item = this.Fields[i];
      if (item.Name === this.form2.value.fieldName) {
        item.Value = this.form2.value.fieldValue;
        fieldAlreadyAdded = true;
      }
    }
    if (!fieldAlreadyAdded) {
      this.Fields.push({Name: this.form2.value.fieldName, Value: this.form2.value.fieldValue});
    }
    this.form2.reset();
  }

  deleteField(field) {
    let i: number;
    let index: number;
    for (i = 0 ; i < this.Fields.length ; i++) {
      let item = this.Fields[i];
      if (item.Name === field.Name) {
        index = i;
      }
    }
    this.Fields.splice(index, 1);
  }
}
