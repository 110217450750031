import { Component, OnInit, AfterViewInit, ViewChild, AfterViewChecked } from '@angular/core';
import { UtilityService } from '../../utility.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StorageConfig } from 'src/app/storage/storage-config';
import { StorageProvider } from 'src/app/storage/storage-provider-interface';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
declare var jQuery: any;

/**
 * DID-NEXUS-067
 * This page allows a user to draw geofence on map and enter its details
 */
@Component({
  selector: 'app-geofencing',
  templateUrl: './geofencing.component.html',
  styleUrls: ['./geofencing.component.css']
})
export class GeofencingComponent implements OnInit {
  coordinates;
  mapId;
  context: CanvasRenderingContext2D;
  savedFences: any[] = [];
  assetTypes = [];
  geofenceType: string; geofenceName: string;
  geofenceTypes = [];
  entryTrigger;
  exitTrigger;
  entryTriggerTime; exitTriggerTime;
  form: FormGroup;
  @ViewChild('myCanvas', {static: true}) myCanvas;
  url: string[];
  rawUrl: any;
  headerdata: { helpdata: any; pageName: any; };
  availableWidth; availableHeight;
  scaleX = 1; scaleY = 1;
  storageProvider: StorageProvider;

  constructor(public utilityService: UtilityService, public route: ActivatedRoute) {}

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.storageProvider = StorageConfig.getInstance().getStorageProvider();
    // title and help
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 2];
    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('mapId')) {
        this.mapId = paramMap.get('mapId');
        this.drawMap();
      }
    });
    this.utilityService.get('/api/settings/getGeofenceSettings', res => {
      console.log(res);
      if (res && res.Fields && res.Fields.GeofenceTypes) {
        this.geofenceTypes = res.Fields.GeofenceTypes;
      }
    }, err => {
      console.log(err);
    });
    this.form = new FormGroup({
      // here we configure our form
      // angular automatically runs all validators, and uses it in form.valid
      name: new FormControl(null, {
        validators: [Validators.required, Validators.minLength(3)]
      }),
      geofenceType: new FormControl(null, {
        validators: [Validators.required]
      }),
      assetType: new FormControl(null, {
        validators: [Validators.required]
      }),
      entryTrigger: new FormControl(null, {
        validators: [Validators.required]
      }),
      entryTriggerTime: new FormControl(null, {
        validators: [Validators.pattern('^[0-9]*$')],
      }),
      exitTrigger: new FormControl(null, {
        validators: [Validators.required]
      } ),
      exitTriggerTime: new FormControl(null, {
        validators: [Validators.pattern('^[0-9]*$')],
      })
    });
    this.availableWidth = window.screen.availWidth - 150;
    this.availableHeight = window.screen.availHeight - 250;
  }

  /**
   * (drawMap)
   * Description:
   * this function allows user to draw geofence on the map
   */
  drawMap() {
    var image = new Image();
    var thisComponent = this;
    let canvas = this.myCanvas.nativeElement;
    let context = canvas.getContext('2d');
    // tslint:disable-next-line: only-arrow-functions
    image.onload = function() {
      if (image.width > thisComponent.availableWidth ) {
        canvas.width = thisComponent.availableWidth;
        thisComponent.scaleX = image.width / canvas.width;
      } else {
        canvas.width = image.width;
      }
      if (image.height > thisComponent.availableHeight) {
        canvas.height = thisComponent.availableHeight;
        thisComponent.scaleY = image.height / canvas.height;
      } else {
        canvas.height = image.height;
      }
      context.drawImage(image, 0, 0, image.width, image.height,
                            0, 0, canvas.width, canvas.height);
      console.log(canvas.width, canvas.height);
      // JCrop
      console.log(jQuery);
      jQuery(document).ready(function() {
        jQuery('#target').click(function (ev) {
              var offset = jQuery(this).offset();
              jQuery(".marker").remove();
              jQuery("body").append(
                jQuery('<div class="marker"></div>').css({
                        position: 'absolute',
                        top: ev.pageY + 'px',
                        left: ev.pageX + 'px',
                        width: '10px',
                        height: '10px',
                        background: '#000000'
                    })
                );
              console.log(ev.pageX - offset.left,ev.pageY - offset.top);
        });
        jQuery('#target').Jcrop({
          boxWidth: image.width,
          boxHeight: image.height,
          onSelect: showCoords,
        });

        thisComponent.utilityService.get('/location/api/geofence/get/' + thisComponent.mapId, res => {
          console.log(res);
          thisComponent.savedFences = res;
          let i: number;
          // draw the geofences on the map
          for (i=0;i<res.length;i++) {
            var element = res[i]['Coordinates'];
            // console.log(element);
            let canvas = thisComponent.myCanvas.nativeElement;
            let ctx = canvas.getContext('2d');
            ctx.beginPath();
            ctx.lineWidth = "3";
            ctx.strokeStyle = "red";
            ctx.rect(element.x / this.scaleX, element.y / this.scaleY, element.w / this.scaleX, element.h / this.scaleY);
            ctx.stroke();
          }
        }, err => {
          console.log(err);
        });
    });

      function showCoords(c) {
        // console.log(c);
        thisComponent.setCoordinates(c);
      }
  };
    if (environment.value !== "test"){
      this.getMap().subscribe(val => {
        const mapKey = `var/uploads/images/Maps/${this.mapId}.jpg`;
        image.src = val.get(mapKey);
      })
    } else {
      image.src = this.getMapSync(`var/uploads/images/Maps/${this.mapId}.jpg`);
    }
  }


  /**
   * (saveFence)
   * Description:
   * this function saves the geofence to database.
   */
  saveFence() {
    // console.log(this.assetTypes);
    if (!this.coordinates) {
      alert('You have not marked any fence on the map');
      return;
    }
    if (this.form.invalid) {
      alert('Please fill all the details correctly');
      return;
    }
    const scaledCoordinates = {
      x : this.coordinates.x * this.scaleX,
      y : this.coordinates.y * this.scaleY,
      x2 : this.coordinates.x2 * this.scaleX,
      y2 : this.coordinates.y2 * this.scaleY,
      w : this.coordinates.w * this.scaleX,
      h : this.coordinates.h * this.scaleY
    };
    console.log(scaledCoordinates);
    const geofence = {
        Name: this.geofenceName,
        Type: this.geofenceType,
        AssetTypes: this.assetTypes,
        MapId: this.mapId,
        Coordinates: scaledCoordinates,
        EntryTrigger: this.entryTrigger === 'Yes' ? true : false,
        EntryTriggerWaitTime: this.entryTrigger === 'Yes' ? this.entryTriggerTime : 0,
        ExitTrigger: this.exitTrigger === 'Yes' ? true : false,
        ExitTriggerWaitTime: this.exitTrigger === 'Yes' ? this.exitTriggerTime : 0,
    };
    this.utilityService.doPost(geofence, '/location/api/geofence/add', res => {
          console.log(res);
          alert('Geofence Saved!');
          var element = res['Coordinates'];
         // console.log(element);
          let canvas = this.myCanvas.nativeElement;
          let ctx = canvas.getContext('2d');
          ctx.beginPath();
          ctx.lineWidth = "3";
          ctx.strokeStyle = "red";
          ctx.rect(element.x / this.scaleX, element.y / this.scaleY, element.w / this.scaleX, element.h / this.scaleY);
          ctx.stroke();
        },
        err => {
          console.log(err);
    });
  }

  getMap() : Observable<Map<string, string>> {
    return this.storageProvider.getImageFileObservable();
  }

  getMapSync(filePath:string) : any {
    return this.storageProvider.getImageFile(filePath);
  }


  /**
   * (setCoordinates)
   * Description:
   * this helper function sets the fence coordinates in coordinates varibale
   */
  setCoordinates(c) {
    console.log(c);
    this.coordinates = c;
  }

}
