import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report-service.service';
import { UtilityService } from "../../utility.service";
import { ActivatedRoute, ParamMap } from '@angular/router';

/**
 * DID-NEXUS-068,DID-NEXUS-069
 * Heat Map Report Component
 */
@Component({
  selector: 'app-heat-map-report',
  templateUrl: './heat-map-report.component.html',
  styleUrls: ['./heat-map-report.component.css']
})
export class HeatMapReportComponent implements OnInit {
  dataSource: any;
  url: any;
  rawUrl: any;
  headerdata = {};
  reportId = '';
  reportData : any;
  subtype: string;
  constructor(public reportService: ReportService,  public utilityService: UtilityService, public route: ActivatedRoute) {

  }

  /**
   * (ngOnInit)
   * Description:
   * this function called when angular initializes the component
   */
  ngOnInit() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('reportId')) {
        const reportId = paramMap.get('reportId');
        this.reportService.get('/custom.getCustomReportById/' + reportId, report => {
          this.reportData = report;
          this.headerdata = {
            helpdata: "help",
            pageName: this.reportData.Name
          };
          this.utilityService.setHeaderHelpData(this.headerdata);
          this.subtype = this.reportData.Subject;
          // tslint:disable-next-line: max-line-length
          this.reportService.setCustomReportFetched({ filterUrl: 'report.getFilterFields/status-fence', reportUrl: 'report.deviceProperties', subject: this.subtype});
          this.buildReport();
        }, error => {
            console.log(error);
        });
      }
    });
  }

  /**
   * (Build Report)
   * send report information to respective components
   */
  buildReport() {
    // tslint:disable-next-line: max-line-length
    this.reportService.setReportData({type: "Heat-Map", target: this.reportData['TargetField'], columns: this.reportData['Fields'], tableName: this.reportData['Name'] , Subject: this.reportData["Subject"]});
  }


  /**
   * (Get Page Name)
   * sends filter field api url and report data api url to child component
   */
  getPageName() {
    return { filterUrl: 'report.getFilterFields/battery', reportUrl: 'report.deviceProperties'};
  }

}
