import { Component, OnInit } from '@angular/core';
import { DeviceService } from 'src/app/device.service';
import { UtilityService } from 'src/app/utility.service';

/**
 * DID-NEXUS-067
 * This page lists all the Geofence Alerts captured by system
 */
@Component({
  selector: 'app-geofence-alerts',
  templateUrl: './geofence-alerts.component.html',
  styleUrls: ['./geofence-alerts.component.css']
})
export class GeofenceAlertsComponent implements OnInit {

  public geofenceAlerts = [];
  alertsPerPage = 9;
  currentPage = 1;
  totalAlerts = 0;
  headerdata: { helpdata: any; pageName: any };
  rawUrl: any;
  url: string[];
  statusHeading: any = [];
  filterData: any = null;

  constructor(public deviceService: DeviceService, public utilityService: UtilityService) { }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.getGeofenceAlerts();
    // title and help
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
  }


  /**
   * (getGeofenceAlerts)
   * Description:
   * this function gets all geofence alerts and send it to table component.
   */
  getGeofenceAlerts() {
    this.utilityService.setLoaderflagStatus(true);
    this.deviceService.getGeofenceAlerts(this.alertsPerPage, this.currentPage, this.filterData).subscribe(fetchedData => {
      this.geofenceAlerts = fetchedData.fetchedAlerts;
      this.totalAlerts = fetchedData.totalAlerts;
      this.utilityService.setLoaderflagStatus(false);
      if(this.geofenceAlerts.length > 0){ // if geofence alert data exits.
        this.statusHeading = Object.keys(this.geofenceAlerts[0]);
        this.utilityService.setDataForTable({
        reportData: this.geofenceAlerts,
        showPagination: 1,
        fields: this.statusHeading,
        tableName: 'Geofence Alerts',
        currentPage: this.currentPage,
        items: this.alertsPerPage,
        total: this.totalAlerts,
        wholeSearch: 1
      });
    } else { // if geofence alert is not data exits.
      this.utilityService.setDataForTable({
        reportData: [],
        showPagination: 1,
        fields: this.statusHeading,
        tableName: 'Geofence Alerts',
        currentPage: this.currentPage,
        items: this.alertsPerPage,
        total: this.totalAlerts,
        wholeSearch: 1
      });
    }
    });
  }

  /**
   * (openOnMap)
   * Description:
   * this function opens a new tab to show the geofence-alert on map
   */
  openOnMap(alertId) {
    window.open('/#/geofence-alert/' + alertId);
  }

  /**
   * (onChangedPage)
   * Description:
   * this function is used for pagination
   */
  onChangedPage(pageData) {
    this.currentPage = pageData.currentPage;
    this.alertsPerPage = pageData.items;
    this.getGeofenceAlerts();
  }

  /**
   * (getFiltervalue)
   * Description:
   * this function get the search text, results of which are sent to table component
   */
  getFiltervalue(ev){
    this.filterData = ev.text || null;
    this.getGeofenceAlerts();
  }

}
