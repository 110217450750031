import { Component, OnInit, OnDestroy, HostListener, Output, ViewChild } from '@angular/core';
import { DeviceService } from 'src/app/device.service';
import { UtilityService } from 'src/app/utility.service';
import { PageEvent } from '@angular/material/paginator';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { EventEmitter } from 'events';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'app-mednet-parameter',
  templateUrl: './mednet-parameter.component.html',
  styleUrls: ['./mednet-parameter.component.css']
})
export class MednetParameterComponent implements OnInit {

  constructor(public deviceService: DeviceService, public utilityService: UtilityService, private http: HttpClient, public router: Router) {
    // this.deviceService.getCategoryImageList();
  }

  ngOnInit() {

  }

}
