import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report-service.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UtilityService } from "../../utility.service";
import { DeviceService } from 'src/app/device.service';
const BACKEND_URL = environment.locationUrl;

/**
 * DID-NEXUS-029, DID-NEXUS-060, DID-NEXUS-061
 * Nearby Asset Report page
 */
@Component({
  selector: 'app-nearby-reports',
  templateUrl: './nearby-reports.component.html',
  styleUrls: ['./nearby-reports.component.css']
})
export class NearbyReportsComponent implements OnInit {
  dataSource: any;
  url: any;
  rawUrl: any;
  headerdata = {};
  // tslint:disable-next-line: max-line-length
  constructor(public reportService: ReportService, private http: HttpClient, public utilityService: UtilityService, public deviceService: DeviceService, ) {}

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.url = document.URL.split("/");
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue("help").then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      console.log(res.help[this.rawUrl]);
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
  }

/**
 * (Get Filter Data)
 * Description:
 * this function receives data from filter component
 */
  getFilterData($event) {
    this.dataSource = $event;
  }

/**
 * (Get Page Name)
 * Description:
 * this function sends filter api url and report api url to filter and report.
 */
  getPageName() {
    return { filterUrl: 'report.getFilterDataForReport/nearby-pump', reportUrl: 'report.nearbyPumps' };
  }
}

