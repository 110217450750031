import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatMenuModule } from "@angular/material/menu";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from "@angular/material/card";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { AuthGuardService } from "../app/auth/auth-guard.service";
import { AuthService } from "../app/auth/auth.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ApplicationMenuComponent } from "./widgets/application-menu/application-menu.component";
import { DeviceDetailComponent } from "./pages/device-detail/device-detail.component";
import { DeviceListComponent } from "../app/pages/device-list/device-list.component";
//import { DeviceMapComponent } from "../app/pages/device-map/device-map.component";
import { LoginComponent } from "./pages/login/login.component";
import { MatPaginatorModule } from "@angular/material/paginator";
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FileUploadComponent } from "./widgets/file-upload/file-upload.component";
import { DeviceHeatMapComponent } from "./widgets/device-heat-map/device-heat-map.component";
import {
  DeviceImportComponent
} from "./pages/device-import/device-import.component";
import { FilterComponent } from "./widgets/filter/filter.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { StatusVsTimeComponent } from "./reports/status-vs-time/status-vs-time.component";
import { PumpMovementComponent } from "./reports/pump-movement/pump-movement.component";
import { MovementVsTimeComponent } from "./reports/movement-vs-time/movement-vs-time.component";
import { FilterListLocationComponent } from "./widgets/filter-list-location/filter-list-location.component";
import { StatusVsTimeChartComponent } from './widgets/status-vs-time-chart/status-vs-time-chart.component';
import { ChartsModule } from 'ng2-charts';
import { HomeLocationComponent } from './widgets/home-location/home-location.component';
import { FooterComponent } from './widgets/footer/footer.component';
import { AlertComponent } from './reports/alert/alert.component';
import { AlarmComponent } from './reports/alarm/alarm.component';
import { MapViewComponent } from './widgets/map-view/map-view.component';
import { NotSupportComponent } from './pages/not-support/not-support.component';
import { FourZeroFourComponent } from './pages/four-zero-four/four-zero-four.component';
import { MednetAlarmComponent } from './reports/mednet-alarm/mednet-alarm.component';
import { DownloadErrorLogsComponent } from './pages/download-error-logs/download-error-logs.component';
import { AuthInterceptor } from './auth/auth-interceptor';
import { MetadataMappingComponent } from './pages/metadata-mapping/metadata-mapping.component';
import { MapImportComponent } from './pages/map-import/map-import.component';
import { GeneratedReportsComponent } from './reports/generated-reports/generated-reports.component';
import { CustomReportComponent } from './reports/custom-report/custom-report.component';
import { HeatMapReportComponent } from './reports/heat-map-report/heat-map-report.component';
import { GeofencingComponent } from './pages/geofencing/geofencing.component';
import { NearbyReportsComponent } from './reports/nearby-reports/nearby-reports.component';
import { GenerateReportFormComponent } from './reports/generate-report-form/generate-report-form.component';
import { GeofenceAlertsComponent } from './pages/geofence-alerts/geofence-alerts.component';
import { GeofenceAlertOnMapComponent } from './pages/geofence-alert-on-map/geofence-alert-on-map.component';
import { MarkingOnMapComponent } from './pages/marking-on-map/marking-on-map.component';
import { ViewMapComponent } from './pages/view-map/view-map.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { TableComponent } from './widgets/table/table.component';
import { MirthSettingsComponent } from './pages/mirth-settings/mirth-settings.component';
import { AdminConfigurationsComponent } from './pages/admin-configurations/admin-configurations.component';
import { GeofenceListComponent } from './pages/geofence-list/geofence-list.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PrivilegeSettingsComponent } from './pages/privilege-settings/privilege-settings.component';
import { CustomTagsComponent } from './pages/custom-tags/custom-tags.component';
import { AllAssetsMapViewComponent } from './reports/all-assets-map-view/all-assets-map-view.component';
import { NoCredentialsComponent } from './pages/no-credentials/no-credentials.component';
import { HierarchyListTwoComponent } from './pages/hierarchy-list-two/hierarchy-list-two.component';
import { ProviderSettingsComponent } from './pages/provider-settings/provider-settings.component';
import { ScalingConfigComponent } from './pages/scaling-config/scaling-config.component';
import { MednetParameterComponent } from './pages/mednet-parameter/mednet-parameter.component';
import { AssetMetadataComponent } from './pages/asset-metadata/asset-metadata.component';
import { ListAssetsComponent } from './pages/list-assets/list-assets.component';
import { AddLsNotifyRuleComponent } from './pages/add-ls-notify-rule/add-ls-notify-rule.component';
import { EncryptionSettingComponent } from "./pages/encryption-setting/encryption-setting.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { LsNotifyRulesComponent } from './pages/ls-notify-rules/ls-notify-rules.component';

export function jsonTokenGetter() {
  return localStorage.getItem("access_token");
}

@NgModule({
  declarations: [
    AppComponent,
    ApplicationMenuComponent,
    DeviceDetailComponent,
    DeviceListComponent,
    LoginComponent,
    FileUploadComponent,
    DeviceImportComponent,
    FilterComponent,
    StatusVsTimeComponent,
    PumpMovementComponent,
    MovementVsTimeComponent,
    FilterListLocationComponent,
    DeviceHeatMapComponent,
    StatusVsTimeChartComponent,
    HomeLocationComponent,
    FooterComponent,
    AlertComponent,
    AlarmComponent,
    MapViewComponent,
    NotSupportComponent,
    FourZeroFourComponent,
    MednetAlarmComponent,
    DownloadErrorLogsComponent,
    MetadataMappingComponent,
    MapImportComponent,
    GeneratedReportsComponent,
    CustomReportComponent,
    HeatMapReportComponent,
    GeofencingComponent,
    NearbyReportsComponent,
    GenerateReportFormComponent,
    GeofenceAlertsComponent,
    GeofenceAlertOnMapComponent,
    MarkingOnMapComponent,
    ViewMapComponent,
    AccessDeniedComponent,
    TableComponent,
    MirthSettingsComponent,
    AdminConfigurationsComponent,
    GeofenceListComponent,
    PrivilegeSettingsComponent,
    CustomTagsComponent,
    AllAssetsMapViewComponent,
    NoCredentialsComponent,
    HierarchyListTwoComponent,
    ProviderSettingsComponent,
    ScalingConfigComponent,
    AssetMetadataComponent,
    MednetParameterComponent,
    ListAssetsComponent,
    EncryptionSettingComponent,
    ProfileComponent,
    AddLsNotifyRuleComponent,
    LsNotifyRulesComponent
  ],
  imports: [
    MatPaginatorModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTableModule,
    MatSnackBarModule,
    MatIconModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    MatGridListModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatDividerModule,
    MatListModule,
    MatSelectModule,
    MatInputModule,
    MatChipsModule,
    HttpClientModule,
    MatFormFieldModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatMenuModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatExpansionModule,
    ChartsModule,
    MatButtonToggleModule,
    MatSortModule,
    MatSlideToggleModule,

  ],
  providers: [
    AuthGuardService,
    AuthService,
    MatDatepickerModule,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]

})
export class AppModule { }
