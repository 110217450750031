import { Component, OnInit } from '@angular/core';
import { UtilityService } from "../../utility.service";

/**
 * 404 (URL not found) page
 */
@Component({
  selector: 'app-four-zero-four',
  templateUrl: './four-zero-four.component.html',
  styleUrls: ['./four-zero-four.component.css']
})
export class FourZeroFourComponent implements OnInit {
  private notfound = false;
  constructor(public utilityService: UtilityService) { }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.notfound = true;
    this.utilityService.setFourZeroFour(this.notfound);
  }

}
