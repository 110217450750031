import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject, Observable } from 'rxjs';
const BACKEND_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root'
})

/**
 * Service dedicated to all basic utilities
 */
export class UtilityService {

  myInit: object;
  error: string;
  success: string;
  searchText: string;
  loaderFlag: boolean = false;
  public mapFlag = false;
  public firstLogin = false;
  public filterClickListener = new Subject<{}>();
  public statusClickListener = new Subject<{}>();
  public locationFilterReset = new Subject<{}>();
  public paginationDataForMap = new Subject<{}>();
  public flagObservable = new Subject<any>();
  public headerObservable = new Subject<any>();
  public searchObservable = new Subject<any>();
  public reportObservable = new Subject<any>();
  public fourZerofourObservable = new Subject<any>();
  public mapDataObservable = new Subject<any>();
  public tableDataListener = new Subject<any>();
  public exportDataListener = new Subject<any>();
  public deleteTableRowObservale = new Subject<any>();
  private currentFilterCriteria;
  public isConfigured: boolean = true;
  private patientDataAccess = false;

  constructor(private http: HttpClient, private _snackBar: MatSnackBar) {}

  /**
   * (Post Api Call)
   * Description:
   * this function is used for calling post api of icumed-core micro service
   */
  post(data, url, callback) {
    const serverUrl = BACKEND_URL + url;
    this.http.post(serverUrl, data).subscribe((res) => {
      callback(res);
    }, (err) => {
      callback(err);
    });
  }

  /**
   * (Post Api Call)
   * Description:
   * this function is used for calling post api, for all micro services
   */
  doPost(data, url: string, success, failure) {
    this.http.post(url, data).subscribe((res) => {
      success(res);
    }, (err) => {
      failure(err);
    }
    );
  }

  /**
   * (Get 404 Error)
   * Description:
   * A listner for 404 page condition
   */
  getFourZeroFour() {
    return this.fourZerofourObservable.asObservable();
  }

  /**
   * (Set 404 Error)
   * Description:
   * Set a flag for 404 page condition
   */
  setFourZeroFour(data) {
    this.fourZerofourObservable.next(data);
  }

  deleteRowObservale() {
    return this.deleteTableRowObservale.asObservable();
  }

  deleteTableRow(id) {
    this.deleteTableRowObservale.next(id);
  }

  /**
   * (Get Map Data)
   * Description:
   * A listner for Map Data
   */
  getMapData() {
    return this.mapDataObservable.asObservable();
  }

  /**
   * (Set Map Data)
   * Description:
   * sets the map data
   */
  setMapData(data) {
    this.currentFilterCriteria = [...data];
    this.mapDataObservable.next(data);
  }

  /**
   * (Set Search Data On Map)
   * Description:
   * sends search key to component
   */
  setSearchDataonMap(data) {
    this.searchObservable.next(data);
  }

  /**
   * (Set Search Data)
   * Description:
   * sends data to dashboard for global search
   */
  setSearchData(data) {
    this.searchText = data;
  }

  /**
   * (Get Header Help Data)
   * Description:
   * gets help data for all page headers
   */
  getHeaderHelpData() {
    return this.headerObservable.asObservable();
  }

  /**
   * (Get data for table)
   * Description:
   * gets data on table component using this listner
   */
  getDataForTable() {
    return this.tableDataListener.asObservable();
  }

  /**
   * (Set Data For Table)
   * Description:
   * sends data to table component
   */
  setDataForTable(data) {
    return this.tableDataListener.next(data);
  }

  /**
   * (Get Export data for Csv file)
   * Description:
   * gets Export data for Csv file  using this listner
   */
  getDataForExportTable() {
    return this.exportDataListener.asObservable();
  }

 /**
  * (Set Data For Csv file)
  * Description:
  * sends data to Csv file
  */
  setDataForExportTable(data) {
    return this.exportDataListener.next(data);
  }

/**
 * (Set Header Help Data)
 * Description:
 * sends help text to each page
 */
  setHeaderHelpData(headerdata) {
    this.headerObservable.next(headerdata);
  }

  /**
   * (Set Custom Report Data)
   * Description:
   * this event is used to send data to custom report page
   */
  setCustomReportData(data) {
    this.reportObservable.next(data)
  }

  /**
   * (get Custom Report Data)
   * Description:
   * this event gets data send by setCustomReportData function
   */
  getCustomReportData() {
    return this.reportObservable.asObservable();
  }

  /**
   * (Get Pagination Data)
   * Description:
   * this event gets data from pagination to diffrent pages.
   */
  getPaginationData() {
    return this.paginationDataForMap.asObservable();
  }

  /**
   * (set Pagination Data)
   * Description:
   * this event sets pagination data on table component.
   */
  setPaginationData(mapData) {
    this.paginationDataForMap.next(mapData);
  }

  /**
   * (Get Loader Flag Status)
   * Description:
   * this event gets flag for show loader
   */
  getLoaderflagStatus() {
    return this.flagObservable.asObservable();
  }

/**
 * (Set Loader Flag Status)
 * Description:
 * this event used for setting loader flag
 */
  setLoaderflagStatus(mapData) {
    this.flagObservable.next(mapData);
  }

  /**
   * (Show Error Text)
   * Description:
   * this function saves error msg in error (global) variable.
   */
  showErrorText(text) {
    this.error = text;
  }

  /**
   * (Show Success Text)
   * Description:
   * this function saves success msg in success (global) variable.
   */
  showSuccessText(text) {
    this.success = text;
  }

  /**
   * (get call api)
   * Description:
   * this function used to call get api
   */
  get(url, success, failure) {
    this.http.get(url).subscribe((res) => {
      success(res);
    }, (err) => {
      failure(err);
    });
  }

  /**
   * (Post Location Service)
   * Description:
   * this function used for call post api for location microservices
   */
  postLocationService(serverUrl, data, callback) {
    this.http.post(serverUrl, data).subscribe((res) => {
      callback(res);
    }
    );
  }

  /**
   * (Get All Filters For User)
   * Description:
   * this function fetches filter accorring to the user(save by users)
   */
  getAllFiltersForUser(userName: string, showOn: string) {
    const queryParams = `?userName=${userName}&showOn=${showOn}`;
    // console.log(queryParams);
    return this.http.get<[
      {
        filterId: number,
        criteria: any,
        displayName: string,
        userName: string,
        showOn: string,
        share:boolean
      }
    ]>(BACKEND_URL + 'filters/getFiltersForUser' + queryParams);
  }

  /**
   * (Create New Filter For User)
   * Description:
   * this function is used for creating new custom filter by user
   */
  createNewFilterForUser(newFilter) {
    this.post(newFilter, 'filters/create', (res) => {
      console.log(res);
    });
  }

  /**
   * (Delete Filter Permanently)
   * Description:
   * this function is used for deleting the filter added by user
   */
  deleteFilterPermanently(filterId) {
    this.get(BACKEND_URL + 'filters/delete/' + filterId, (success) => {
      console.log(success);
    }, (failure) => {
      console.log(failure);
    });
  }

/**
 * (Send Filter Message)
 * Description:
 * this event sends filter text to pages
 */
  sendFilterMessage(criteria) {
    this.filterClickListener.next(criteria);
    // necessary to clone criteria, otherwise result in bugs
    this.currentFilterCriteria = [...criteria];
  }

  /**
   * (Get Filter Click Listener)
   * Description:
   * this event returns data when filter clicked
   */
  getFilterClickListener() {
    return this.filterClickListener.asObservable();
  }
  /**
   * (Get Status Click Listener)
   * Description:
   * this event returns data when status changed
   */
  getStatusClickListener() {
    return this.statusClickListener.asObservable();
  }

  /**
 * (Send Filter Message)
 * Description:
 * this event sends filter text to pages
 */
  sendStatusMessage(criteria) {
    this.statusClickListener.next(criteria);

  }

  /**
* (Send Filter Message)
* Description:
* this event sends filter text to pages
*/
  changeBulkAssetStatus(url,criteria,callback) {
    const serverUrl = BACKEND_URL + url;
    this.http.post(serverUrl, criteria).subscribe((res) => {
      callback(res);
    }, (err) => {
      callback(err);
    });

  }



  /**
   * (Reset Location Filter)
   * Description:
   * this function resets the location filter
   */
  resetLocationFilter() {
    this.locationFilterReset.next({});
  }

  /**
   * (Get Reset Location Observable)
   * Description:
   * this function returns location filter observable
   */
  getResetLocationObservable() {
    return this.locationFilterReset.asObservable();
  }

  /**
   * (Get Lang Value)
   * Description:
   * this function gets page name and help text from json
   */
  async getLangValue(key) {

    this.myInit = {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      mode: "cors",
      cache: "default"
    };
    let myRequest = new Request(
      "./assets/lang/en-GB.json",
      this.myInit
    );
    let res = await fetch(myRequest);
    let data = await res.json();

    return data;
  }


  /**
   * (Get Auth Token)
   * Description:
   * this function returns access token
   */
  getAuthToken() {
    if (localStorage.getItem('AccessToken')) {
    return localStorage.getItem('AccessToken');
    } else { return ""; }
  }

/**
 * (Get Current Filter Criteria)
 * Description:
 * this function returns get filter variable
 */
  getCurrentFilterCriteria() {
    return this.currentFilterCriteria;
  }

  /**
   * (Save custom reports)
   * Description:
   * this function deletes alias images
   */
  deleteAliasImage(body, url, success, failure) {
    const serverUrl = BACKEND_URL + url;
    this.http.post(serverUrl, body).subscribe((res) => {
      success(res)
    }, (err) => {
      failure(err);
    }
    )

  }

  /**
   * (logout)
   * Description:
   * this function clears acess token and redirects to login page.
   */
  logout() {
    let userData = {
      username: localStorage.getItem("userName"),
      accessToken: localStorage.getItem("AccessToken")
    }
    let url = BACKEND_URL + 'user/logout';
    return this.http.post(url, userData);
  }

  /**
   * (setPatientDataAccess)
   * this function sets flad for patient data access
   */
  setPatientDataAccess(allowed: boolean) {
    this.patientDataAccess = allowed;
  }

  /**
   * (isPatientDataAccessAllowed)
   * this function checks whether patient data access is allowed
   */
  isPatientDataAccessAllowed() {
    return this.patientDataAccess;
  }

}


