import { Component, OnInit, Output, EventEmitter, Input, QueryList, ViewChildren, OnDestroy,ViewChild } from '@angular/core';
import { ReportService } from 'src/app/reports/report-service.service';
import { UtilityService } from 'src/app/utility.service';
import { DeviceService } from 'src/app/device.service';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';


import _ from 'lodash';
declare var $: any;

/**
 * DID-NEXUS-030, DID-NEXUS-032, DID-NEXUS-034,
 * DID-NEXUS-036, DID-NEXUS-042
 * filter component
 * Description:
 * this is filter component, used for metadata
 */
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit, OnDestroy {
  @Input() getFieldsRoutes;
  @Output() ExportDataEvent = new EventEmitter();
  @Output() graphDataEvent = new EventEmitter();
  @Output() shareMapDataEvent = new EventEmitter();
  @Output() shareMapDataEventNext = new EventEmitter();
  @Output() locationFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshEvent = new EventEmitter();
  @ViewChildren('dynamic') dynamic: QueryList<MatSelect>; // mat select for on custom filter click
  // we create an object that contains coordinates
  menuTopLeftPosition = { x: '0', y: '0' }

  // reference to the MatMenuTrigger in the DOM
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  private customReportFetchedSubscription: Subscription;
  private buildReportSubscription: Subscription;
  private exportTableSubscription: Subscription;
  filterList: any = [];
  hide: boolean = false; startDateChanged = false; endDateChanged = false;
  parentMessage;
  public FormVariable = { assetType: '', surrounding: '', fields: '' };
  parentMessage1;
  MetaDataMapping: any = {};
  criteria: any;
  ExportedData: any;
  columnsdata: any;
  disableExport: boolean = true;
  disableExportforfilter: boolean = true;
  public active_value: any = '';
  public startDate = '';
  public endDate = '';
  perPageItems = 5;
  currentPage = 1;
  myControl;
  savedFilters: any = [];
  activeFilter: any = null;
  public model = {};
  optionKey = [];
  public key: string;
  public location: any;
  public keys = [];
  public selectedData = {};
  public batteryPage: boolean;
  public wifiPage: boolean;
  public stautsFencePage: boolean;
  public nearbyPage: boolean;
  public mvst: boolean; // movement vs time
  public showlist = false;
  public locationFilterArray: any = [];
  public locationFilterArray2: any = [];
  filterVariableCriteria = [];
  sortCriteria={};
  public isSavedFilterSelected: boolean = false;
  public isFilterInputChange: boolean = false;
  public type;
  CustomTagsList: any = [];
  optionsCustomTags: any = [];
  public isShareChecked:boolean=false;
  public isShareCheckedFilterId:any;
  public status: boolean;
  currentSort:any="";
  sortDirection=-1;
  sortDirectionIcon="arrow_downward"
  items = [
    { id: 1, name: 'Item 1' },
    { id: 2, name: 'Item 2' },
    { id: 3, name: 'Item 3' }
  ];
  selectedTag: string;
  selectedShare: string='Everyone';
  selectedTypeValue:string="Equipment";
  selectedTypeCategory:string;
  showHidden=false;
  tags: any = ["Heat Map", "Battery", "Clinician", "Patient"];
  shareGroup: any = ["Everyone","Only Me"]
  public DashboardFilterSettings = {
    AssetInfo: [],
    AssetStatus: []
  };
  AssetArray :any=[];
  list_asset_type:any;
  list_asset_category:any;

  constructor(
    public reportService: ReportService,
    public utilityService: UtilityService,
    public router: Router,
    public deviceService: DeviceService,

  ) {
    this.exportTableSubscription = this.utilityService.getDataForExportTable().subscribe((res) => {
      this.columnsdata = res;
    });
    this.deviceService.getData(
      'asset.getMetaDataMapping',
      data => {
        this.MetaDataMapping = data;
        this.list_asset_type=Object.keys(this.MetaDataMapping);
        this.list_asset_type.shift();
        this.AssetArray=Object.keys(this.MetaDataMapping);
        this.AssetArray.shift();
        this.list_asset_category = Object.keys(this.MetaDataMapping["Equipment"]["PrimaryCategory"]["Options"]);
        
      },
      err => {
        console.log(err);
      }
    );
    // patient data access
    if (this.utilityService.isPatientDataAccessAllowed() && !this.AssetArray.includes('Patient')) {
      this.AssetArray.push('Patient');
    }
    if (
      this.router.url === '/report/pump-movement' ||
      this.router.url === '/report/movement-vs-time'
    ) {
      this.parentMessage = 'from';
      this.parentMessage1 = 'to';
    }
    this.filterList = '';
  }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.fetchCustomTags();
    console.log(this.router.url);
    if (this.router.url == '/devices' || this.router.url == '/report/status-vs-time')
    {
      this.disableExport = false;
    }
    this.buildReportSubscription = this.reportService.getReportData().subscribe((data: any) => {
      this.columnsdata = data.columns;
      this.getUserFilters(filters => { this.savedFilters = filters; });
    });

    this.customReportFetchedSubscription = this.reportService.getCustomReportFetchedListener().subscribe((data: any) => {
      this.getFieldsRoutes = data;
      this.type = this.getFieldsRoutes.subject;
      this.DashboardFilterSettings = {
        AssetInfo: [],
        AssetStatus: []
      };
      this.getFilterOption();
      this.resetAppliedFilter(true);
      this.onApplyFilter();
    });

    console.log(this.getFieldsRoutes);
    // tslint:disable-next-line: max-line-length
    if (this.router.url === '/devices' || this.router.url === '/report/fence-status' || this.router.url === '/report/nearby-pump' || this.router.url === '/report/movement-vs-time') {
      this.getFilterOption();
      this.getUserFilters(filters => { this.savedFilters = filters; });
    } else if (this.router.url.includes('custom-report') || this.router.url.includes('heat-map-report')) {
    } else {
      this.getFilterFields();
      this.getUserFilters(filters => { this.savedFilters = filters; });
    }

    this.fiterListActive();

    if (typeof this.getFieldsRoutes != 'undefined' && this.router.url === '/report/battery-heat') {
      this.batteryPage = true;

    } else {
      if (typeof this.getFieldsRoutes != 'undefined' && this.router.url === '/report/wifi-heat') {
        this.wifiPage = true;
      } else {
        if (typeof this.getFieldsRoutes != 'undefined' && this.router.url === '/report/fence-status') {
          this.stautsFencePage = true;

        } else {
          if (typeof this.getFieldsRoutes != 'undefined' && this.router.url === '/report/nearby-pump') {
            this.nearbyPage = true;

          } else {
            if (typeof this.getFieldsRoutes != 'undefined' && this.router.url === '/report/movement-vs-time') {
              this.mvst = true;

            }
          }
        }
      }
    }

    // tslint:disable-next-line: max-line-length
    if (this.router.url === '/report/battery-heat' || this.router.url === '/report/wifi-heat' || this.router.url === '/report/fence-status' || this.router.url === '/report/movement-vs-time') {
      this.onCustomFilterClick(0, false);
    // tslint:disable-next-line: max-line-length
    } else if (this.router.url.includes('status-vs-time') || this.router.url.includes('pump-movement') || this.router.url.includes('mednet-alarm')) {
      this.lastSixHours();
    } else if (this.router.url.includes('nearby-pump') || this.router.url.includes('rtls-alert') ||
      this.router.url.includes('rtls-alarm')) {
      // nothing
    } else {
      this.onCustomFilterClick(0, false);
    }
  }

  /**
   * (getFilterOption)
   * Description:
   * this function gets filter according to asset types.
   */
   getFilterOption() {
    let intialKey;

    this.deviceService.getFilterOptionFromDb(
      'asset.getMetaDataMapping',
      data => {
        this.optionKey = Object.keys(this.MetaDataMapping.Equipment);
        if (this.type == "Patient") {
          this.optionKey = Object.keys(this.MetaDataMapping.Patient);
        } else {
          if (this.type == "Clinician") {
            this.optionKey = Object.keys(this.MetaDataMapping.Clinician);
          }
        }
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < this.optionKey.length; index++) {
          if (this.type == "Clinician") {

            if (this.MetaDataMapping.Clinician[this.optionKey[index]].filterFlag != 0) {
              intialKey = this.optionKey[index];

              this.DashboardFilterSettings.AssetInfo.push(
                this.MetaDataMapping.Clinician[intialKey]
              );
            }
          } else {
            if (this.type == "Patient") {
              if (this.MetaDataMapping.Patient[this.optionKey[index]].filterFlag != 0) {
                intialKey = this.optionKey[index];
                this.DashboardFilterSettings.AssetInfo.push(
                  this.MetaDataMapping.Patient[intialKey]
                );
              }
            }

          }
          if (this.type !== "Clinician" && this.type !== "Patient") {

            if (this.MetaDataMapping.Equipment[this.optionKey[index]].filterFlag != 0) {
              // tslint:disable-next-line: max-line-length
              if (this.wifiPage || this.batteryPage || this.stautsFencePage || this.nearbyPage || this.router.url === '/devices' || this.mvst || this.router.url.includes('custom-report')) {
                if (
                  this.optionKey[index] == 'SerialNo' ||
                  this.optionKey[index] == 'PrimaryCategory' ||
                  this.optionKey[index] == 'AssetName' ||
                  this.optionKey[index] == 'AssetId'
                ) {

                  this.key = this.optionKey[index];
                  intialKey = this.optionKey[index];

                  this.DashboardFilterSettings.AssetInfo.push(
                    this.MetaDataMapping.Equipment[intialKey]
                  );
                } else {
                  if (
                    this.optionKey[index] != 'AssetName' &&
                    this.optionKey[index] != 'VolumeInfused'
                  ) {
                    this.key = this.optionKey[index];
                    intialKey = this.optionKey[index];
                    this.DashboardFilterSettings.AssetStatus.push(
                      this.MetaDataMapping.Equipment[intialKey]
                    );
                  }
                }
              }
            }
          }
        }

        let customTagFilter = {
          Alias: "Custom Tag",
          Model: "CustomTags",
          filterFlag: "1",
          Options: this.optionsCustomTags,
          inputType: "select",
          tableKey: "CustomTags"
        };

        for (let index = 0; index < this.CustomTagsList.length; index++) {
          customTagFilter.Options[this.CustomTagsList[index].name] = {
            alias: this.CustomTagsList[index].name
          };
        }

        //for custom tags
        this.DashboardFilterSettings.AssetInfo.push(
          customTagFilter
        );

        this.filterList = this.DashboardFilterSettings;

        console.log("dashboard filters");
        console.log(this.DashboardFilterSettings);
      },
      err => {
        console.log(err);
      }
    );
  }

  /**
   * (getFilterFields)
   * Description:
   * this function returns date time fields object.
   */
  getFilterFields() {
    console.log(this.getFieldsRoutes.filterUrl);

    this.reportService.get(
      this.getFieldsRoutes.filterUrl,
      res => {
        console.log('res', res);
        this.filterList = res;
        console.log(this.filterList);
      },
      err => {
        console.log('Error:', err);
      }
    );
  }

  /**
   * (Fetch CustomTags)
   * Description:
   * this function fetches all the Custom Tags of RTLS
   */
  fetchCustomTags() {
    this.utilityService.get('/api/customTag/list', res => {
      this.CustomTagsList = res.data;
    }, err => {
      console.log(err);
    });
  }

  /**
   * (startDateChange)
   * Description:
   * this function is used to select start date in datetime filter.
   */
  startDateChange(event) {
    // console.log(event.value);
    this.startDate = new Date(Date.parse(event.value)).toISOString();
    this.startDateChanged = true;
    if (this.startDateChanged && this.endDateChanged) {
      this.isAnyFilterSelected();
    }
  }

  /**
   * (endDateChange)
   * Description:
   * this function is used to select end date in datetime filter.
   */
  endDateChange(event) {
    // console.log(event.value);
    this.endDate = new Date(Date.parse(event.value)).toISOString();
    this.endDateChanged = true;
    if (this.startDateChanged && this.endDateChanged) {
      this.isAnyFilterSelected();
    }
  }

  /**
   * (getUserFilters)
   * Description:
   * this function returns the saved filters for user
   */
  getUserFilters(cb) {
    this.utilityService
      .getAllFiltersForUser(localStorage.getItem('userName'), this.router.url)
      .subscribe(fetchedFilters => {
        // console.log('status fence', fetchedFilters);
        cb(fetchedFilters);
      });
  }

  /**
   * (onCustomFilterClick)
   * Description:
   * this function is used when custom filter is selected
   */
  onCustomFilterClick(savedFilter, justCreated) {
    let criteria;
    
     
    if (savedFilter === 0) {
      this.isSavedFilterSelected = false;
    }
    if (savedFilter !== this.activeFilter && !justCreated) {
      this.resetAppliedFilter(false);
    }
    if (savedFilter !== 0) {
      this.activeFilter = savedFilter;
      this.isSavedFilterSelected = true;
      this.isShareCheckedFilterId=savedFilter.filterId;
      this.isShareChecked=savedFilter.share;
      if (this.isShareChecked == true) {
        this.selectedShare="Everyone"
      } else {
        this.selectedShare="Only Me"
      }
    }
    if (this.router.url === '/devices') {
      criteria = [{ AssetType: 'Equipment' }];
      if (savedFilter !== 0) {
        criteria = savedFilter.criteria;
      }
      this.filterVariableCriteria = criteria;
      console.log('Custom', this.filterVariableCriteria); 
      this.utilityService.sendFilterMessage(criteria);
      if(document.getElementById('filter_name')) document.getElementById('filter_name')['value'] = '';
    } else if (this.router.url === '/report/status-vs-time') {
      const criteria = savedFilter.criteria[0];
      this.utilityService.setLoaderflagStatus(true);
      this.reportService.post(criteria, 'report.getStatusVsTimeReport', res => {
        this.graphDataEvent.emit(res);
        this.utilityService.setLoaderflagStatus(false);
        this.hide = true;
      }, err => {
        this.utilityService.setLoaderflagStatus(false);
      }
      );
    } else {
      criteria = [];
      if (savedFilter !== 0) {
        criteria = savedFilter.criteria;
      }
      const temp = {};
      temp['pagination'] = {
        currentPage: this.currentPage,
        items: this.perPageItems
      };
      this.filterVariableCriteria = criteria;
      // console.log("Custom", this.filterVariableCriteria);
      temp['items'] = this.filterVariableCriteria;
      console.log(this.getFieldsRoutes);
      temp['subject'] = this.getFieldsRoutes.subject;
      console.log('temp', this.getFieldsRoutes);
      this.reportService.post(
        temp,
        this.getFieldsRoutes.filterUrl,
        res => {
          console.log(res);

          if (this.router.url.includes('movement-vs-time')){
          this.utilityService.setDataForTable({
            reportData: res.assets,
            showPagination: 1,
            fields: ["AssetId", "AssetType", "AssetName", "Current Location", "Homed Location"],
            tableName: 'Movement Vs Time Report',
            currentPage: this.currentPage,
            items: this.perPageItems,
            total: res.maxAssets
          });
        }
          // this.shareMapDataEvent.emit(res);
          const temp = {};
          temp['result'] = res.assets;
          temp['page'] = 'Heat Map';
          // console.log("filter componet", temp);
          this.shareMapDataEvent.emit(temp);
          this.hide = true;
        },
        err => {
          console.log(err);
        }
      );
    }
    if (this.dynamic) {
      this.dynamic.forEach(innelement => {
        innelement.options.forEach(option => {
          if (option.selected) {
            option.deselect();
          }
        });
      });
    }
    // loop for each matselect
    // loop for each options in matselect
    // for highlighting criteria
    if (criteria) {
      let i: number;
      console.log('criteria', criteria);
      for (i = 0; i < criteria.length; i++) {
        const element = criteria[i];
        console.log(element);

        // tslint:disable-next-line: forin
        for (const k in element) {
          // console.log('typeof', typeof element[k]);
          var view: any = document.getElementById(k);
          if (view && typeof this.dynamic != 'undefined') {
            // if undefined nothing happen
            this.dynamic.forEach(innelement => {
              // loop for each matselect

              innelement.options.forEach(option => {
                // loop for each options in matselect
                if (view.nodeName != 'INPUT') {
                  // excluding input type

                  if (element[k].includes(option.value)) {
                    option.select();
                  }


                

                  // if (this.selectedShare == option.value) {


                  //   option.select();
                  // }

                }
              });
            });
          }

          if (view) {
            if (view.nodeName === 'INPUT') {
              // handling input type here which is excluded above
              var Value = element[k].$regex;
              view.value = Value;
            }
          }
        }
      }
    }
  }


  onApplyListFilter(){
    let listFilter=[]
    listFilter.push(this.selectedTypeValue)
    listFilter.push(this.selectedTypeCategory)
    this.filterVariableCriteria=listFilter;
    this.utilityService.sendFilterMessage(this.filterVariableCriteria);
  }

  /**
   * (onApplyFilter)
   * Description:
   * this function is used to apply filter
   */
  onApplyFilter() {
   this.disableExportforfilter = false;
  // alert(this.getFieldsRoutes.subject);
    this.filterVariableCriteria = [];
    console.log(this.filterList);
    
    for (const k in this.filterList) {
      const q = this.filterList[k];

      // console.log(q);
      // tslint:disable-next-line: forin
      for (const x in q) {
        const element = q[x];
        // console.log(element);
        const temp = {};
        // console.log(element[element.Model]);
        if (element[element.Model]) {
          // console.log("exist");
          if (element.inputType == "input") {
            temp[element.Model] = {
              $regex: element[element.Model], $options: 'i'
            };
          } else {
            console.log(element[element.Model]);
            temp[element.Model] = element[element.Model];
          }
          const index = this.filterVariableCriteria
            .map(each => each[element.Model])
            .indexOf(element[element.Model]);
          // second condition is for cases like when 'Fields.BatteryLevel.Level' is an empty array
          // tslint:disable-next-line: max-line-length
          if (index == -1 && ((!Array.isArray(element[element.Model])) || Array.isArray(element[element.Model]) && element[element.Model].length > 0)) {
            this.filterVariableCriteria.push(temp);
          
            
          }
        }
      }
    }
    this.filterVariableCriteria.push(this.sortCriteria);
    console.log(this.filterVariableCriteria);

    let i: number;
    for (i = 0; i < this.locationFilterArray.length; i++) {
      this.filterVariableCriteria.push(this.locationFilterArray[i]);
    }

    let j: number;
    for (j = 0; j < this.locationFilterArray2.length; j++) {
      this.filterVariableCriteria.push(this.locationFilterArray2[j]);
    }

    

    if (this.router.url.includes('/devices')) {
      this.utilityService.sendFilterMessage(this.filterVariableCriteria);
    } else if (this.router.url.includes('status-vs-time')) {

      const criteria = {
        createdAt: {
          $gte: this.startDate,
          $lt: this.endDate
        }
      };
      this.utilityService.setLoaderflagStatus(true);
      this.reportService.post(
        criteria,
        'report.getStatusVsTimeReport',
        res => {
          // console.log(res);
          this.graphDataEvent.emit(res);
          this.utilityService.setLoaderflagStatus(false);
          this.hide = true;
        },
        err => {
          // console.log(err);
          this.utilityService.setLoaderflagStatus(false);
        }
      );
    } else if (this.router.url.includes('rtls-alert') || this.router.url.includes('rtls-alarm')) {
      let reportUrl;
      this.router.url.includes('rtls-alert')
        ? (reportUrl = 'report.getAlertReport')
        : (reportUrl = 'report.getAlarmReport');
      const temp = {};
      temp['pagination'] = {
        currentPage: this.currentPage,
        items: this.perPageItems
      };
      temp['items'] = this.filterVariableCriteria;
      const tempp = {};

      this.utilityService.setMapData(this.filterVariableCriteria);
      this.utilityService.setLoaderflagStatus(true);
      this.reportService.post(
        temp,
        reportUrl,
        res => {
          tempp['pagination'] = {
            currentPage: 1,
            items: res.maxAssets
          }
          tempp['items'] = this.filterVariableCriteria;
          this.getcsvdataalert(tempp, reportUrl);
          const temp = {};
          temp['result'] = res.assets;
          temp['page'] = 'Heat Map';
          // this.utilityService.setLoaderflagStatus(false);
          console.log('filter componet', temp);
          this.utilityService.setDataForTable({
            reportData: res.assets,
            showPagination: 1,
            fields: ['AssetId', 'Name', 'SerialNo', 'Location', 'Category', 'Type', 'Status', 'Battery'],
            tableName: 'Alert Report',
            currentPage: this.currentPage,
            items: this.perPageItems,
            total: res.maxAssets
          });
          this.graphDataEvent.emit(res);
          const exportObject = {};
          exportObject['url'] = reportUrl;
          exportObject['filterCriteria'] = this.filterVariableCriteria;
          exportObject['limit'] = 10;
          // this.exportDataForPump(reportUrl, this.filterVariableCriteria, 100, (data) => {
          this.ExportDataEvent.emit(exportObject);
          this.utilityService.setLoaderflagStatus(false);
          // });
          // this.filterVariableCriteria = [];
          this.hide = true;
        },
        err => {
          console.log(err);
          this.utilityService.setLoaderflagStatus(false);
        }
      );
    } else if (this.router.url.includes('pump-movement')) {
      // console.log(this.filterVariableCriteria);
      const criteria = {
        createdAt: {
          startDate: this.startDate,
          endDate: this.endDate
        }
      };
      this.filterVariableCriteria.push(criteria);
      const temp = {};
      temp['pagination'] = {
        currentPage: this.currentPage,
        items: this.perPageItems
      };
      temp['items'] = this.filterVariableCriteria;
      const tempp = {};
      this.utilityService.setLoaderflagStatus(true);
      this.reportService.post(
        temp,
        "report.getFilterDataForReport/pump-movement",
        res => {
          tempp['pagination'] = {
            currentPage: 1,
            items: res.maxAssets
          };
          tempp['items'] = this.filterVariableCriteria;
          this.getcsvdatapumpmovement(tempp);

          this.shareMapDataEvent.emit(res['assets']['LocationA']);
          this.shareMapDataEventNext.emit(res['assets']['LocationB']);
          this.utilityService.setDataForTable({
            reportData: res.assets.LocationA,
            showPagination: 1,
            fields: ['AssetId', 'LocationA', 'LocationB'],
            tableName: 'Device Movement Report',
            currentPage: this.currentPage,
            items: this.perPageItems,
            total: res.maxAssets
          });
          // this.ExportDataEvent.emit(res["assets"]["LocationA"]);
          const exportObject = {};
          exportObject['url'] = this.getFieldsRoutes.filterUrl;
          exportObject['filterCriteria'] = this.filterVariableCriteria;
          exportObject['limit'] = 100;
          // this.ExportDataEvent.emit(exportObject);
          this.utilityService.setLoaderflagStatus(false);
        },
        err => {
          console.log(err);
          this.utilityService.setLoaderflagStatus(false);
        }
      );
    } else if (this.router.url.includes('nearby-pump')) {
      // update the meta data filter options according to selection
      if (this.type !== this.FormVariable.surrounding) {
        this.type = this.FormVariable.surrounding;
        this.DashboardFilterSettings = {
          AssetInfo: [],
          AssetStatus: []
        };
        this.getFilterOption();
      }
      const getValues = Object.values(this.FormVariable);
      if (getValues.length >= 3) {
        const temp = {};
        temp['pagination'] = {
          currentPage: this.currentPage,
          items: this.perPageItems
        };
        temp['items'] = this.FormVariable;
        temp['items']['metaData'] = this.filterVariableCriteria;
        this.utilityService.setLoaderflagStatus(true);
        const csvTemp = {};
        csvTemp['items'] = temp['items'];
        this.getcsvdatanearbypump(csvTemp);
        this.reportService.post(
          temp,
          this.getFieldsRoutes.filterUrl,
          res => {
            const temp = {};
            temp['result'] = res.assets;
            temp['page'] = 'Heat Map';
            this.shareMapDataEvent.emit(temp);
            const exportObject = {};
            exportObject['url'] = this.getFieldsRoutes.filterUrl;
            exportObject['filterCriteria'] = this.filterVariableCriteria;
            exportObject['limit'] = 100;
            this.ExportDataEvent.emit(exportObject);
            this.utilityService.setLoaderflagStatus(false);
            this.hide = true;
          },
          err => {
            console.log(err);
            this.utilityService.setLoaderflagStatus(false);
          }
        );
      }
    } else if (this.router.url.includes('/assetlist')) {
      this.utilityService.sendFilterMessage(this.filterVariableCriteria);
    }
    else {
      const temp = {};
      temp['items'] = this.filterVariableCriteria;
      temp['subject'] = this.type;
      temp['pagination'] = {
        currentPage: this.currentPage,
        items: this.perPageItems
      };

      this.utilityService.setMapData(this.filterVariableCriteria);

      temp['subject'] = this.type;
      this.utilityService.setLoaderflagStatus(true);
      this.utilityService.sendFilterMessage(this.filterVariableCriteria);
      this.reportService.post(
        temp,
        this.getFieldsRoutes.filterUrl,
        res => {
          const tempp = {};
          tempp['items'] = this.filterVariableCriteria;
          tempp['subject'] = this.type;
          tempp['pagination'] = {
            currentPage: 1,
            items: res.maxAssets
          };
          this.getcsvdata(tempp);
          temp['result'] = res.assets;
          temp['page'] = 'Heat Map';

          this.shareMapDataEvent.emit(temp);
          if (this.router.url.includes('movement-vs-time')){
            this.getcsvdatamovementvstime(tempp);
            this.utilityService.setDataForTable({
              reportData: res.assets,
              showPagination: 1,
              fields: ["AssetId", "AssetType", "AssetName", "Current Location", "Homed Location"],
              tableName: 'Movement Vs Time Report',
              currentPage: this.currentPage,
              items: this.perPageItems,
              total: res.maxAssets
            });
         }
          // this.reportService.setMapFlag({columns: this.reportData['Fields'],tableName: this.reportData['Name']});
          const exportObject = {};
          exportObject['url'] = this.getFieldsRoutes.filterUrl;
          exportObject['filterCriteria'] = this.filterVariableCriteria;
          exportObject['limit'] = 100;
          // this.ExportDataEvent.emit(exportObject);
          this.utilityService.setLoaderflagStatus(false);
          this.hide = true;
        },
        err => {
          console.log(err);
          this.utilityService.setLoaderflagStatus(false);
        }
      );
    }
  }

  /**
   * (lastSixHours)
   * Description:
   * this function return last six hours data in date-time filter.
   */
  lastSixHours() {
    var today = new Date().toISOString();
    var d = new Date();
    var sixHouresBefore = new Date(d.setHours(d.getHours() - 6)).toISOString();
    this.startDate = sixHouresBefore;
    this.endDate = today;
    this.onApplyFilter();
  }


  /**
   * (resetAppliedFilter)
   * Description:
   * this function resets the applied filter
   */
  resetAppliedFilter(apiCall: boolean) {
    this.isFilterInputChange = false;
    if (document.getElementById('filter_name')) document.getElementById('filter_name')['value'] = '';
    if (apiCall) {
      if (this.router.url === '/devices') {
        this.utilityService.sendFilterMessage([{ AssetType: 'Equipment' }]);
      } else {
        let postData: any = { pagination: { currentPage: 1, items: 5 } };
        if (this.router.url.includes('/nearby-pump')) {
          postData.items = this.FormVariable;
          const csvTemp = {};
          csvTemp['items'] = postData['items'];
          this.getcsvdatanearbypump(csvTemp);
        } else {
          postData.items = [];
        }
        this.reportService.post(
          postData,
          this.getFieldsRoutes.filterUrl,
          res => {
            // console.log(res);
            this.shareMapDataEvent.emit(res);
            this.hide = true;
          },
          err => {
            console.log(err);
          }
        );
      }
    }
    let criteria: any;
    if (this.activeFilter) {
      criteria = this.activeFilter.criteria;
      console.log('k');
    } else {
      criteria = this.filterVariableCriteria;

    }

    // tslint:disable-next-line: forin
    for (const k in criteria) {

      // here whole code in this loop is not in use check before removing

      const element = criteria[k];
      console.log(element);
      // tslint:disable-next-line: forin
      for (const x in element) {
        var view: any = document.getElementById(x);
        if (view) {
          if (view.nodeName === 'SELECT') {

            // this must not be wroking here because we have replaced select element with matselect element
            view.selectedIndex = -1;
          } else if (view.nodeName === 'INPUT') {
            view.value = '';

          }
        }
      }
    }
    // this.activeFilter = null;
    this.filterVariableCriteria = null;
    // reset the location filter
    this.utilityService.resetLocationFilter();
    // tslint:disable-next-line: forin
    for (const k in this.filterList) {
      // console.log(k);
      const q = this.filterList[k];
      // console.log(q);
      // tslint:disable-next-line: forin
      for (const x in q) {
        // console.log(x);
        const element = q[x];
        // console.log(element);
        if (element[element.Model]) {
          element[element.Model] = null;
          // console.log('vn');
        }
      }
    }
    this.startDateChanged = false;
    this.endDateChanged = true;
  }

  /**
   * (createNewCustomFilter)
   * Description:
   * this function creates a new custom filter.
   */
  createNewCustomFilter() {
    console.log(this.filterVariableCriteria);
    var inputFilterName = document.getElementById('filter_name') as HTMLInputElement;
    var f_li = document.querySelectorAll('.filter_list li');
    var in_list = [];
    for (let i = 0; i <= f_li.length - 1; i++) {
      in_list.push(f_li[i].textContent.toLowerCase().trim() === inputFilterName.value.trim().toLowerCase());
    }
    const text = inputFilterName.value.trim();
    if (text.length >= 2 && in_list.indexOf(true) === -1) {
      for (let i = 0; i <= f_li.length - 1; i++) {
        f_li[i].classList.remove('active');
      }
      this.active_value = text;
      this.filterVariableCriteria = [];
      // check for time filter values
      if ((this.startDateChanged || this.endDateChanged) && (this.startDateChanged && this.endDateChanged)) {
        this.filterVariableCriteria.push({
          createdAt: {
            $gte: this.startDate,
            $lt: this.endDate
          }
        });
      }
      for (const k in this.filterList) {
        const q = this.filterList[k];
        for (const x in q) {
          const element = q[x];
          const temp = {};
          if (element[element.Model]) {
            if (element.inputType == 'input') {
              temp[element.Model] = { $regex: element[element.Model], $options: 'i' };
            } else {
              temp[element.Model] = element[element.Model];
            }
            const index = this.filterVariableCriteria.map(each => each[element.Model]).indexOf(element[element.Model]);
            if (index == -1 && ((!Array.isArray(element[element.Model])) || Array.isArray(element[element.Model]) && element[element.Model].length > 0)) {
              this.filterVariableCriteria.push(temp);
            }
          }
        }
      }
      const filter = {
        filterId: new Date().getTime(),
        criteria: this.filterVariableCriteria,
        displayName: text,
        userName: localStorage.getItem('userName'),
        showOn: this.router.url,
        share:this.isShareChecked,
        showMe:true
      };
      this.isShareCheckedFilterId=filter.filterId;
      this.utilityService.createNewFilterForUser(filter);
      this.savedFilters.push(filter);
      inputFilterName.value = '';
      this.onCustomFilterClick(filter, true);
    } else {
      this.inputValidation(inputFilterName);
    }
  }


  /**
   * (countCreateNewCustomFilter)
   * Description:
   * this function calls validation function for validation of entered filter name.
   */
  countCreateNewCustomFilter(e) {
    this.inputValidation(e.target);
  }

/**
* (checkbox box show)
* Description:
* this function calls the ui element visibility on the page.
*/
  showCheckBox(e){
    document.getElementById('rounded').style.visibility="visible";


  }


/**
* (this will track the share checkbox)
* Description:
* This funciton will track the changes on share checkbox and post it to db
*/
  shareEventChange(){
    // alert(this.selectedShare);
    if (this.selectedShare=='Everyone') {
      this.isShareChecked=true;
      let ShareChangedStatus = { share: this.isShareChecked }
      this.utilityService.post(ShareChangedStatus, `filters/update/${this.isShareCheckedFilterId}`, res => {
        console.log(res);

      })
    }
    if (this.selectedShare=="Only Me") {

      this.isShareChecked = false
     
      let ShareChangedStatus = { share: this.isShareChecked }
      this.utilityService.post(ShareChangedStatus, `filters/update/${this.isShareCheckedFilterId}`, res => {
        console.log(res);

      })
    }



  }




  /**
   * (inputValidation)
   * Description:
   * this function validates the input
   */
  inputValidation(inputId: any) {
    var f_li = document.querySelectorAll('.filter_list li');
    // console.log('f_li', f_li);
    var inputError = document.getElementById('inputError');
    var len = inputId.value.trim().length;

    // var spe = /[^a-zA-Z0-9\-\/]/.test(inputId.value.trim());

    var in_list = [];
    for (let i = 0; i <= f_li.length - 1; i++) {
      in_list.push(
        f_li[i].textContent.toLowerCase().trim() ===
        inputId.value
          .trim()
          .toLowerCase()
          .trim()
      );
    }
    var mes;
    if (len >= 2 && in_list.indexOf(true) === -1) {
      // console.log('Filter Name', inputId.value);
      inputId.classList.remove('is-invalid');
      inputError.textContent = ' ';
      // console.log("filters",this.savedFilters[0].displayName);
    } else {
      if (in_list.indexOf(true) > -1) {
        mes = 'List tag name should be unique.';
      } else {
        mes = 'Minimum 2 character required.';
      }
      inputError.textContent = mes;
      inputId.classList.add('is-invalid');
    }
  }

  /**
   * (removeCustomFilter)
   * Description:
   * this function removes custom filter.
   */
  removeCustomFilter() {
    if (this.activeFilter) {
      var index: number = this.savedFilters.indexOf(this.activeFilter);
      if (index > -1) {
        console.log(this.activeFilter);
        this.savedFilters.splice(index, 1);
        this.utilityService.deleteFilterPermanently(this.activeFilter.filterId);
        this.activeFilter = null;
        this.isSavedFilterSelected = false;
        this.resetAppliedFilter(true);
        document.getElementById('filter_name')['value'] = '';
      }
    }
  }

  /**
   * (closeFilter)
   * Description:
   * this function hides filter.
   */
  closeFilter() {
    var span1 = document.querySelectorAll('span.add');
    for (let i = 0; i <= span1.length - 1; i++) {
      span1[i].classList.remove('rotate');
      span1[i].parentElement.nextSibling['style'].height = '0px';
    }
  }

  /**
   * (getLocationFilterData)
   * Description:
   * this function gets selected location from location list.
   */
  getLocationFilterData(data) {
    console.log(data);
    if (data) {
      this.locationFilterArray = [];
      // tslint:disable-next-line: forin
      for (const key in data) {
        const value = data[key];
        const newKey = 'CurrentLocation.fullPath.' + key;
        const newObject = {};
        newObject[newKey] = value;
        this.locationFilterArray.push(newObject);
      }
      this.locationFilter.emit(data);
      console.log(this.locationFilterArray);
      this.utilityService.setMapData(this.locationFilterArray);
      this.onApplyFilter();
    }
  }

  /**
   * (getLocationFilterData2)
   * Description:
   * this function get selected location from location list(in case of from and to location).
   */
  getLocationFilterData2(data) {
    console.log(data);
    if (data) {
      this.locationFilterArray2 = [];
      // tslint:disable-next-line: forin
      for (const key in data) {
        const value = data[key];
        const newKey = '2.CurrentLocation.level.' + key;
        const newObject = {};
        newObject[newKey] = value;
        this.locationFilterArray2.push(newObject);
      }
      this.locationFilter.emit(data);
      console.log(this.locationFilterArray2);
      this.onApplyFilter();
    }
  }

  /**
   * (click_refresh)
   * Description:
   * this function refreshes data.
   */
  click_refresh() {
    // console.log((this.status = !this.status));
    this.refreshEvent.emit();
  }

  /**
   * (fiterListActive)
   * Description:
   * adds active class to active filter.
   */
  fiterListActive() {
    $(document).ready(function () {
      $(document).on('click', '.filter_list ul li', function () {
        $('.filter_list ul li').removeClass('active');
        $(this).addClass('active');
        if (
          $(this)
            .text()
            .toLowerCase()
            .trim() === 'all devices'
        ) {
          $('.applied_filters').hide();
        } else {
          $('.applied_filters').show();
        }
      });
    });
  }

  /**
   * (sendPaginationDetails)
   * Description:
   * This function is used for pagination
   */
  sendPaginationDetails(event) {
    this.currentPage = event.currentPage;
    this.perPageItems = event.items;
    this.onApplyFilter();
  }

  /**
   * (isAnyFilterSelected)
   * Description:
   * set any filter selected flag.
   */
  isAnyFilterSelected() {
    this.isFilterInputChange = true;
  }

  isAnyOptionSelected() {
    let notSelected = true;
    console.log(this.filterVariableCriteria);
    for(let a in this.filterVariableCriteria) {
      let criteriaElement = this.filterVariableCriteria[a];
      notSelected = _.isEqual(criteriaElement, { AssetType: "Equipment" });
      if(!notSelected) break;
    }
    //onsole.log(selectedFlag);
    return !notSelected;
  }

  /**
   * (getcsvdata)
   * Description:
   * gets data used for csv export.
   */
  getcsvdata(myobj){
    this.reportService.csvdataget(
      myobj,
      this.getFieldsRoutes.filterUrl,
      res => {
        const temp = {};
        console.log(res);
        this.ExportedData = res.assets;
        this.utilityService.setLoaderflagStatus(false);
        this.hide = true;
      },
      err => {
        console.log(err);
        this.utilityService.setLoaderflagStatus(false);
      }
    );

  }

  /**
   * (getcsvdatapumpmovement)
   * Description:
   * gets data used for pump movement csv.
   */
  getcsvdatapumpmovement(myobj)
  {
    // alert(this.getFieldsRoutes.filterUrl);
    this.reportService.csvdataget(
      myobj,
      this.getFieldsRoutes.filterUrl,
       res => {
       //  alert("response");
       // const temp = {};
        console.log(res);
        this.ExportedData = res.assets.LocationA;
        this.utilityService.setLoaderflagStatus(false);
        this.hide = true;
      },
      err => {
        console.log(err);
        this.utilityService.setLoaderflagStatus(false);
      }
    );


  }

  /**
   * (getcsvdatamovementvstime)
   * Description:
   * gets data used for movement vs time csv.
   */
  getcsvdatamovementvstime(myobj)
  {
    this.reportService.csvdataget(
      myobj,
      this.getFieldsRoutes.filterUrl,
      res => {
        const temp = {};
        console.log(res);
        this.ExportedData = res.assets;
        this.utilityService.setLoaderflagStatus(false);
        this.hide = true;
      },
      err => {
        console.log(err);
        this.utilityService.setLoaderflagStatus(false);
      }
    );
  }

  /**
   * (getcsvdatamednetalarm)
   * Description:
   * gets data used for alarm csv.
   */
  getcsvdatamednetalarm(myobj)
  {
    this.reportService.csvdataget(
      myobj,
      this.getFieldsRoutes.filterUrl,
      res => {
        const temp = {};
        console.log(res);
        this.ExportedData = res.assets;
        this.utilityService.setLoaderflagStatus(false);
        this.hide = true;
      },
      err => {
        console.log(err);
        this.utilityService.setLoaderflagStatus(false);
      }
    );
  }

  /**
   * (getcsvdataalert)
   * Description:
   * gets data used for alert csv.
   */
  getcsvdataalert(myobj, reportUrl ){
    this.reportService.csvdataget(
      myobj,
      reportUrl,
      res => {
        const temp = {};
        console.log(res);
        this.ExportedData = res.assets;
        console.log("totlal lenght of the feild " + this.ExportedData.length);
        this.utilityService.setLoaderflagStatus(false);
        this.hide = true;
      },
      err => {
        console.log(err);
        this.utilityService.setLoaderflagStatus(false);
      }
    );
  }

  /**
   * (getcsvdatanearbypump)
   * Description:
   * gets data used for near by pump csv.
   */
  getcsvdatanearbypump(myobj)
  {
    this.reportService.csvdataget(
      myobj,
      this.getFieldsRoutes.filterUrl,
      res => {
        const temp = {};
        this.ExportedData = res.assets;
        this.utilityService.setLoaderflagStatus(false);
        this.hide = true;
      },
      err => {
        console.log(err);
        this.utilityService.setLoaderflagStatus(false);
      }
    );
  }

  /**
   * (getcsvdatapump)
   * Description:
   * gets data used for pump csv.
   */
  getcsvdatapump(myobj){
    this.reportService.csvdataget(
      myobj,
      this.getFieldsRoutes.filterUrl,
      res => {
        const temp = {};
        console.log(res);
        this.ExportedData = res.assets.LocationA;
        this.utilityService.setLoaderflagStatus(false);
        this.hide = true;
      },
      err => {
        console.log(err);
        this.utilityService.setLoaderflagStatus(false);
      }
    );

  }

  /**
   * (downloadCsv)
   * Description:
   * call exportCsv function .
   */
  downloadCsv() {
    this.utilityService.setLoaderflagStatus(true);
    this.exportCsv();
  }

  /**
   * (exportCsv)
   * Description:
   * this function exports csv.
   */
  exportCsv() {
    const columns = this.columnsdata;
    console.log(columns);
    var csv = columns.toString() + "\n";
    if (this.ExportedData.length > 0) {
      this.ExportedData.map(e => {
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < columns.length; index++) {
        const element = columns[index];
        if (e[element] == '#N/A')
        {
          csv += 'NA';
        }
        else{
        // console.log(e);
        csv += e[element] + ",";
        }
      }
      csv += "\n";
    });
  }

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
    hiddenElement.target = "_blank";
    hiddenElement.download = "report.csv";
    hiddenElement.click();
    this.utilityService.setLoaderflagStatus(false);
  }
  /**
   * Method called when the user click with the right button
   * @param event MouseEvent, it contains the coordinates
   * @param item Our data contained in the row of the table
   */
  onRightClick(event: MouseEvent, item,flag) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();
      // alert(flag)
    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item: item }

    // we open the menu
    this.matMenuTrigger.openMenu();

  }
  onContextMenuAction1() {
    let ShowMeChangedStatus = { showMe: false }
    this.utilityService.post(ShowMeChangedStatus, `filters/update/${this.isShareCheckedFilterId}`, res => {
      console.log(res);

    })
  }
  onContextMenuAction2() {
    let ShowMeChangedStatus = { showMe: true }
    this.utilityService.post(ShowMeChangedStatus, `filters/update/${this.isShareCheckedFilterId}`, res => {
      console.log(res);

    })
  }
  showHiddenFilters(filters){
    filters.forEach(element => {
      element.showMe=true;
      this.showHidden=true;
    });
    document.getElementById("hidden").style.display = "none";

  }

  sortAsset(sortkey){
    const allSortButton=document.getElementsByClassName("sortButton");
    for (let index = 0; index < allSortButton.length; index++) {
      const element = allSortButton[index];
      if (element.classList.contains("sortActive")) {

        element.classList.remove("sortActive")
        
      }
      if (!element.children[0].classList.contains("d-none")) {
        element.children[0].classList.add("d-none")
      }
    }
    document.getElementById(sortkey.tableKey).classList.add("sortActive")
  document.getElementById(sortkey.tableKey).children[0].classList.remove("d-none")
    const sortField=sortkey.Model;
  
    if (this.currentSort==sortkey.tableKey) {
      if (this.sortDirection==-1) {
        this.sortDirection=1;
        this.sortDirectionIcon="arrow_upward"
      }else{
        this.sortDirection=-1;
        this.sortDirectionIcon="arrow_downward"
      }
      
    }
    this.currentSort=sortkey.tableKey;
    this.sortCriteria["sort"]=[];
    this. sortCriteria["sort"].push({ "sortField": sortField })
    this.sortCriteria["sort"].push({ "sortDirection": this.sortDirection })
    this.onApplyFilter(); 
    
    



  }



  /**
   * (ngOnDestroy)
   * this function is called when angular destroys this component
   */
  ngOnDestroy() {
    if (this.customReportFetchedSubscription) { this.customReportFetchedSubscription.unsubscribe(); }
    if (this.buildReportSubscription) { this.buildReportSubscription.unsubscribe(); }
    if (this.exportTableSubscription) { this.exportTableSubscription.unsubscribe(); }
  }

}
