import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/utility.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ReportService } from '../report-service.service';

/**
 * This page lists all the generated custom reports
 */
@Component({
  selector: 'app-generated-reports',
  templateUrl: './generated-reports.component.html',
  styleUrls: ['./generated-reports.component.css']
})
export class GeneratedReportsComponent implements OnInit {
  public url;
  rawUrl: any;
  deviceFields: string;
  staffFields: string;
  patientFields: string;
  selectedSubject: string;
  selectedType: string;
  listReports = [];
  headerdata: { helpdata: any; pageName: any };
  form: FormGroup;
  reportType: string;
  public ReportTypes = ['List', 'Heat Map'];
    constructor(
    public router: Router,
    public utilityService: UtilityService,
    public reportService: ReportService
  ) {}

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.getAllListReport();
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
    this.form = new FormGroup({
      // here we configure our form
      // angular automatically runs all validators, and uses it in form.valid
      name: new FormControl(null, {
        validators: [Validators.required]
      }),
      description: new FormControl(null, {
        validators: [Validators.required, Validators.minLength(3)]
      }),
      subject: new FormControl(null, {
        validators: [Validators.required]
      }),
      type: new FormControl(null, {
        validators: [Validators.required]
      }),
      fields: new FormControl(null, {
        validators: [Validators.required]
      })
    });
  }

  /**
   * (Delete Report)
   * Description:
   * this function deletes the custom report
   */
  deleteReport(id) {
    this.reportService.deleteCustomReportFromDb(
      'custom.deleteCustomReportById/' + id + '',
      res => {
        console.log(res);
        document.getElementById(id).style.display = 'none';
        let index = -1;
        for (let report of this.listReports) {
          if (report._id === id) {
            index = this.listReports.indexOf(report);
          }
        }
        this.listReports.splice(index, 1);
        this.reportService.sendCustomReportChangeMessage();
      },
      err => {
        console.log(err);
      }
    );
  }

  /**
   * (Edit Report)
   * Description:
   * this function redirects to edit report page.
   */
  editReport(id){
   this.router.navigate(['/generate-report-form/' + id + '']);

  }

  /**
   * (Add Form)
   * Description:
   * this function redirects to create report page.
   */
  addForm(){
    if (this.form.value.type){
      this.reportType = this.form.value.type;
      this.reportService.sendReportTypeToReportForm(this.form.value.type);
    }
  }

  /**
   * (Get All List Report)
   * Description:
   * this function returns list of all custom reports
   */
  getAllListReport() {
    this.reportService.getAllListReport(
      'custom.getAllCustomReports',
      res => {
        this.listReports = res;
      },
      err => {
        console.log(err);
      }
    );
  }

}
