import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DeviceService } from 'src/app/device.service';
import { UtilityService } from 'src/app/utility.service';

@Component({
  selector: 'app-add-ls-notify-rule',
  templateUrl: './add-ls-notify-rule.component.html',
  styleUrls: ['./add-ls-notify-rule.component.css']
})
export class AddLsNotifyRuleComponent implements OnInit {

  form: FormGroup;
  url:any; rawUrl:any;
  MetaDataMapping: any;
  AssetTypes: any[];
  selectedAsset: any;
  selectedMetadataName: any;
  selectedMetadataOperator: any;
  assetFieldMapping: any = {};
  assetFieldNamesMapping: any = {};


  constructor(private deviceService: DeviceService, private utilityService: UtilityService, private router: Router) {
    this.deviceService.getData('asset.getMetaDataMapping', data => {
      delete data._id;
      this.MetaDataMapping = data;
      this.AssetTypes = Object.keys(data);
      for(let assetType in data) {
        let typeObject = data[assetType];



        console.log(typeObject);
        let array = [];
        for(let a in typeObject) {
          array.push(typeObject[a].Model);
          this.assetFieldNamesMapping[typeObject[a].Model] = a;
        }
        this.assetFieldMapping[assetType] = array;
      }
      console.log(this.assetFieldMapping);
    }, res => { });
  }

  ngOnInit(): void {
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue('help').then(res => {
      let headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(headerdata);
    });
    this.form = new FormGroup({
      // here we configure our form
      // angular automatically runs all validators, and uses it in form.valid
      rule_name: new FormControl(null, {
        validators: [Validators.required, Validators.minLength(3)]
      }),
      asset_type: new FormControl(null, {
        validators: [Validators.required]
      }),
      metadata_name: new FormControl(null, {
        validators: [Validators.required]
      }),
      metadata_value_operator: new FormControl(null, {
        validators: [Validators.required]
      }),
      metadata_value: new FormControl(null, {
        validators: [Validators.required]
      }),
      priority: new FormControl(null, {
        validators: [Validators.required]
      })
    });
  }

  saveData() {
    let field;
    for(let a in this.assetFieldNamesMapping) {
      if(this.assetFieldNamesMapping[a] === this.selectedMetadataName) field = a;
    }
    let notification = {
      Name: this.form.value.rule_name,
      NotificationAssetType: this.form.value.asset_type,
      NotificationField: field,
      RaiseValue: this.form.value.metadata_value_operator + ' ' + this.form.value.metadata_value,
      Priority: this.form.value.priority
    };
    console.log(notification);
    this.utilityService.doPost(notification, '/api/filters/notificationRule/create', (res) => { console.log(res); alert(res.message); this.router.navigate(['/ls-notify-rules']); }, (err) => { console.log(err); });
  }

  getOptions() {
    if(this.selectedAsset && this.selectedMetadataName && this.MetaDataMapping[this.selectedAsset][this.selectedMetadataName].Options) return Object.keys(this.MetaDataMapping[this.selectedAsset][this.selectedMetadataName].Options);
    else return null;
  }
}
