import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/utility.service';

@Component({
  selector: 'app-ls-notify-rules',
  templateUrl: './ls-notify-rules.component.html',
  styleUrls: ['./ls-notify-rules.component.css']
})
export class LsNotifyRulesComponent implements OnInit {

  pageSize = 10;
  currentPage = 1;

  constructor(private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.utilityService.get(`/api/filters/getNotificationRules?pageSize=${this.pageSize}&currentPage=${this.currentPage}`, (message) => {
      this.sendData(message);
    }, (err) => {
      console.log(err);
    });
    this.utilityService.deleteRowObservale().subscribe(notificationRuleId => {
      this.utilityService.get(`/api/filters/deleteNotificationRule/${notificationRuleId}`, (res) => {
        this.utilityService.get(`/api/filters/getNotificationRules?pageSize=${this.pageSize}&currentPage=${this.currentPage}`, (message) => { this.sendData(message); }, (err) => { console.log(err); });
      }, (deleteErr) => {
        console.log(deleteErr);
      });
    });
  }

  onChangedPage(pageData) {
    this.currentPage = pageData.currentPage;
    this.pageSize = pageData.items;
  }

  sendData(notifications) {
    this.utilityService.setDataForTable({
      reportData: notifications,
      total: notifications.length,
      items: this.pageSize,
      currentPage: this.currentPage,
      showPagination: 1,
      fields: ['Name', 'NotificationAssetType', 'NotificationField', 'RaiseValue', 'Priority', 'Delete'],
      tableName: 'Notification Rules'
    });
  }

}
