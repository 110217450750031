import { Component, OnInit, OnDestroy, HostListener, Output, ViewChild } from '@angular/core';
import { DeviceService } from 'src/app/device.service';
import { UtilityService } from 'src/app/utility.service';
import { PageEvent } from '@angular/material/paginator';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { EventEmitter } from 'events';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { StorageConfig } from 'src/app/storage/storage-config';
import { StorageProvider } from 'src/app/storage/storage-provider-interface';
import { DomSanitizer } from '@angular/platform-browser';
declare var jQuery: any;

/**
 * DID-NEXUS-010, DID-NEXUS-011, DID-NEXUS-023,
 * DID-NEXUS-024, DID-NEXUS-025, DID-NEXUS-038,
 * DID-NEXUS-039
 * Main Dashboard page
 */
@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.css']
})
export class DeviceListComponent implements OnInit, OnDestroy {
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  @Output() paginationData: any = new EventEmitter();

  devicesPerPage = 9;
  currentPage = 1;
  devicesLPerPage = 9;
  currentLPage = 1;
  totalAssets = 0;
  maxAssets = 0;
  refreshPagination = 0;
  currentFilterCriteria: any = [];
  allEquipmentFilter = [{ AssetType: 'Equipment' }];
  public Devices = [];
  public AllDevices = [];
  public DeviceFields = [];
  public searchText = '';
  public mapFlag = false;
  url: any;
  rawUrl: any;
  headerdata = {};
  public headerShow = false;
  public searchObject;
  MetaDataMapping: any;
  private filterClickSubscription: Subscription;
  private searchPressSubscription: Subscription;
  PageIndex: number;
  refreshTimer: any;
  storageProvider: StorageProvider;

  constructor(public deviceService: DeviceService, public utilityService: UtilityService, private http: HttpClient, public router: Router, public bypassSecurityTrustUrl: DomSanitizer) {
    this.storageProvider = StorageConfig.getInstance().getStorageProvider();
  }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes this component
   */
  ngOnInit() {

    jQuery(document).ready(function () {
      jQuery('img').click(function (e) {
        var offset = jQuery(this).offset();
      });
    });
    this.utilityService.setFourZeroFour(this.headerShow);
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 1];

    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      }
      this.utilityService.setHeaderHelpData(this.headerdata);

    });
    jQuery('[data-toggle=popover]').popover({ html: true });
    this.getDevices((devices) => {
      this.Devices = devices;
      this.utilityService.setPaginationData(this.Devices);
      this.AllDevices = devices;
      this.format_words();
    });
    this.searchPressSubscription = this.deviceService.getSearchPressedListener().subscribe((searchText: string) => {
      this.searchText = searchText.toUpperCase();
      if (this.searchText.length === 0) {
        // console.log('testObj', this.currentFilterCriteria);
        this.getFilteredDevices(this.currentFilterCriteria, filteredDevices => {
          this.Devices = filteredDevices;
          this.utilityService.setPaginationData(this.Devices);
        });
        this.utilityService.setMapData(this.allEquipmentFilter);
      } else {
        this.onSearch(this.searchText);

      }
    });
    this.filterClickSubscription = this.utilityService.getFilterClickListener().subscribe(
      filterCriteria => {
        this.currentFilterCriteria = filterCriteria;
        if (this.searchText.length === 0) {
          this.getFilteredDevices(filterCriteria, filteredDevices => {
            this.Devices = filteredDevices;
            this.format_words();
          });
        } else {
          this.onSearch(this.searchText);
        }
    });
    this.refreshTimer = setInterval(() => {
      if (this.router.url !== '/login') {
        this.refresh();
      }
    }, 50000);
    this.deviceService.getData('asset.getMetaDataMapping', data => {
      this.MetaDataMapping = data;
    }, err => {
      console.log(err);
    });
  }


  /**
   * (refresh)
   * Description:
   * this function refreshes the contents of the dashboard
   */
  refresh() {
    if (this.searchText.length === 0 && this.currentFilterCriteria.length > 0) {
      // if this is filter only
      // console.log('In refresh', this.currentFilterCriteria);
      this.deviceService.getDevices(this.currentFilterCriteria, this.devicesPerPage, this.currentPage).subscribe(fetchedDevices => {
        this.Devices = fetchedDevices.assets;
      });
    } else if (this.searchText.length === 0 && this.currentFilterCriteria.length === 0) {
      // if this is natural
      this.deviceService.getDevices(this.allEquipmentFilter, this.devicesPerPage, this.currentPage).subscribe(fetchedDevices => {
        this.Devices = fetchedDevices.assets;
      });
    } else {
      // if this involves search as well
      this.onSearch(this.searchText);
    }
    this.format_words();
  }

  /**
   * (onSearch)
   * Description:
   * this function is used in header for searching devices
   */
  onSearch(searchText: string) {
    this.refreshPagination = 1;
    this.utilityService.setLoaderflagStatus(true);
    console.log(this.refreshPagination);
    // check search keyword condition
    if (this.searchText.length > 1) {
      this.searchObject = [{$and:[{
        $or: [{ 'Fields.AssetName': { $regex: this.searchText, $options: 'i' } },
        { 'Fields.SerialNo': { $regex: this.searchText, $options: 'i' } },
        { AssetId: { $regex: this.searchText, $options: 'i' } },
        { 'Fields.PrimaryCategory': { $regex: this.searchText, $options: 'i' } },
        { 'CurrentLocation.fullPath': { $regex: this.searchText, $options: 'i' } },
        ]
      }] }];
      let i: number;
      // push each filter condition in searchObject
      for (i = 0; i < this.currentFilterCriteria.length; i++) {
        this.searchObject.push(this.currentFilterCriteria[i]);
      }
      this.utilityService.setMapData(this.searchObject);
      this.getFilteredDevices(this.searchObject, filteredDevices => {
        this.Devices = filteredDevices;
        this.refreshPagination = 0;
        this.utilityService.setLoaderflagStatus(false);
        this.utilityService.setPaginationData(this.Devices);
      });
      // if search keyword is empty than show all data
    } else if (this.searchText.length === 0) {
      this.searchText = '';
      this.searchObject = [];
      this.Devices = this.AllDevices;
      this.totalAssets = this.maxAssets;
    }
    this.format_words();
  }

  /**
   * (onChangedPage)
   * Description:
   * this function is called for pagination
   */
  onChangedPage(pageData: PageEvent, page) {
    this.currentPage = pageData.pageIndex + 1;
    this.devicesPerPage = pageData.pageSize;
    if (this.searchText.length === 0) {
      // if this is filter only
      this.deviceService.getDevices(this.currentFilterCriteria, this.devicesPerPage, this.currentPage).subscribe(fetchedDevices => {
        this.Devices = fetchedDevices.assets;
        this.utilityService.setPaginationData(this.Devices);
      });
    } else {
      // if this involves search as well
      this.deviceService.getDevices(this.searchObject, this.devicesPerPage, this.currentPage).subscribe(fetchedDevices => {
        this.Devices = fetchedDevices.assets;
        this.utilityService.setPaginationData(this.Devices);
      });
    }

    this.format_words();
  }

  /**
   * (getFilteredDevices)
   * Description:
   * this function returns filtered device data.
   */
  getFilteredDevices(filterObject, cb) {
  
    console.log(filterObject);
    
    // force the filtering to be device only
    if(filterObject.indexOf(this.allEquipmentFilter[0]) === -1 ) filterObject.push(this.allEquipmentFilter[0]);
    this.deviceService.getDevices(filterObject, this.devicesPerPage, 1).subscribe(fetchedData => {
      this.totalAssets = fetchedData.maxAssets;
      cb(fetchedData.assets);
    });
  }

  /**
   * (getDevices)
   * Description:
   * this function gets all the devices present in the db
   */
  getDevices(cb) {
    console.log("100:",this.allEquipmentFilter);

    this.deviceService.getDevices(this.allEquipmentFilter, this.devicesPerPage, this.currentPage).subscribe(fetchedData => {
      this.totalAssets = fetchedData.maxAssets;
      this.maxAssets = fetchedData.maxAssets;
      cb(fetchedData.assets);
    });
  }


  /**
   * (mapView)
   * Description:
   * toggle for map view and card view.
   */
  public mapView() {
    this.mapFlag = !this.mapFlag;
  }

  /**
   * (GetSrcImg)
   * Description:
   * gets the category image of the device
   */
  getSrcImg(propertyName: string, propertyValue: string) {
    if(this.MetaDataMapping) {
      let imagePath = this.MetaDataMapping['Equipment'][propertyName]['Options'][propertyValue]['image'];
      let image = this.getMetadataFile(imagePath);
      if (image) return image;
      else return 'assets/images/no_img_details.svg';
    }
    else {
    return 'assets/images/no_img_details.svg';
    }
  }

  getMetadataFile(filePath: string) : any {
    let image = this.storageProvider.getImageFile(filePath);
    if(image) return image.startsWith('data:image/') ? this.bypassSecurityTrustUrl.bypassSecurityTrustUrl(image) : image;
    else return null;
  }

  /**
   * (format_words)
   * Description:
   * this function is used for formating the words displayed.
   */
  format_words() {
    setTimeout(() => {
      let cls = document.querySelectorAll('.content1 p:last-child');
      cls.forEach((item, index) => {
        item.textContent.replace(/>/g, 'abc')
        let abc = item.textContent.replace(/>/g, '>&#8203')
        item.innerHTML = abc;
      });
    }, 3000);
  }

  /**
   * (ngOnDestroy)
   * this function is called when angular destroys this component
   */
  ngOnDestroy() {
    this.filterClickSubscription.unsubscribe();
    this.searchPressSubscription.unsubscribe();
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }
}
