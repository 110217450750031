import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Subject } from 'rxjs';

/**
 * Service dedicated to all device related functionalities
 */
const BACKEND_URL = environment.apiUrl;
const LOCATION_BACKEND_URL = environment.locationUrl;
const MEDNET_BACKEND_URL = environment.statusUrl;
@Injectable({ providedIn: 'root' })
export class DeviceService {
  private searchPressed = new Subject<string>();
  newLocationProviderAddedListener = new Subject<{}>();

  constructor(private http: HttpClient) { }

  /**
   * (Get Asset By Type)
   * Description:
   * this function returns asset details in success callback and error in error in error callback
   */
  getAssetByType(url, success, failure) {
    let serverUrl = BACKEND_URL + url;
    this.http.get(serverUrl).subscribe((res) => {
      success(res);
    }, (err) => {
      failure(err);
    });
  }

  /**
   * (Get Devices)
   * Description:
   * this function returns devices according to pagination and filter.
   */
  getDevices(filter, devicesPerPage: number, currentPage: number) {


    const queryParams = `?pagesize=${devicesPerPage}&page=${currentPage}`;
    return this.http.post<{
      message: string, assets: any, maxAssets: number
    }>(BACKEND_URL + 'assets.filter' + queryParams, filter);
  }

  /**
   * (Pass To Dashboard)
   * Description:
   * this event sends search text from  filter to dashboard.
   */
  passToDashboard(searchText) {
    this.searchPressed.next(searchText);
  }

  /**
   * (Get Search Pressed Listener)
   * Description:
   * this event gets search text from  filter to dashboard.
   */
  getSearchPressedListener() {
    return this.searchPressed.asObservable();
  }

  /**
   * (Get)
   * Description:
   * this function calls get api by passing url
   */
  get(url, success, failure) {
    let serverUrl = LOCATION_BACKEND_URL + url;
    this.http.get(serverUrl).subscribe((res) => {
      success(res)
    }, (err) => {
      failure(err);
    });
  }

  /**
   * (Get Location History From Database)
   * Description:
   * this function returns location history of asset
   */
  getLocationHistoryFromDb(data, url, success, failure) {
    let serverUrl = LOCATION_BACKEND_URL + url;
    this.http.post(serverUrl, data).subscribe((res) => {
      success(res)
    }, (err) => {
      failure(err);
    }
    );
  }

  /**
   * (Get History From Database)
   * Description:
   * this function returns history of wifi,battery and other mednet data info of asset
   */
  getHistoryFromDb(data, url, success, failure) {
    let serverUrl = MEDNET_BACKEND_URL + url;
    this.http.post(serverUrl, data).subscribe((res) => {
      success(res)
    }, (err) => {
      failure(err);
    }
    );
  }

  /**
   * (Get Data)
   * Description:
   * this function calls get api by passing url
   */
  getData(url, success, failure) {
    let serverUrl = BACKEND_URL + url;
    this.http.get(serverUrl).subscribe((res) => {
      success(res)
    }, (err) => {
      failure(err);
    });
  }

  /**
   * (Post)
   * Description:
   * this function call post api by passing url
   */
  post(data, url, success, failure) {
    const serverUrl = BACKEND_URL + url;
    this.http.post(serverUrl, data).subscribe((res) => {
      success(res)
    }, (err) => {
      failure(err);
    }
    );
  }

  /**
   * (Get Asset)
   * Description:
   * this function returns asset details in success callback and error in error callback
   */
  getAsset(url, success, failure) {
    const serverUrl = BACKEND_URL + url;
    this.http.get(serverUrl).subscribe((res) => {
      success(res)
    }, (err) => {
      failure(err);
    });
  }

  /**
   * (get Filter Option From Db)
   * Description:
   * this function returns filter options.
   */
  getFilterOptionFromDb(url, success, failure) {
    const serverUrl = BACKEND_URL + url;
    this.http.get(serverUrl).subscribe((res) => {
      success(res)
    }, (err) => {
      failure(err);
    });
  }

  /**
   * (Get Location Fileds From Database)
   * Description:
   * this function calls api for location field
   */
  getLocationFiledsFromDb(url, success, failure) {
    const serverUrl = LOCATION_BACKEND_URL + url;
    this.http.get(serverUrl).subscribe((res) => {
      success(res)
    }, (err) => {
      failure(err);
    });
  }

  /**
   * (Get All Aeroscout Location)
   * Description:
   * this function calls api for all aeroscout location.
   */
  getAllAeroscoutLocation(url, success, failure) {
    const serverUrl = LOCATION_BACKEND_URL + url;
    this.http.get(serverUrl).subscribe((res) => {
      success(res);
    }, (err) => {
      failure(err);
    })
  }

  /**
   * (Get Geo Fence Alerts)
   * Description:
   * this function returns geo-fence alert data
   */
  getGeofenceAlerts(alertsPerPage: number, currentPage: number, filterData: any) {
    const url = LOCATION_BACKEND_URL + 'geofence/alerts';
    const queryParams = `?pagesize=${alertsPerPage}&page=${currentPage}&filterData=${filterData}`;
    return this.http.get<{
      fetchedAlerts: [],
      totalAlerts: number
    }>(url + queryParams);
  }

  /**
   * (Get Geo-fence Alert By Id)
   * Description:
   * this function returns geo-fence alert data by passing id
   */
  getGeofenceAlertById(id: string) {
    const url = LOCATION_BACKEND_URL + 'geofence/alert/' + id;
    return this.http.get<{}>(url);
  }

  /**
   * (Get Device From Database)
   * Description:
   * this function return device primary id, Device Id, Name, Manufacturer,
   * Model,Owner,LocationTags,SerialNo
   */
  getDeviceFromDb(assetType: string, assetId: string) {
    return this.http.get<
      [
        {
          _id: string,
          deviceId: string,
          deviceName: string,
          manufacturer: string,
          model: string,
          owner: string,
          locationTags: [],
          serialNo: string
        }
      ]>(BACKEND_URL + `assets.getById?assetType=${assetType}&assetId=${assetId}`);
  }

  /**
   * (Get New Provider Added Listener)
   * Description:
   * this event gets data on sendNewProviderAddedMessage event
   */
  getNewProviderAddedListener() {
    return this.newLocationProviderAddedListener.asObservable();
  }

  /**
   * (Send New Provider Added Message)
   * Description:
   * this event is used when new provider report is added
   */
  sendProviderAddedMessage() {
    this.newLocationProviderAddedListener.next();
  }


}
