import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms'; // FormControl imported but not used
import { Router, ActivatedRoute } from '@angular/router'; // RouterModule imported but not used check before removing
import { UtilityService } from '../../utility.service';

/**
 * DID-NEXUS-019
 * Login page
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  registerForm: FormGroup;
  router: Router;
  requiredUsername = false;
  requiredPassword = false;
  constructor(private formBuilder: FormBuilder, router: Router,
              private route: ActivatedRoute, public utilityService: UtilityService) {
    this.router = router;
    const token = localStorage.getItem('AccessToken');
    if (token) {
        this.router.navigate(['devices']);
        this.utilityService.firstLogin = false;
    }
  }


/**
 * (ngOnInit)
 * Description:
 * this function is called when angular initializes the component
 */
  ngOnInit() {
    setTimeout(() => {  document.getElementById("uname").focus(); }, 0);
    this.registerForm = this.formBuilder.group({
      username: [null, [Validators.required, Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)]],
      password: [null, [Validators.required, Validators.pattern(/^[^\s]+(\s+[^\s]+)*$/)]]
    });
  }

/**
 * (keyDownFunction)
 * Description:
 * this function submits the form on enter key press
 */
  keyDownFunction(event) {
    if (event.keyCode == 13) {
		  this.onSubmit();
	  }
  }

/**
 * (onSubmit)
 * Description:
 * this function is used to process the login attempt by the user
 */
  onSubmit() {
      let data = {
        username: this.registerForm.get('username').value,
        password: this.registerForm.get('password').value,
        authenticationMethod: "Password"
      };
      this.utilityService.post(data, 'user/verify', (res) => {
        if (!res.hasOwnProperty('verify') && res.status !== 200) {
          this.utilityService.showErrorText('Failed to connect to RTLS servers');
        }
        if (res.err && res.err === 1) {
          this.utilityService.showErrorText(res.message);
        } else {
          const userData = res;
          if (userData.AccessToken[0] === ""){
            this.utilityService.showErrorText('Some error occurred');
          } else {
            this.utilityService.get('api/config/isConfigured', res => {
              localStorage.setItem('AccessToken', userData.AccessToken);
              localStorage.setItem("userName", userData.username);
              localStorage.setItem("sessionId", userData.sessionId);
              this.utilityService.setPatientDataAccess(userData.patientDataAccess);
              if (res.config === 0) {
                this.utilityService.isConfigured = false;
                this.router.navigate(['/privilege-settings'], { replaceUrl: true });
              } else {
                this.utilityService.isConfigured = true;
                this.router.navigate(['/devices'], { replaceUrl: true });
              }
            }, err => {});
          }
          console.log(userData);
          console.log(res);
        }
      });
  }

}
