import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from 'src/app/utility.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { StorageConfig } from 'src/app/storage/storage-config';
import { StorageProvider } from 'src/app/storage/storage-provider-interface';

/**
 * This page is used to view the map, and the mark locations and the geofences
 * marked on this map
 */
@Component({
  selector: 'app-view-map',
  templateUrl: './view-map.component.html',
  styleUrls: ['./view-map.component.css']
})
export class ViewMapComponent implements OnInit {

  context: CanvasRenderingContext2D;
  @ViewChild('myCanvas', {static: true}) myCanvas;
  hierarchyId;
  hierarchy;
  url: string[];
  rawUrl: any;
  headerdata: { helpdata: any; pageName: any; };
  availableWidth; availableHeight;
  scaleX = 1; scaleY = 1;
  storageProvider: StorageProvider;

  constructor(private utilityService: UtilityService, public route: ActivatedRoute) {}


/**
 * (ngOnInit)
 * Description:
 * this function is called when angular initializes the component
 */
  ngOnInit() {
    this.utilityService.setLoaderflagStatus(true);
    this.storageProvider = StorageConfig.getInstance().getStorageProvider();
    // title and help
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 2];
    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('hierarchyId')) {
        this.hierarchyId = paramMap.get('hierarchyId');
        this.utilityService.get('/location/api/hierarchy/getLocationHierarchyById/' + this.hierarchyId, res => {
          console.log(res);
          this.hierarchy = res;
          // full hierarchy name generation
          let fullName = '';
          let fullPath = this.hierarchy.fullName;
          for(let level in fullPath) {
            fullName = fullName + fullPath[level] + '>';
          }
          fullName = fullName.substring(0, fullName.length - 1);
          this.hierarchy.fullName = fullName;
          this.drawMap();
        }, err => {
          console.log(err);
        });
      }
    });
    this.availableWidth = window.screen.availWidth - 150;
    this.availableHeight = window.screen.availHeight - 250;
  }

/**
 * (drawMap)
 * Description:
 * draws the map image on the screen
 */
  drawMap() {
    var thisComponent = this;
    let canvas = this.myCanvas.nativeElement;
    let context = canvas.getContext('2d');
    var image = new Image();
    image.onload = function() {
        if (image.width > thisComponent.availableWidth ) {
          canvas.width = thisComponent.availableWidth;
          thisComponent.scaleX = image.width / canvas.width;
        } else {
          canvas.width = image.width;
        }
        if (image.height > thisComponent.availableHeight) {
          canvas.height = thisComponent.availableHeight;
          thisComponent.scaleY = image.height / canvas.height;
        } else {
          canvas.height = image.height;
        }
        context.drawImage(image, 0, 0, image.width, image.height,
                              0, 0, canvas.width, canvas.height);
        thisComponent.loadSavedGeofences();
        thisComponent.loadMarkedLocations();
    };
    image.src = this.getMap(`var/uploads/images/Maps/${this.hierarchy.mapId}.jpg`);
  }


/**
 * (loadSavedGeofences)
 * Description:
 * gets saved geofences form the backend and draws on the map
 */
  loadSavedGeofences() {
    this.utilityService.get('/location/api/geofence/get/' + this.hierarchy.mapId, res => {
      console.log(res);
      let i: number;
      // draw the geofences on the map
      for (i = 0; i < res.length; i++) {
        var element = res[i]['Coordinates'];
        console.log(element);
        let canvas = this.myCanvas.nativeElement;
        let ctx = canvas.getContext('2d');
        ctx.beginPath();
        ctx.lineWidth = "3";
        ctx.strokeStyle = "red";
        ctx.rect(element.x / this.scaleX, element.y / this.scaleY, element.w / this.scaleX, element.h / this.scaleY);
        ctx.stroke();
      }
    }, err => {
      console.log(err);
    });
  }

/**
 * (loadMarkedLocations)
 * Description:
 * gets marked locations form the backend and draws on the map
 */
  loadMarkedLocations() {
    var thisComponent = this;
    this.utilityService.get('/location/api/hierarchy/markedMonitors/get/' + this.hierarchy.mapId, (res) => {
      console.log(res);
      const markedMonitors = res;
      if (markedMonitors && markedMonitors.length > 0) {
        let canvas = this.myCanvas.nativeElement;
        let context = canvas.getContext('2d');
        // tslint:disable-next-line: forin
        for (const a in markedMonitors) {
          const monitor = markedMonitors[a];
          var pointer = new Image();
          pointer.onload = function() {
            // tslint:disable-next-line: max-line-length
            context.drawImage(pointer, (monitor.xCoordinate - 21) / thisComponent.scaleX, (monitor.yCoordinate - 48) / thisComponent.scaleY, 40, 55);
          };
          pointer.src = 'assets/images/monitor.webp';
        }
      }
      this.utilityService.setLoaderflagStatus(false);
    }, (err) =>  {
      console.log(err);
      this.utilityService.setLoaderflagStatus(false);
    });
  }

  getMap(filePath:string) : any {
    return this.storageProvider.getImageFile(filePath);
  }
}
