import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/**
 * (Auth Service)
 * A Helper class used by auth guard service
 */

@Injectable()
export class AuthService {
  constructor(public router: Router) {}
  /**
   * (Is Authenticated)
   * This function checks whether an access token is present
   * in storage
   */
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('AccessToken');
    if (token) {
      return true;
    } else {
      return false;
    }
  }
}
