import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report-service.service';
import { UtilityService } from '../../utility.service';

/**
 * RTLS Alarm Report Page
 */
@Component({
  selector: 'app-alarm',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.css']
})
export class AlarmComponent implements OnInit {
  url: any;
  rawUrl: any;
  headerdata = {};
  constructor(public reportService: ReportService, public utilityService: UtilityService) { }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    // show page name and help text on page header
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 1];

    this.utilityService.getLangValue('help').then(res => {
    this.headerdata = {
      helpdata: res.help[this.rawUrl],
      pageName: res[this.rawUrl]
    };
    console.log(res.help[this.rawUrl]);
    this.utilityService.setHeaderHelpData(this.headerdata);
    });
  }

  /**
   * (getPageName)
   * sends filter api url and report data api url to child component
   */
  getPageName() {
    return { filterUrl: 'report.getFilterFields/alarm', reportUrl: 'report.deviceProperties' };
  }

  /**
   * (getData)
   * sends table header data and name of table to table component
   */
  getData() {
    // tslint:disable-next-line: max-line-length
    this.reportService.setReportData({columns: ['AssetId', 'Name', 'SerialNo', 'Location', 'Category', 'Type', 'Status', 'Battery']});
    // tslint:disable-next-line: max-line-length
    return {columns: ['AssetId', 'Name', 'SerialNo', 'Location', 'Category', 'Type', 'Status', 'Battery'], tableName: 'Alarm Report'};
  }

}
