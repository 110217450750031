import { Component, OnInit } from '@angular/core';
import { ReportService } from '../report-service.service';
import { UtilityService } from "../../utility.service";
import { DeviceService } from 'src/app/device.service';

/**
 * DID-NEXUS-011, DID-NEXUS-071
 * Fleet Status Graphs Page
 */
@Component({
  selector: 'app-status-vs-time',
  templateUrl: './status-vs-time.component.html',
  styleUrls: ['./status-vs-time.component.css']
})
export class StatusVsTimeComponent implements OnInit {

  dataSource: any;
  url: any;
  rawUrl: any;
  headerdata = {};
  constructor(public reportService: ReportService, public utilityService: UtilityService, public deviceService: DeviceService) {
  }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.url = document.URL.split("/");
    this.rawUrl = this.url[this.url.length - 1];

    this.utilityService.getLangValue("help").then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });
  }

  /**
   * (Get Filter Data)
   * Description:
   * this function receives data from filter component
   */
    getFilterData($event) {
      this.dataSource = $event;
    }

  /**
   * (Get Page Name)
   * Description:
   * this function sends api url to child component.
   */
    getPageName() {
      return { filterUrl: 'report.getFilterFields/status-vs-time', reportUrl: 'report.deviceProperties' };
    }
}
