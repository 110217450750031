import { Component, OnInit } from '@angular/core';
import { UtilityService } from "../../utility.service";
import { FormControl, FormGroup, Validators, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { AuthGuardService } from 'src/app/auth/auth-guard.service';

/**
 * DID-NEXUS-015, DID-NEXUS-050
 * This page is used to create custom tags
 */
@Component({
  selector: 'app-custom-tags',
  templateUrl: './custom-tags.component.html',
  styleUrls: ['./custom-tags.component.css']
})
export class CustomTagsComponent implements OnInit {

  options: string[] = [];
  url: any;
  rawUrl: any;
  headerdata = {};
  customTagsList = [];
  newCustomTag: any = "";
  remTagName:any = "";
  description: any;
  form: FormGroup;


  constructor(public utilityService: UtilityService, private authGaurd: AuthGuardService) { }

  /**
   * (ngOnInit)
   * Description:
   * this function is called when angular initializes the component
   */
  ngOnInit() {
    this.url = document.URL.split("/");
    this.rawUrl = this.url[this.url.length - 1];
    this.utilityService.getLangValue("help").then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };

      for (let route in res) {
        if (typeof res[route] === "string") {
          this.options.push(route);
        }
      }
      console.log(res.help[this.rawUrl]);
      this.utilityService.setHeaderHelpData(this.headerdata);
    });

    this.fetchCustomTags();
  }

  /**
   * (Fetch CustomTags)
   * Description:
   * this function fetches all the Custom Tags of RTLS
   */
  fetchCustomTags() {
    this.utilityService.get('/api/customTag/list', res => {
      this.customTagsList = res.data;
    }, err => {
      console.log(err);
    });
  }

  /**
   *
   * (Add Custom Tag)
   * Description:
   * this function sends request to add a new custom tag to RTLS system
   */
  addCustomTag() {
    console.log(this.newCustomTag.length);
    if (this.newCustomTag.length == 0) {
      alert('Please fill custom tag name');
    } else {
      console.log("hello");
      let data = {
        newCustomTag: this.newCustomTag
      };
      this.utilityService.post(data, 'customTag/create', res => {
        if (res.status === 500) {
          alert(res.error.msg);
          return;
        }
        alert('Custom Tag Added Successfully!');
        this.customTagsList.push(res);
        this.newCustomTag = undefined;
        this.utilityService.isConfigured = true;
      });
    }
  }

  /**
   * (Remove Custom Tag)
   * Description:
   * this function sends request to remove a given custom tag
   */
  removingCustomTag(customTagName) {
    this.remTagName = customTagName;
  }

  /**
   * (Remove Custom Tag)
   * Description:
   * this function sends request to remove a given custom tag
   */
  removeCustomTag() {
    
    let data: any = {
      customTagName: this.remTagName
    };
    this.utilityService.post(data, 'customTag/remove', res => {
      let index = -1;
      this.customTagsList.forEach((val, i) => {
        if (val.name === this.remTagName) {
          index = i;
        }
      });
      this.customTagsList.splice(index, 1);
      this.remTagName = "";
    });
  }

  /**
   * (_filter)
   * Helper function used by various functions on this page
   */
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
}
