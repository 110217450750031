import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/utility.service';
import { HttpClient} from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { ViewChild, ElementRef} from '@angular/core'; // imported not used
import { ParamMap, ActivatedRoute } from '@angular/router';
const BACKEND_URL = environment.locationUrl;

/**
 * DID-NEXUS-037, DID-NEXUS-084
 * Map Import Page
 */
@Component({
  selector: 'app-map-import',
  templateUrl: './map-import.component.html',
  styleUrls: ['./map-import.component.css']
})
export class MapImportComponent implements OnInit {
  @ViewChild('coverFilesInput', {static: false}) imgType: ElementRef;
  percentDone: number;
  uploadSuccess: boolean;
  size: any;
  width: number;
  height: number;
  fileToUpload: File = null;
  base64: any;
  dataObj: any = {};
  hierarchy;
  url: string[];
  rawUrl: any;
  headerdata: { helpdata: any; pageName: any; };

  constructor(public utilityService: UtilityService, public httpClient: HttpClient, public route: ActivatedRoute) {
  }

/**
 * (ngOnInit)
 * Description:
 * this function is called when angular initializes the component
 */
  ngOnInit() {
    this.utilityService.setLoaderflagStatus(true);
    // title and help
    this.url = document.URL.split('/');
    this.rawUrl = this.url[this.url.length - 2];
    this.utilityService.getLangValue('help').then(res => {
      this.headerdata = {
        helpdata: res.help[this.rawUrl],
        pageName: res[this.rawUrl]
      };
      this.utilityService.setHeaderHelpData(this.headerdata);
    });

    // get location detail by id
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('hierarchyId')) {
        this.utilityService.get('/location/api/hierarchy/getLocationHierarchyById/' + paramMap.get('hierarchyId'), (res) => {
          console.log(res);
          this.hierarchy = res;
          this.utilityService.setLoaderflagStatus(false);
        }, (err) => {
          console.log(err);
          this.utilityService.setLoaderflagStatus(false);
        });
      }
    });
  }

/**
 * (uploadFileToDb)
 * Description:
 * uploads the image file and other info to server
 */
  uploadFileToDb() {
    let temp = {};
    temp['mapData'] = this.dataObj;
    temp['hierarchyId'] = this.hierarchy.hierarchyId;
    temp['imgData'] = { img: this.base64, fileName: this.dataObj['mapId']};
    let url = BACKEND_URL + 'hierarchy/map.uploadMap';
    this.utilityService.postLocationService(url, temp, (res) => {
      if (res.status === 200) {
        alert(res.message);
        window.close();
      } else {
        alert('Map import failed');
      }
    }
    );
  }

/**
 * (saveDataAfterUpload)
 * Description:
 * makes a dataObj which is used in uploadFileToDb function for saving the data.
 */
  saveDataAfterUpload() {
    let temp = {};
    temp['mapId'] = "upload-" + new Date().getTime();
    temp['refName'] = "upload-" + new Date().getTime();
    let fullName: string = '';
    console.log(this.hierarchy.fullName);
    for(let level in this.hierarchy.fullName) {
      console.log(level);
      fullName = fullName + this.hierarchy.fullName[level] + '>';
    }
    fullName = fullName.substring(0, fullName.length - 1);
    temp['name'] = fullName;
    temp['xDimension'] = this.width;
    temp['yDimension'] = this.height;
    temp['source'] = 'upload';
    this.dataObj = temp;
    this.uploadFileToDb();
  }


/**
 * (onChange)
 * Description:
 * this function is called when we select a map image file to upload it
 */
  onChange(evt: any) {
    var thisComponent = this;
    this.fileToUpload = evt;
    this.percentDone = 100;
    this.uploadSuccess = true;
    let image: any = evt.target.files[0];
    this.fileToUpload = image;
    this.size = image.size;
    let fr = new FileReader();
    fr.onload = (function(theFile: any) {
      var image = new Image();
      image.src = theFile.target.result;
      image.onload = function() {
          // access image size here
          thisComponent.width = image.width;
          thisComponent.height = image.height;
          thisComponent.base64 = fr.result;
          thisComponent.saveDataAfterUpload();
      };
    });
    console.log(image);
    fr.readAsDataURL(image);
    this.imgType.nativeElement.value = "";
  }
}
